import React from "react";
import { Field } from "formik";
import styled from "@emotion/styled";
import CheckIcon from "../icons/CheckIcon";
import ErrorIcon from "../icons/ErrorIcon";

const Input = styled(Field)({
  padding: 14,
  borderRadius: 8,
  border: "solid 1.8px rgb(200,200,200)",
  outline: "none",
  width: "100%",
  marginRight: 10,
});
const Error = styled.p({
  color: "red",
  fontSize: 10,
  margin: 0,
  textAlign: "left",
});
const Container = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-beetwen",
});
const TextField = ({
  name,
  error,
  touched,
  placeholder,
  type,
  disabled = false,
}) => {
  return (
    <>
      {/*
            Convertir este input en uno de google con icono y hacer q cambie de color*/}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Container>
          <Input
            sx={{
              borderColor: error && touched ? "#FF6464" : touched && "#0AE678",
            }}
            name={name}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
          />
          {error && touched && <ErrorIcon />}
          {touched && !error && <CheckIcon />}
        </Container>
        {error && touched ? <Error>{error}</Error> : null}
      </div>
    </>
  );
};
export default TextField;
