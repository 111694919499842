import React from "react";
import { Box } from '@mui/material'
import Chart from 'react-apexcharts';

export const PieChart = ({labels, series}) => {
  const chartData = {
    chartOptions:{
      labels
    },
    series,
    options:{
      style:{
        padding:4
      }
    }
  }
  return (
    <Box className="bg-white">
      <Chart
        options={chartData.chartOptions}
        series={chartData.series}
        type="pie"
      />
    </Box>
  );
}