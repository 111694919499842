import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import { MenuItem, Select } from "@mui/material";
import moment from 'moment';

import { UserContext } from "../../../../context/userContext";
import {
  AddUser,
  UpdateUserController,
} from "../../../../Api/Controller";
import TextField from "../../../../components/UI/TextField";
import { ButtonSecondary } from "../../../../components/UI/Buttons";
import Country from "../../../../components/country/Country";

const SignupSchema = Yup.object().shape({
  email: Yup.string("debe haber texto")
    .min(4, "El correo es muy corto")
    .max(50, "El correo es muy largo")
    .required("Este campo es requerido"),
  name: Yup.string("debe haber texto")
    .min(3, "El nombre  es muy corto")
    .max(50, "El nombre es largo")
    .required("Este campo es requerido"),
  lastName: Yup.string("debe haber texto")
    .min(4, "El apellido es muy corto")
    .max(50, "El apellido es muy largo")
    .required("Este campo es requerido"),
  phone: Yup.string("debe haber texto")
    .min(4, "debes introducir un numero de telefono valido")
    .max(50, "debes introducir un numero de telefono valido")
    .required("Este campo es requerido"),
});

export const AdminAddUser = ({
  userSelected = "",
  setAddUser,
  setEditUser,
  setUsers,
}) => {
  const { token, user } = React.useContext(UserContext);
  const typeUser = localStorage.getItem("type-user");

  function closeModal() {
    setAddUser(false);
    setEditUser(false);
  }
  /*const [hasCompany, setHasCompany] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);*/
  const [company, setCompany] = React.useState("default");
  const [edit, setEdit] = React.useState(false);
  const [country, setCountry] = React.useState('');

  const userRoles = ['user', 'admin'];

  const submitUser = (userSubmitted) => {
    if (userSelected._id) {
      UpdateUserController(userSubmitted, token, typeUser, user.role, setUsers);
    } else {
      AddUser(userSubmitted, setUsers);
    }
    setAddUser(false);
    setEditUser(false);
  };

  const initial = {
    email:"",
    name: "",
    password: "123456",
    lastName: "",
    phone: "",
    birth: moment().format('L'),
    role: "user",
    country: "",
    company: [],
    referedPoints: 0
  };

  const loadValues = {
    id: userSelected._id,
    email: userSelected.email,
    name: userSelected.name,
    birth: userSelected.birth ? moment(userSelected.birth).format('L') : moment().format('L'),
    lastName: userSelected.lastName,
    phone: userSelected.phone,
    company: userSelected.company,
    country: userSelected.country,
    role: userSelected.role,
    referedPoints: userSelected.referedPoints
  }
  React.useEffect(() => {
    setCountry(userSelected.country);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if(userSelected){
      setEdit(true);
    }
  }, [userSelected]);

  React.useMemo(() => {
    //GetCompanies(setCompanies);
    if(userSelected._id && (userSelected.company.length > 0)){
      setCompany(userSelected.company[0]._id);
    }
  }, [userSelected._id, userSelected.company]);

  return (
    <>
      <Grid
        container
        className="flex flex-col w-full bg-white opacity-100 rounded-2xl z-auto pt-80 lg:pt-0"
        spacing={1}
      >
        <Grid item className="flex justify-end items-center p-4" xs={12}>
          <Close onClick={closeModal} className="text-danger cursor-pointer" />
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={edit ? loadValues : initial}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => {
              values.country = country;
              if (company !== "default") {
                values.company.push(company);
              } else {
                values.company = [];
              }
              submitUser(values);
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={12} md={5}>
                    <h2 className="subtitle-2">Agregar usuario</h2>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="py-4"
                  justifyContent={"space-around"}
                  spacing={2}
                >
                  <Grid item xs={12} md={5}>
                    <label>Nombre</label>
                    <TextField
                      name="name"
                      error={errors.name}
                      touched={touched.name}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Apellido</label>
                    <TextField
                      name="lastName"
                      error={errors.lastName}
                      touched={touched.lastName}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Email</label>
                    <TextField
                      name="email"
                      error={errors.email}
                      touched={touched.email}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Phone</label>
                    <TextField
                      name="phone"
                      error={errors.phone}
                      touched={touched.phone}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Fecha de nacimiento</label>
                    <input
                      type="date"
                      name="birth"
                      id="birth"
                      className="border-2 border-field w-full p-4 rounded-lg"
                      onChange={handleChange}
                    />
                    <p>{errors.birth}</p>
                  </Grid>
                  {/*<Grid item xs={12} md={5}>
                    <label htmlFor="hasCompany">Esta en una empresa? (proximamente)</label>
                    <Checkbox
                      id="hasCompany"
                      disabled
                      onChange={(event) => setHasCompany(event.target.checked)}
                    ></Checkbox>
                    <Select
                      name="company"
                      className="w-full"
                      multiple={false}
                      disabled={!hasCompany}
                      value={company}
                      onChange={(event) => setCompany(event.target.value)}
                    >
                      <MenuItem value="default">
                        Seleccione una empresa
                      </MenuItem>
                      {companies.map((comp, index) => (
                        <MenuItem key={index} value={comp._id}>
                          {comp.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>*/}
                  <Grid item alignItems={'flex-end'} xs={12} md={5}>
                    <label>Rol</label>
                    <div className="w-full">
                      <Select
                        name="role"
                        className="w-full"
                        multiple={false}
                        value={values.role}
                        onChange={handleChange}
                      >
                        {userRoles.map((role, index) => (
                          <MenuItem key={index} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Puntos</label>
                    <TextField
                      name="referedPoints"
                      error={errors.referedPoints}
                      touched={touched.referedPoints}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={12} md={10}>
                    <label>Seleccione un pais</label>
                    <Country
                      value={country}
                      setValue={setCountry}
                      register={true}
                      showOptionLabel={true}
                      showSelectedLabel={true}
                    ></Country>
                  </Grid>
                </Grid>
                <Grid container className="p-4" justifyContent={"center"}>
                  <Grid item xs={12} md={3}>
                    <ButtonSecondary type="submit">
                      {userSelected._id ? "Editar" : "Agregar"}
                    </ButtonSecondary>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
