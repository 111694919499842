import React from "react";
import { Link, useParams } from 'react-router-dom';
import { Grid, Card, CardContent, CardActions } from "@mui/material";
import { Formik, Form } from "formik";

import { ChangePassword } from '../../../Api/Controller';
import { ButtonSecondary } from "../../../components/UI/Buttons";

export const RecoverPassword = () => {
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [invalid, setInvalid] = React.useState(false);
  const [equals, setEquals] = React.useState(false);
  const { id } = useParams();
  return (
    <>
      <div className="h-screen flex justify-center items-center">
        <Formik
          initialValues={{
            password: "",
          }}
          onSubmit={() => {
            setInvalid(false);
            setEquals(false);
            if (newPassword && confirmNewPassword) {
              if(newPassword === confirmNewPassword){
                ChangePassword(id, newPassword);
              } else {
                setEquals(true);
              }
            } else {
              setInvalid(true);
            }
          }}
        >
          <Form>
            <Card>
              <h3 className="p-4">Recuperar contraseña</h3>
              <CardContent>
                <Grid container>
                  <Grid item className="py-2" xs={12}>
                    <h5 className="text-left">Nueva contraseña</h5>
                    <input
                      id="password"
                      name="password"
                      className="form-field"
                      type="password"
                      onClick={() => setInvalid(false)}
                      onChange={(event) => setNewPassword(event.target.value)}
                    />
                    {invalid && (
                      <p className="text-danger text-left text-sm">Requerido</p>
                    )}
                  </Grid>
                  <Grid item className="py-2" xs={12}>
                    <h5 className="text-left">Confirmar contraseña</h5>
                    <input
                      id="confirm-password"
                      name="confirm-password"
                      className="form-field"
                      type="password"
                      onClick={() => setInvalid(false)}
                      onChange={(event) => setConfirmNewPassword(event.target.value)}
                    />
                    {invalid && (
                      <p className="text-danger text-left text-sm">Requerido</p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {equals && <p className="text-danger text-sm">Las contraseñas no coinciden</p>}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container>
                  <Grid item className="py-2" xs={12}>
                    <ButtonSecondary type="submit">Cambiar contraseña</ButtonSecondary>
                  </Grid>
                  <Grid item className="py-2" xs={12}>
                    <Link to='/login' className="cursor-pointer">Inicia sesion</Link>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Form>
        </Formik>
      </div>
    </>
  );
};