import { Grid, Typography } from '@mui/material';

import { termsAndConditions } from '../../constants/data';

export const Terms = () => {
  const styleContainer = {
    paddingX: '7%',
    paddingBottom: '1%'
  };
  const aspectText = {
    color: "#145374",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "40px",
    paddingY: 5
  };
  const subtitle = {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16.18px',
  }
  const paragraph = {
    fontFamily: 'Inter',
    fontSize: '16.18px',
    textAlign: 'left',
    paddingY: '0.5%',
    lineHeight: '160%',
    letterSpacing: '0.02em'
  }
  return (
    <>
      <br />
      <br />
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={aspectText}>Terminos y condiciones</Typography>
        </Grid>
      </Grid>
      {termsAndConditions.map(terms => {
        return(
          <Grid container sx={styleContainer}>
            <Grid item>
              <Typography sx={subtitle}>{terms.title}</Typography>
            </Grid>
            {terms.description.map(description => {
              return(
                <Grid item>
                  <Typography sx={paragraph}>{description}</Typography>
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </>
  );
}