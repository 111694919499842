import React from "react";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { PersonRemove, Edit, Person, ManageAccounts, Business, Group } from "@mui/icons-material";
import Swal from "sweetalert2";

import { GetAllUsers, RemoveUser, RemoveCompany } from "../../../Api/Controller";
import { ButtonSecondary } from "../../../components/UI/Buttons";
import { AdminAddUser } from "./Form/index";
import { AdminAddCompany } from "./CompanyForm/index";

export const AdminPanel = () => {
  const columns = [
    { field: "name", headerName: "First name", width: 200 },
    { field: "lastName", headerName: "Last name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "referedPoints", headerName: "Referred Points", width: 200 },
    { field: "role", headerName: "Tipo de usuario", width: 200 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          title="Editar usuario"
          label="Editar usuario"
          icon={<Edit className="text-blue-500" />}
          onClick={() => userToEdit(true, params.row)}
        ></GridActionsCellItem>,
        <GridActionsCellItem
          title="Eliminar usuario"
          label="Eliminar usuario"
          icon={<PersonRemove className="text-danger" />}
          onClick={() => deleteUser(params.row)}
        />,
      ],
    },
  ];
  const [users, setUsers] = React.useState([]);
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [admin, setAdmin] = React.useState(0);
  const [person, setPerson] = React.useState(0);
  const [company, setCompany] = React.useState(0);
  const [usersToShow, setUsersToShow] = React.useState([]);
  const [addUser, setAddUser] = React.useState(false);
  const [editUser, setEditUser] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState("");
  const [addCompany, setAddCompany] = React.useState(false);
  const [typeUser, setTypeUser] = React.useState("all");

  React.useEffect(() => {
    GetAllUsers(setUsers, setTotalUsers, setPerson, setAdmin, setCompany);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useMemo(() => {
    let filtered = [];
    if (typeUser === "all") {
      filtered = users;
    } else {
      filtered = users.filter((user) => {
        return user.role === typeUser;
      });
    }
    setUsersToShow(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeUser, users]);
  const userToEdit = (isEdit, user) => {
    setEditUser(isEdit);
    setUserSelected(user);
  };

  const deleteUser = (user) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podras revertir esto!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        if(user.role !== 'company'){
          RemoveUser(user._id, setUsers);
        } else {
          RemoveCompany(user._id, setUsers, setTotalUsers, setPerson, setAdmin, setCompany);
        }
      }
    });
  };

  return (
    <>
      <div className="py-10 px-4 space-y-3">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 className="text-center subtitle-3 md:text-left">Admin Panel</h2>
          </Grid>
        </Grid>
        <Grid container justifyContent={{ xs: "center", md: "left" }} spacing={2}>
          <Grid item xs={12}>
            <h2 className="text-left text-2xl">General</h2>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card variant="outlined">
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Group className="text-primary" />
                    <Typography className="subtitle-4">
                      Usuarios
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className="font-inter text-2xl text-center py-4 md:text-5xl">
                    {totalUsers}
                  </p>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card variant="outlined">
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Person className="text-info"/>
                    <Typography className="subtitle-4">
                      Personas
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className="font-inter text-2xl text-center py-4 md:text-5xl">
                    {person}
                  </p>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card variant="outlined">
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <ManageAccounts className="text-danger"/>
                    <Typography className="subtitle-4">
                      Administradores
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className="font-inter text-2xl text-center py-4 md:text-5xl">
                    {admin}
                  </p>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card variant="outlined">
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Business className="text-gray-400" />
                    <Typography className="subtitle-4">
                      Empresas
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className="font-inter text-2xl text-center py-4 md:text-5xl">
                    {company}
                  </p>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 className="text-left text-2xl">Usuarios</h2>
          </Grid>
          <Grid item xs={6} md={3}>
            <InputLabel id="filter-users" className="text-left">
              Filtrar por
            </InputLabel>
            <Select
              labelId="filter-users"
              className="w-full"
              id="filter"
              value={typeUser}
              label="Filtrar por"
              onChange={(e) => setTypeUser(e.target.value)}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              <MenuItem value={"user"}>Usuarios</MenuItem>
              <MenuItem value={"company"}>Empresas</MenuItem>
              <MenuItem value={"admin"}>Administradores</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={usersToShow}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowId={(row) => row._id}
              autoHeight={true}
              autoPageSize={true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ButtonSecondary onClick={() => setAddUser(true)}>
              Agregar Usuario
            </ButtonSecondary>
          </Grid>
          <Grid item xs={12} md={3}>
            <ButtonSecondary onClick={() => setAddCompany(true)}>
              Agregar Empresa
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      {(addUser || (editUser && userSelected.role !== "company")) && (
        <div
          id="formModal"
          className="fixed top-0 left-0 right-0 w-full z-30 h-full overflow-x-hidden overflow-y-auto md:inset-0 md:p-10"
          style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        >
          <div className="w-full h-full flex justify-center items-center py-20">
            <AdminAddUser
              userSelected={editUser ? userSelected : ""}
              setAddUser={setAddUser}
              setEditUser={setEditUser}
              setUsers={setUsers}
            />
          </div>
        </div>
      )}
      {(addCompany || (editUser && userSelected.role === "company")) && (
        <div
          id="formModal"
          className="fixed top-0 left-0 right-0 w-full z-30 h-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:p-10"
          style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        >
          <div className="w-full h-full flex justify-center items-center py-20">
            <AdminAddCompany
              companySelected={editUser ? userSelected : ""}
              setAddCompany={setAddCompany}
              setEditUser={setEditUser}
              setUsers={setUsers}
            />
          </div>
        </div>
      )}
    </>
  );
};
