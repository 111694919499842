import "./App.css";
import Routes from "./routes/routes";
import { UserContext } from "./context/userContext";
import { useContext } from "react";
import { Loader, Message, Error } from "./components/loader/Loader";
import NavBar from "./components/UI/NavBar";
import TopMenu from "./components/UI/TopMenu/TopMenu";
import { Footer } from './components/UI/footer/index';

function App() {
  const { message, loadding, error, token } = useContext(UserContext);
  return (
    <div className="App">
      <div className="header">
        {(!token) && <TopMenu/>}
        {(token) && <NavBar />}
      </div>
      {error && <Error />}
      {loadding && <Loader />}
      {message && <Message />}
      <Routes />
      <Footer/>
    </div>
  );
}

export default App;
