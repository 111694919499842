import { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";
import * as Yup from "yup";

import TextField from "../../components/UI/TextField";
import { UserContext } from "../../context/userContext";
import { TittleText } from "../../components/UI/Texts";
import { ButtonPrimary, ButtonSecondary } from "../../components/UI/Buttons";
import { LogInUserBusiness } from "../../Api/Controller";

const Ray = styled.div({
  width: "100%",
  height: 1,
  margin: "2vw 0vw",
  backgroundColor: "rgb(220,220,220)",
});
const Tittle = styled(TittleText)({});
const Card = styled(Form)({
  marginTop: "6vw",
  backgroundColor: "rgb(252,252,252)",
  padding: "2vw 2vw 0 2vw",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 10,
  boxShadow: "0 1vw 4vw 0vw rgba(205,205,205,0.2)",
});
const Wrapper = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 99,
  marginBottom: 80,
});
const SignupSchema = Yup.object().shape({
  email: Yup.string("debe haber texto")
    .min(4, "El correo es muy corto")
    .max(50, "El correo es muy largo")
    .required("Este campo es requerido"),
  password: Yup.string("debe haber texto")
    .min(5, "La contraseña es muy corta")
    .max(50, "La contraseña es muy larga")
    .required("Este campo es requerido"),
});
const BussinessLogin = () => {
  // - Ahora crear la validacion del login en el lado del servidor
  const { bussinesUser, setBussinesUser, setToken, setBountyData, setError, setLoadding } = useContext(UserContext);
  return (
    <>
      {bussinesUser.id && <Redirect to="/home" />}
      <Wrapper className="h-screen">
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item md={4} sm={6} xs={10} lg={3}>
            <Formik
              initialValues={{
                rut: "",
                email: "",
                password: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                LogInUserBusiness(values, setBussinesUser, setToken, setBountyData, setLoadding, setError)
              }}
            >
              {({ errors, touched }) => (
                <Card>
                  <Tittle size={25}>Inicio de sesión empresas</Tittle>
                  <Ray></Ray>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          error={errors.email}
                          touched={touched.email}
                          placeholder="Correo"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="password"
                          error={errors.password}
                          touched={touched.password}
                          placeholder="Contraseña"
                          type="password"
                        />
                      </Grid>
                    </Grid>
                    <ButtonPrimary type="submit">Iniciar sesión</ButtonPrimary>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to="/bussines"
                    >
                      <ButtonSecondary>Registrarme</ButtonSecondary>
                    </Link>
                  </div>
                </Card>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default BussinessLogin;
