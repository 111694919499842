import React from "react";
import { Formik, Form } from "formik";
import { Link, Redirect } from "react-router-dom";
import TextField from "../../components/UI/TextField";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { ButtonPrimary, ButtonSecondary } from "../../components/UI/Buttons";
import { Grid } from "@mui/material";
import { TittleText } from "../../components/UI/Texts";
import {
  SignInUserController,
  //SignInUserGoogleController,
  //SignInUserFacebookController,
} from "../../Api/Controller";
//import Background from "../../assets/bg1.jpg";
//import SignInGoogle from "../../components/google/Signin";
//import SignInFacebook from "../../components/facebook/Signin";
import { UserContext } from "../../context/userContext";
import { useContext } from "react";
const Ray = styled.div({
  width: "100%",
  height: 1,
  margin: "2vw 0vw",
  backgroundColor: "rgb(220,220,220)",
});
const Wrapper = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 99,
  marginBottom: 80,
});
const Tittle = styled(TittleText)({});
const Card = styled(Form)({
  marginTop: "6vw",
  backgroundColor: "rgb(252,252,252)",
  padding: "2vw 2vw 0 2vw",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 10,
  boxShadow: "0 1vw 4vw 0vw rgba(205,205,205,0.2)",
});

const SignupSchema = Yup.object().shape({
  email: Yup.string("debe haber texto")
    .min(4, "El correo es muy corto")
    .max(50, "El correo es muy largo")
    .required("Este campo es requerido"),
  password: Yup.string("debe haber texto")
    .min(5, "La contraseña es muy corta")
    .max(50, "La contraseña es muy larga")
    .required("Este campo es requerido"),
});
const Login = () => {
  const {
    user,
    setToken,
    setUser,
    setLoadding,
    setError,
    setBountyData,
  } = useContext(UserContext);
  /*const responseGoogle = (res) => {
    console.log(res);
    SignInUserGoogleController(res, setUser, setMessage, setLoadding, setError);
  };
  const responseFacebook = (res) => {
    console.log(res);
    SignInUserFacebookController(
      res,
      setUser,
      setMessage,
      setLoadding,
      setError
    );
  };*/
  return (
    <>
      {(user.id && <Redirect to="/home" />)}
      <Wrapper className="h-screen">
        <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item md={4} sm={6} xs={10} lg={3}>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                // same shape as initial values
                setLoadding(true);
                SignInUserController(
                  values,
                  setUser,
                  setToken,
                  setLoadding,
                  setError,
                  setBountyData,
                );
              }}
            >
              {({ errors, touched }) => (
                <Card>
                  <Tittle size={25}>Inicio de sesión</Tittle>
                  <Ray></Ray>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5%"
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          error={errors.email}
                          touched={touched.email}
                          placeholder="Correo"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="password"
                          error={errors.password}
                          touched={touched.password}
                          placeholder="Contraseña"
                          type="password"
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      container
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <Grid item xs={12}>
                        <SignInFacebook responseFacebook={responseFacebook} />
                      </Grid>
                      <Grid item xs={12}>
                        <SignInGoogle responseGoogle={responseGoogle} />
                      </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                      <ButtonPrimary type="submit">Iniciar sesión</ButtonPrimary>
                    </Grid>
                    <Grid item xs={12}>
                      <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/register"
                      >
                        <ButtonSecondary>Registrarme</ButtonSecondary>
                      </Link>
                    </Grid>
                    <Grid item className="py-4" xs={12}>
                      <Link to='/verify'>¿Olvido su contraseña?</Link>
                    </Grid>
                  </div>
                </Card>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};
export default Login;
