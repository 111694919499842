import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../views/Login/Login";
//import Register from "../views/register/Register";
import Home from "../views/home/Home";
import Bussines from "../views/bussines/Bussines";
import FrontPage from "../views/frontPage/FrontPage";
import BussinesLogin from "../views/Login/Bussiness-login";
import { BankDetail } from "../views/Bank/BankDetail";
import Register from "../views/register/Register";
import { VerifyEmail } from '../views/Login/Verify/index';
import { RecoverPassword } from '../views/Login/Recover/index';
import { SaveRoute } from '../components/SaveRoutes';
import { Terms } from '../views/Terms/index';
import { Privacy } from '../views/privacy/index';
const PublicRoute = ({ component, ...options }) => {
  const isAuth = false;
  if (!isAuth) return <Route {...options} component={component} />;
  return <Redirect to="/" />;
};

const Routes = () => {
  return (
    <div style={{height: "auto"}}>
      <Switch>
        <Route exact path="/" component={FrontPage}/>
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/business-login" component={BussinesLogin} />
        <PublicRoute exact path="/register" component={Register} />
        <PublicRoute exact path="/register/:referalId" component={Register} />
        <PublicRoute exact path="/bussines" component={Bussines}/>
        <PublicRoute exact path="/verify" component={VerifyEmail}/>
        <PublicRoute exact path="/recover/:id" component={RecoverPassword}/>
        <PublicRoute exact path="/terms" component={Terms}/>
        <PublicRoute exact path="/privacy" component={Privacy}/>
        <PublicRoute exact path="/home" render={() => <SaveRoute><Home/></SaveRoute>}/>
        <PublicRoute exact path="/bankDetail/:id" render={() => <SaveRoute><BankDetail/></SaveRoute>}/>
      </Switch>
    </div>
  );
};
export default Routes;
