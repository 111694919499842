import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { UserContext } from "../../context/userContext";
import { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import { GRADIENTBLUE, GRADIENTRED } from "../../constants/Colors";
const Tittle = styled.p({
  fontSize: 20,
  margin: 0,
  letterSpacing: 1,
  fontFamily: "Bebas Neue, cursive",
  width: 20,
  color: "white",
});
const Text = styled.p({
  fontSize: 20,
  marginTop: 15,
  fontFamily: "Roboto Condensed, sans-serif",
});
const Container = styled.div({
  position: "fixed",
  width: "100%",
  height: "120%",
  backgroundColor: "rgb(20,20,20,0.4)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 99,
});
export const Message = () => {
  const Card = styled.div({
    width: 300,
    height: 200,
    borderRadius: 10,
    boxShadow: "0 5px .8vw 0 rgba(20,20,20,0.5)",
    backgroundColor: "rgb(255,255,255)",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  });

  const Icon = styled(CloseIcon)({
    color: "white",
  });
  const ContainerIcon = styled.div({
    width: "100%",
    display: "flex",
    background: GRADIENTBLUE,
    borderRadius: "10px 10px 0px 0px",
    marginBottom: 15,
    justifyContent: "space-between",
    alignItems: "center",
  });
  const { message, setMessage } = useContext(UserContext);
  const handleClick = () => {
    setMessage("");
  };
  return (
    <>
      <Container>
        <Card>
          <ContainerIcon>
            <Tittle></Tittle>
            <Tittle>Alerta</Tittle>
            <IconButton
              style={{ marginRight: 5 }}
              onClick={() => handleClick()}
              aria-label="upload picture"
              component="label"
            >
              <Icon />
            </IconButton>
          </ContainerIcon>
          <Text>{message}</Text>
        </Card>
      </Container>
    </>
  );
};
export const Loader = () => {
  const CircularLoader = styled(CircularProgress)({
    color: "rgb(10,105,200)",
  });
  const Card = styled.div({
    width: 300,
    height: 200,
    borderRadius: 10,
    // boxShadow:"0 5px .8vw 0 rgba(20,20,20,0.5)",
    backgroundColor: "rgba(255,255,255,0)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: '1000'
  });
  return (
    <>
      <Container>
        <Card>
          <CircularLoader />
          <Tittle>Cargando...</Tittle>
        </Card>
      </Container>
    </>
  );
};

export const Error = () => {

  const Card = styled.div({
    width: 250,
    height: 180,
    borderRadius: 10,
    boxShadow: "0 5px .8vw 0 rgba(20,20,20,0.5)",
    backgroundColor: "rgb(255,255,255)",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  });
  const ContainerIcon = styled.div({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    background: GRADIENTRED,
    borderRadius: "10px 10px 0px 0px",
    marginBottom: 15,
    alignItems: "center",
  });
  const Icon = styled(CloseIcon)({
    color: "white",
  });
  const { error, setError } = useContext(UserContext);
  const handleClick = () => {
    setError("");
  };
  return (
    <>
      <Container>
        <Card>
          <ContainerIcon>
            <Tittle></Tittle>
            <Tittle>Error</Tittle>
            <IconButton
              onClick={() => handleClick()}
              aria-label="upload picture"
              component="label"
            >
              <Icon />
            </IconButton>
          </ContainerIcon>
          <Text>{error === "ERR_NETWORK" ? "Error de conexion" : error}</Text>
        </Card>
      </Container>
    </>
  );
};
