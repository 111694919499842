import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { RoundedButton } from "./Buttons";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import { Link } from "react-router-dom";
const ContainerButton = styled.div({
  width: "100%",
  marginBottom: "5%",
});
export default function SideMenu({ toggleDrawer, state }) {
  const { screen, user, bussinesUser, setScreen, setUser, setBountyData } = useContext(UserContext);
  const items = [
    {
      name: "Tablero",
      screen: 0,
      access: ((user && user.email) || (bussinesUser && bussinesUser.email)) ? true : false
    },
    {
      name: "Simulador",
      screen: 1,
      access: (user && (user.role === 'user' || user.role === 'admin')) ? true : false
    },
    {
      name: "Perfil",
      screen: 2,
      access: true
    },
    {
      name: "Dashboard",
      screen: (user && user.role === 'user') ? 4 : 5,
      access: (user.role === 'user' || user.role === 'companyMember' || bussinesUser.role === 'company') ? true : false
    },
    {
      name: "Panel",
      screen: 6,
      access: (user.role === 'admin') ? true : false
    }
  ];
  function logout () {
    localStorage.removeItem('token');
    localStorage.removeItem('type-user');
    localStorage.removeItem('result');
    localStorage.removeItem('results');
    localStorage.removeItem('details');
    localStorage.removeItem('bankDetail');
    localStorage.removeItem('loanDetails');
    localStorage.removeItem('screen');
    setUser({
      avatar: "",
      backgroundImage: "",
      name: "",
      lastName: "",
      phone: "",
      email: "",
      id: null,
      username: "",
      password: "",
      typeUser: "",
      country: "",
    });
    setBountyData({
      id_user: null,
      income: "",
      independent: "",
      yearsOfWork: "",
      couple: "",
      contribute: "",
      typeOfLoan: "",
    })
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{
        height: "100vh",
        backgroundColor: "#00334E",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      }}
    >
      <List>
        {items.map((item, index) => (
          <Link key={index} style={{ textDecoration: "none" }} to="/home">
            {item.access && <ListItem disablePadding>
              <ListItemButton
                style={{
                  backgroundColor: screen === index ? '#00334E' : null,
                }}
                onClick={() => {
                  localStorage.removeItem('result');
                  localStorage.removeItem('details');
                  localStorage.removeItem('loanDetails');
                  localStorage.removeItem('results');
                  localStorage.removeItem('bankDetail');
                  setScreen(item.screen)
                }}
              >
                <ListItemIcon
                  style={{
                    color: screen === item.screen ? "#19C8FA" : "rgb(250,250,250)",
                  }}
                  className="visited:bg-info"
                >
                  {index === 0 && <DashboardIcon />}
                  {index === 1 && <DesktopWindowsIcon/>}
                  {index === 2 && <AccountCircleIcon />}
                  {index === 3 && <BarChartIcon />}
                  {index === 4 && <BarChartIcon />}
                </ListItemIcon>
                <ListItemText
                  style={{ color: "rgb(250,250,250)" }}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>}
          </Link>
        ))}
      </List>
      <ContainerButton>
        <Grid container justifyContent={"center"}>
          <Grid item xs={10}>
            <a href="/">
              <RoundedButton type="button" onClick={logout}>Cerrar sesión</RoundedButton>
            </a>
          </Grid>
        </Grid>
      </ContainerButton>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor, index) => (
        <React.Fragment key={index}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
