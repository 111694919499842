import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import TextField from "../../components/UI/TextField";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { ButtonSecondary } from "../../components/UI/Buttons";
import { Grid } from "@mui/material";
import { TittleText } from "../../components/UI/Texts";
/*import {
  SignInUserController,
  SignInUserGoogleController,
  SignInUserFacebookController,
} from "../../Api/Controller";*/
import Background from "../../assets/bg1.jpg";
/*import SignInGoogle from "../../components/google/Signin";
import SignInFacebook from "../../components/facebook/Signin";*/
import { UserContext } from "../../context/userContext";
import { useContext } from "react";
import { Link, Redirect } from "react-router-dom";

import CustomCheckbox from "../../components/UI/CheckBox";
import {
  //SignUserController,
  SignInUserBussines,
  //SignUpUserGoogleController,
  //SignUpUserFacebookController,
} from "../../Api/Controller";
const Ray = styled.div({
  width: "100%",
  height: 1,
  marginBottom: "2vw",
  backgroundColor: "rgb(220,220,220)",
});
const Wrapper = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 99,
});
const Tittle = styled(TittleText)({
  color: "red",
});
const Text = styled.p({
  fontFamily: "Roboto Condensed, sans-serif",
  color: " rgb(60,60,100)",
});
const Url = styled(Link)({
  textDecoration: "none",
  color: " rgb(60,60,100)",
  letterSpacing: 1,
  fontFamily: "Bebas Neue, cursive",
});
const Card = styled(Form)({
  marginTop: "6vw",
  backgroundColor: "rgb(252,252,252)",
  padding: "2vw 1vw 0 2vw",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 10,
  boxShadow: "0 1vw 4vw 0vw rgba(50,50,50,0.5)",
  marginBottom: "6vw",
});

const SignupSchema = Yup.object().shape({
  email: Yup.string("debe haber texto")
    .min(4, "El correo es muy corto")
    .max(50, "El correo es muy largo")
    .required("Este campo es requerido"),
  user: Yup.string("debe haber texto")
    .min(4, "El nombre de usuario es muy corto")
    .max(50, "El nombre de usuario es muy largo")
    .required("Este campo es requerido"),
  name: Yup.string("debe haber texto")
    .min(3, "El nombre  es muy corto")
    .max(50, "El nombre es largo")
    .required("Este campo es requerido"),
  lastName: Yup.string("debe haber texto")
    .min(4, "El apellido es muy corto")
    .max(50, "El apellido es muy largo")
    .required("Este campo es requerido"),
  phone: Yup.string("debe haber texto")
    .min(4, "debes introducir un numero de telefono valido")
    .max(50, "debes introducir un numero de telefono valido")
    .required("Este campo es requerido"),
  password: Yup.string("debe haber texto")
    .min(5, "La contraseña es muy corta")
    .max(50, "La contraseña es muy larga")
    .required("Este campo es requerido"),
  typeEnterprise: Yup.string("debe haber texto")
    .min(2, "El tipo de empresa es muy corto")
    .max(50, "El tipo de empresa es muy larga")
    .required("Este campo es requerido"),
  position: Yup.string("debe haber texto")
    .min(1, "El cargo es muy corto")
    .max(50, "El cargo es muy largo")
    .required("Este campo es requerido"),
  rut: Yup.string("debe haber texto")
    .min(5, "El rut es muy corto")
    .max(50, "El rut es muy largo")
    .required("Este campo es requerido"),
  nameEnterprise: Yup.string("debe haber texto")
    .min(2, "El nombre de la empresa es muy es muy corto")
    .max(50, "El nombre de la empresa es muy es muy largo")
    .required("Este campo es requerido"),
});
const Bussines = () => {
  const { bussinesUser, setBussinesUser, setLoadding } =
    useContext(UserContext);
  return (
    <>
      {bussinesUser?.id && <Redirect to="/home" />}
      <div style={{ marginTop: "-10%", marginBottom: "-50%" }}>
        <img style={{ zIndex: 1, width: "100%" }} alt="" src={Background} />
      </div>
      <Wrapper>
        <Grid container justifyContent="center">
          <Grid item md={5} sm={6} xs={10} lg={4}>
            <Formik
              initialValues={{
                user: "",
                email: "",
                name: "",
                password: "",
                lastName: "",
                phone: "",
                typeEnterprise: "",
                position: "",
                rut: "",
                nameEnterprise: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                // same shape as initial values
                setLoadding(true);
                SignInUserBussines(values, setBussinesUser, setLoadding);
              }}
            >
              {({ errors, touched }) => (
                <Card>
                  <Tittle size={25}>Registrarme</Tittle>
                  <Ray></Ray>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          name="user"
                          error={errors.user}
                          touched={touched.user}
                          placeholder="Usuario"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="name"
                          error={errors.name}
                          touched={touched.name}
                          placeholder="Nombre"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="lastName"
                          error={errors.lastName}
                          touched={touched.lastName}
                          placeholder="Apellido"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="email"
                          error={errors.email}
                          touched={touched.email}
                          placeholder="Correo corporativo"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="phone"
                          error={errors.phone}
                          touched={touched.phone}
                          placeholder="Telefono"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="password"
                          error={errors.password}
                          touched={touched.password}
                          placeholder="Contraseña"
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="position"
                          error={errors.position}
                          touched={touched.position}
                          placeholder="Cargo en la empresa"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="typeEnterprise"
                          error={errors.typeEnterprise}
                          touched={touched.typeEnterprise}
                          placeholder="Tipo de empresa"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="nameEnterprise"
                          error={errors.nameEnterprise}
                          touched={touched.nameEnterprise}
                          placeholder="Nombre de la empresa"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="rut"
                          error={errors.rut}
                          touched={touched.rut}
                          placeholder="Rut"
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={1}>
                        <CustomCheckbox />
                      </Grid>
                      <Grid item xs={8}>
                        <Text>
                          Acepto los <Url to="/">terminos y condiciones</Url>{" "}
                        </Text>
                      </Grid>
                    </Grid>

                    <br />
                    <ButtonSecondary type="submit">Registrarme</ButtonSecondary>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={6}>
                        <Text>Ya tienes una cuenta?</Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Url to="/business-login">Iniciar sesión</Url>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};
export default Bussines;
