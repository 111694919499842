import React from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import axios from "axios";

import { typeOffers } from "../../../constants/data";
import { UserContext } from "../../../context/userContext";
import { CompanyContext } from "../../../context/companyContext";
import TextField from "../../UI/TextField";
import { ButtonPrimary } from "../../UI/Buttons";

const offersValidator = Yup.object().shape({
  name: Yup.string("Este campo es requerido").required(
    "Este campo es requerido"
  ),
  typeOffer: Yup.string().required("Campo obligatorio"),
  orientedTo: Yup.string().required("Campo obligatorio"),
  rateElements: Yup.string().required("Campo obligatorio"),
  summary: Yup.string("Breve descripcion")
    .min(10, "Debe tener minimo 10 caracteres")
    .max(100, "Debe tener maximo 30 caracteres")
    .required("Debe incorporar una breve descripcion"),
  description: Yup.string("Descripcion detallada")
    .min(10, "Debe tener minimo 10 caracteres")
    .max(500, "Debe tener maximo 500 caracteres")
    .required("Debe tener descripcion detallada"),
  price: Yup.string().required("Se require el precio del producto"),
});

export const FormOffers = ({
  openModal,
  offerData,
  edit,
  setOpenModal,
  setEdit,
}) => {
  const [rate, setRate] = React.useState("newrate");
  const ageRank = [
    {
      name: "Personas entre 18 y 30 años",
      value: "18 - 30",
    },
    {
      name: "Personas entre 31 y 45 años",
      value: "31 - 45",
    },
    {
      name: "Personas entre 46 y 60 años",
      value: "46 - 60",
    },
  ];
  const {
    bussinesUser: { id: companyId },
    loadding,
    setLoadding,
  } = React.useContext(UserContext);
  const [image, setImage] = React.useState(null);
  const [errorImage, setErrorImage] = React.useState(false);
  const getImage = (ev) => {
    const files = ev.target.files;
    setImage(files[0]);
  };
  const uploadImage = async (imgData) => {
    if (imgData) {
      const data = new FormData();
      data.append("file", imgData);
      data.append("upload_preset", "crediapOffersImages");
      data.append("cloud_name", "ark_software");
      setLoadding(true);
      const res = await axios.post(
        "https://api.cloudinary.com/v1_1/ark-software/image/upload",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return {
        url: res.data.url,
        publicKey: res.data.public_id,
        signature: res.data.signature,
      };
    } else {
      return false;
    }
  };

  const { setOffer, addOffer, updateOffer } = React.useContext(CompanyContext);

  const initialValues = {
    name: edit ? offerData.name : "",
    typeOffer: edit
      ? offerData.typeOffer || typeOffers[0].type
      : typeOffers[0].type,
    orientedTo: edit
      ? offerData.orientedTo || ageRank[0].value
      : ageRank[0].value,
    rateElements: edit ? offerData.rateElements : "",
    duration: 0,
    summary: edit ? offerData.summary : "",
    description: edit ? offerData.description : "",
    price: edit ? offerData.price : "0",
    offerImage: edit ? offerData.imageSummary : "",
    createdDate: edit ? offerData.createdAt : null
  };
  React.useEffect(() => {
    if (edit) {
      setOffer(offerData.typeOffer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid container justifyContent={"center"} spacing={1}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={offersValidator}
            onSubmit={async (values) => {
              //console.log(values);
              const urlImage = await uploadImage(image);
              if (urlImage) {
                const newOffer = {
                  ...values,
                  company: companyId,
                  imageSummary: urlImage,
                  imageDetail: urlImage.url,
                };
                if (edit) {
                  updateOffer(offerData._id, newOffer, setOpenModal);
                  setEdit(false);
                } else {
                  addOffer(newOffer, setOpenModal);
                }
                setLoadding(false);
              } else {
                setLoadding(false);
                setErrorImage(true);
              }
            }}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={1} justifyContent={"space-around"}>
                    <Grid item xs={12} md={6}>
                      <label>Tipo de oferta</label>
                      <div>
                        <select
                          id="typeOffer"
                          name="typeOffer"
                          className="w-full border-2 border-field p-3.5 rounded-lg"
                          onChange={handleChange}
                          defaultValue={
                            edit ? offerData.typeOffer : typeOffers[0].type
                          }
                        >
                          {typeOffers.map((offer, index) => (
                            <option key={index} value={offer.type}>
                              {offer.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>Dirigida a:</label>
                      <div>
                        <select
                          id="orientedTo"
                          name="orientedTo"
                          className="w-full border-2 border-field p-3.5 rounded-lg sm:w-full"
                          onChange={handleChange}
                          defaultValue={
                            edit ? offerData.orientedTo : ageRank[0].value
                          }
                        >
                          {ageRank.map((rank) => (
                            <option key={rank.value} value={rank.value}>
                              {rank.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>Nombre</label>
                      <TextField
                        name="name"
                        error={errors.offer}
                        touched={touched.offer}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>Resumen</label>
                      <TextField
                        name="summary"
                        error={errors.summary}
                        touched={touched.summary}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>Descripcion</label>
                      <TextField
                        name="description"
                        error={errors.description}
                        touched={touched.description}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>Precio</label>
                      <TextField
                        name="price"
                        type="number"
                        error={errors.price}
                        touched={touched.price}
                      ></TextField>
                    </Grid>
                    {values.typeOffer === "automotive-personal" && (
                      <Grid item xs={6}>
                        <FormControl sx={{ width: "100%" }}>
                          <FormLabel id="rate-elements">Tipo de tasa</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="rate-elements"
                            defaultValue="newrate"
                            name="rateElements"
                            onChange={(e) => {
                              setRate(e.target.value);
                              if (rate !== "zerorate") {
                                setFieldValue("rateElements", "zerorate");
                              } else {
                                setFieldValue("rateElements", "");
                              }
                            }}
                          >
                            <FormControlLabel
                              value="newrate"
                              control={<Radio />}
                              label="Nueva Tasa"
                            />
                            <FormControlLabel
                              value="zerorate"
                              control={<Radio />}
                              label="Tasa 0"
                            />
                          </RadioGroup>
                          <TextField
                            id="rateElements"
                            name="rateElements"
                            errors={errors.rateElements}
                            disabled={rate === "newrate" ? false : true}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {(values.typeOffer === "mortgage-personal" ||
                      values.typeOffer === "personal") && (
                      <Grid item xs={6}>
                        <label>Tasa</label>
                        <TextField
                          name="rateElements"
                          type="number"
                          error={errors.rateElements}
                          touched={touched.price}
                        />
                      </Grid>
                    )}
                    {values.typeOffer === "automotive" && (
                      <Grid item xs={6}>
                        <label>Estado</label>
                        <select
                          id="rateElements"
                          name="rateElements"
                          className="w-[16.2rem] border-2 border-field p-3.5 rounded-lg md:w-full"
                          onChange={handleChange}
                          defaultValue={edit ? offerData.rateElements : ""}
                        >
                          <option defaultValue>Seleccione una opcion</option>
                          <option value="new">Nuevo</option>
                          <option value="used">Usado</option>
                          <option value="nafta">Nafta</option>
                          <option value="diesel">Diesel</option>
                        </select>
                      </Grid>
                    )}
                    {(values.typeOffer === "mortgage-house" ||
                      values.typeOffer === "mortgage-apartment") && (
                      <Grid item xs={6}>
                        <label>Cantidad de dormitorios</label>
                        <select
                          id="rateElements"
                          name="rateElements"
                          className="w-[16.2rem] border-2 border-field p-3.5 rounded-lg md:w-full"
                          onChange={handleChange}
                          defaultValue={ values.rateElements }
                        >
                          <option defaultValue="">Seleccione una opcion</option>
                          <option value={1}>1 Dormitorio</option>
                          <option value={2}>2 Dormitorios</option>
                          <option value={3}>3 Dormitorios</option>
                          <option value={4}>4 Dormitorios</option>
                          <option value={5}>Mas de 4 dormitorios</option>
                        </select>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <label>Duracion</label>
                      <select
                        id="duration"
                        name="duration"
                        className="w-[16.2rem] border-2 border-field p-3.5 rounded-lg md:w-full"
                        onChange={handleChange}
                        defaultValue={values.duration}
                      >
                        <option defaultValue="">Seleccione una opcion</option>
                        <option value={7}>7 dias</option>
                        <option value={15}>15 dias</option>
                        <option value={30}>30 dias</option>
                      </select>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="flex flex-col space-y-1">
                        <label
                          className="block mb-2 text-sm font-medium text-gray-900"
                          htmlFor="offer-image"
                        >
                          Cargar imagen
                        </label>
                        <input
                          className="block w-full mb-5 text-sm text-gray-900 cursor-pointer bg-gray-50 focus:outline-none"
                          name="offerImage"
                          id="offer-image"
                          type="file"
                          placeholder="Seleccione imagen"
                          onChange={(e) => getImage(e)}
                        />
                        {errorImage && (
                          <p className="text-xs text-danger font-semibold">
                            Imagen invalida
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={5} md={4}>
                      <ButtonPrimary type="submit" isDisabled={loadding}>
                        {edit ? "Actualizar" : "Agregar"}
                      </ButtonPrimary>
                    </Grid>
                    <Grid item xs={5} md={4}>
                      <ButtonPrimary
                        onClick={() => {
                          setEdit(false);
                          setOffer("hipotecaria");
                          setOpenModal(!openModal);
                        }}
                        isDisabled={loadding}
                      >
                        Cerrar
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
