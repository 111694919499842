import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserProvider from "./context/userContext";
import { CompanyProvider } from './context/companyContext'
import { BrowserRouter as Router } from "react-router-dom";
//import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <UserProvider>
      <CompanyProvider>
        <Router>
          <App />
        </Router>
      </CompanyProvider>
    </UserProvider>
  </React.StrictMode>
);

/*
  Autenticacion con google esto va dentro de router y envuelve a App, queda pendiente
  <GoogleOAuthProvider clientId="889279306179-l4beopf36o0ulunkldqm0636efsneknp.apps.googleusercontent.com">
  </GoogleOAuthProvider>
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
