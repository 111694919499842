import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";

import SelectMultiple from "../../../components/UI/Select";
import { ButtonSecondary } from "../../../components/UI/Buttons";

import {
  setAmountToPayMonthly,
  setCapitalRecoveryFactor,
  setMonthlyFees,
  setMonthlyEffectiveRate,
  setTotalToPay,
  setMinPercentValidFromBalance,
} from "../../../helpers/calculations";

import { bankInterests } from "../../../constants/data";

export const MortageSimulator = ({ resultScreen, setResultValues }) => {
  //const [client, setClient] = useState(["Si"]);
  const [money, setMoney] = useState("usd");
  const [houseType, setHouseType] = useState("Primera");
  const [years, setYears] = useState(1);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [ammountRequired, setAmmountRequired] = useState(0);
  const results = [];
  const Text = styled.p({
    fontFamily: "Bebas Neue, cursive",
    margin: 0,
    fontSize: 20,
    color: "rgb(140,140,160)",
    textAlign: "left",
  });
  function cleanValues(value) {
    setTotal(value);
    if(value === '' || value < 1){
      setPercent(0)
      setAmmountRequired(0)
    } else {
      setAmmountRequired(value * (percent/100));
    }
  }
  function calculatePercent(value){
    setAmmountRequired(value)
    let result = 0
    result = ((value*100)/total)
    setPercent(result);
  }
  function calculateRequired(value){
    setPercent(value);
    let result = 0
    result = (total * (value/100));
    setAmmountRequired(result)
  }
  function setTea(money, index) {
    let tea;
    let tem;
    if (money === "usd") {
      tea = bankInterests[index].effectiveAnnualTask[money];
      tem = setMonthlyEffectiveRate(
        bankInterests[index].effectiveAnnualTask[money]
      );
    } else if (money === "peso uruguayo") {
      tea = bankInterests[index].effectiveAnnualTask.localMoneyOne;
      tem = setMonthlyEffectiveRate(
        bankInterests[index].effectiveAnnualTask.localMoneyOne
      );
    }
    return { tea, tem };
  }
  function getNecessaryBalance(index) {
    let necessaryBalance = 0;
    if (money === "usd") {
      necessaryBalance = bankInterests[index].maxPercentFromBalance[money];
    } else if (money === "peso uruguayo") {
      necessaryBalance = bankInterests[index].maxPercentFromBalance.localMoney;
    }
    return necessaryBalance;
  }
  function getFinancingAmmount(index, houseValue) {
    let financingAmmount = 0;
    financingAmmount =
      (houseValue * bankInterests[index].housingFinancing[houseType]) / 100;
    return financingAmmount;
  }
  const GetResults = (values) => {
    for (let index in bankInterests) {
      const { tea, tem } = setTea(money, index);
      const monthlyEffectiveRate = tem;
      const capitalRecoveryFactor = setCapitalRecoveryFactor({
        monthlyEffectiveRate,
        years,
      });
      const financingAmmount = getFinancingAmmount(index, values.houseValue);
      const fees = setMonthlyFees({
        capitalRecoveryFactor,
        amountRequired: ammountRequired,
      });
      const amountToPay = setAmountToPayMonthly({ fees, tea });
      const totalToPay = setTotalToPay({
        // - Importe a pagar
        fees,
        amountToPay,
        years,
      });
      const minValidBalance = setMinPercentValidFromBalance(
        fees,
        getNecessaryBalance(index)
      ).toFixed(0);
      const maxValidBalance = setMinPercentValidFromBalance(
        amountToPay,
        getNecessaryBalance(index)
      ).toFixed(0);
      results.push({
        loanType:'mortgage',
        bank: bankInterests[index].name,
        image: bankInterests[index].image,
        capitalRecoveryFactor,
        ammountRequired,
        annualEffectiveTax: tea,
        monthlyEffectiveRate,
        totalValue: values.houseValue,
        years: years,
        minFee: fees.toFixed(0),
        maxFee: amountToPay.toFixed(0),
        totalToPay,
        minValidBalance,
        maxValidBalance,
        moneyType: money,
        houseType: houseType,
        housingFinancing: bankInterests[index].housingFinancing[houseType],
        financingAmmount,
        aspects: bankInterests[index].aspects,
        conditions: bankInterests[index].conditions,
        requirements: bankInterests[index].requirements,
        additionalCosts: [
          {
            details: "Honorarios Compraventa (No inc Iva)",
            ammount: "USD 2,800"
          },
          {
            details: "Aportes Notariales",
            ammount: "USD 1,300"
          },
          {
            details: "Honorarios Hipoteca (No Inc Iva)",
            ammount: "USD 1,350"
          },
          {
            details: "Aportes Notariales",
            ammount: "USD 600"
          }
        ],
        previousCost:[
          {
            details: "Certificado Registro Nacional de Actos Personales",
            ammount: "$U 1,265"
          },
          {
            details: "Certificado información Registro Propiedad Inmobiliaria ",
            ammount: "$U 1,265"
          },
          {
            details: "Certificado Prenda sin desplazamiento",
            ammount: "$U 1,265"
          },
          {
            details: "Situación Contributiva",
            ammount: "$U 350"
          },
          {
            details: "Tasa inscripción Reserva de Prioridad",
            ammount: "$U 1,265"
          },
          {
            details: "Timbre formulario 1700 DGI",
            ammount: "$U 220"
          },
          {
            details: "Cedula catastral informada",
            ammount: "$U 630"
          },
          {
            details: "Tasas inscripción Registro",
            ammount: "$U 2,530"
          },
          {
            details: "Gestoría",
            ammount: "$U 1,800"
          },
        ]
      });
    }
    setResultValues(results);
    return;
  };
  return (
    <Grid container>
      <Grid item xs={12} className="p-4 rounded-md hover:bg-gray-200">
        <Typography variant="h5" component="div">
          Prestamo hipotecario
        </Typography>
        <br />
        <Formik
          initialValues={{
            moneyType: money,
            houseType: houseType,
            houseValue: 0,
            amountRequired: 0,
            years: years,
          }}
          onSubmit={(values) => {
            GetResults({
              ...values,
              houseValue: total,
              ammountRequired: ammountRequired,
            });
            resultScreen(true);
          }}
        >
          {({ errors, values }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Text>Tipo de vivienda</Text>
                  <SelectMultiple
                    name="houseType"
                    personName={houseType}
                    setPersonName={setHouseType}
                    data={["Primera", "Segunda"]}
                    isMultiple={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text>Indique el tipo de moneda</Text>
                  <SelectMultiple
                    name="moneyType"
                    personName={money}
                    setPersonName={setMoney}
                    data={["usd"]}
                    isMultiple={false}
                    onChange
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text>Valor del inmueble</Text>
                  <input
                    className="form-field"
                    type="number"
                    name="houseValue"
                    error={errors.moneyUsd}
                    placeholder="Value"
                    onChange={(event) => cleanValues(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text>Porcentaje a solicitar</Text>
                  <input
                    className="form-field"
                    name='percent'
                    type='number'
                    placeholder="Porcentaje a adquirir"
                    value={percent}
                    disabled={total <= 0 ? true : false}
                    onChange={(event) => calculateRequired(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text>Cantidad de dinero requerida</Text>
                  <input
                    className="form-field"
                    name="amountRequired"
                    type="number"
                    error={errors.ammount}
                    placeholder="Cantidad"
                    value={ammountRequired}
                    disabled={total <= 0 ? true : false}
                    onChange={(event) => calculatePercent(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text>En cuantos años desea pagar</Text>
                  <SelectMultiple
                    name="years"
                    personName={years}
                    setPersonName={setYears}
                    data={[
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                      18, 19, 20, 21, 22, 23, 24, 25,
                    ]}
                    isMultiple={false}
                    onChange
                  />
                </Grid>
                <br />
                <Grid container justifyContent={"center"}>
                  <Grid item xs={6} style={{marginTop: '4%'}}>
                    <ButtonSecondary disabled={(total <= 0) || (ammountRequired <= 0) ? true : false} type="submit">Calcular</ButtonSecondary>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
