import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { ButtonSecondary } from "../../../components/UI/Buttons";
import { UserContext } from '../../../context/userContext';
import { ConsumePoints } from '../../../Api/Controller';

import "./Details.css";

export const Details = ({ bankDetail, setDetails, setLoanDetails }) => {

  const { user, setUser } = React.useContext(UserContext);

  const goToResults = () => {
    setDetails(false);
  };
  const goToDetails = () => {
    setDetails(false);
    setLoanDetails(true);
    ConsumePoints(user, setUser);
  }
  const title = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "20px",
    textAlign: "center",
    color: "#145374",
  };
  const aspectText = {
    color: "#145374",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "40px",
    paddingY: 5,
  };
  const textSubtitle = {
    fontFamily: "Inter",
    color: "#145374",
    fontWeight: "700",
    textAlign: "left",
    fontSize: "16.18px",
  };
  const boxStyleTitle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: '5%',
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
  };
  const boxStyleInfo = {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    padding: "8px",
    //height: "78%",
    paddingBottom: "4px",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
  };
  const textFeature = {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16.18px",
    textAlign: "left",
  };
  const niceContainer = {
    paddingY: 6,
  };
  const listIcon = {
    fontSize: "5px",
    color: "black",
  };
  const listText = {
    fontFamily: "Inter",
    color: "#232323",
    fontSize: "16.18px",
    letterSpacing: "0.02em",
  };
  return (
    <>
      <Grid container justifyContent={''} alignContent={"center"} spacing={1}>
        <Grid item xs={8} md={2}>
          <ButtonSecondary onClick={goToResults}>
            Volver a los resultados
          </ButtonSecondary>
        </Grid>
        <Grid item xs={12}>
          <Card className="main-card">
            <Grid container justifyContent={"space-between"} spacing={1}>
              <Grid item xs={12} md={9}>
                <CardMedia
                  component="img"
                  className="image-card-detail"
                  image={bankDetail.image}
                  alt="Credits"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={boxStyleTitle}>
                  <Typography sx={title}>{bankDetail.bank}</Typography>
                </Box>
                <Box sx={boxStyleInfo}>
                  <span className="subtitle">Cuota mensual:</span>
                  {bankDetail.moneyType === "usd" && (
                    <>
                      <span className="info-text">
                        USD:
                        Entre USD {new Intl.NumberFormat('es-MX').format(bankDetail.minFee)} y USD {new Intl.NumberFormat('es-MX').format(bankDetail.maxFee)}
                      </span>
                      <span className="info-text">
                        Pesos:
                        Entre $U {new Intl.NumberFormat('es-MX').format((bankDetail.minFee * 40).toFixed(0))} y $U {new Intl.NumberFormat('es-MX').format((bankDetail.maxFee * 40).toFixed(0))}
                      </span>
                    </>
                  )}
                  <span className="info-text">
                    TEA{": "}
                    <b>{bankDetail.annualEffectiveTax} %</b>
                  </span>
                  <Divider />
                  <span className="subtitle">Importe a pagar</span>
                  {bankDetail.moneyType === "usd" && (
                    <>
                      <span className="info-text">
                        USD:
                        Entre USD {new Intl.NumberFormat('es-MX').format(bankDetail.totalToPay.min)} y USD {new Intl.NumberFormat('es-MX').format(bankDetail.totalToPay.max)}
                      </span>
                      <span className="info-text">
                        Pesos:
                        Entre $U {new Intl.NumberFormat('es-MX').format((bankDetail.totalToPay.min * 40).toFixed(0))} y $U {new Intl.NumberFormat('es-MX').format((bankDetail.totalToPay.max * 40).toFixed(0))}
                      </span>
                    </>
                  )}
                  <Divider />
                  <span className="subtitle">Salario minimo requerido</span>
                  {bankDetail.moneyType === "usd" && (
                    <>
                      <span className="info-text">
                        USD:
                        Entre USD {new Intl.NumberFormat('es-MX').format(bankDetail.minValidBalance)} y USD {new Intl.NumberFormat('es-MX').format(bankDetail.maxValidBalance)}
                      </span>
                      <span className="info-text">
                        Pesos:
                        Entre $U {new Intl.NumberFormat('es-MX').format((bankDetail.minValidBalance * 40).toFixed(0))} y $U {new Intl.NumberFormat('es-MX').format((bankDetail.maxValidBalance * 40).toFixed(0))}
                      </span>
                    </>
                  )}
                  <div className="margin-5">
                    <ButtonSecondary disabled={(user.referedPoints > 0) ? false : true } onClick={goToDetails}>Ver detalles {`(${user.referedPoints})`}</ButtonSecondary>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={2}>
              <Circle sx={listIcon} />
            </Grid>
            <Grid item xs={10}>
              <Typography sx={textFeature}>
                {bankDetail.aspects.aspectOne}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Circle sx={listIcon} />
            </Grid>
            <Grid item xs={10}>
              <Typography sx={textFeature}>
                {bankDetail.aspects.aspectTwo}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={2}>
              <Circle sx={listIcon} />
            </Grid>
            <Grid item xs={10}>
              <Typography sx={textFeature}>
                {bankDetail.aspects.aspectThree}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Circle sx={listIcon} />
            </Grid>
            <Grid item xs={10}>
              <Typography sx={textFeature}>
                {bankDetail.aspects.aspectFour}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={aspectText}>Caracteristicas</Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography sx={textSubtitle}>
            TASAS (en unidades indexadas)
          </Typography>
          <List>
            {bankDetail.conditions.conditionsOne.map((condition, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <Circle sx={listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={condition}
                  primaryTypographyProps={listText}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={1} className="divider">
          <Divider orientation="vertical" variant="middle" flexItem />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography sx={textSubtitle}>TASAS (en dolares)</Typography>
          <List>
            {bankDetail.conditions.conditionsTwo.map((condition, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <Circle sx={listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={condition}
                  primaryTypographyProps={listText}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Divider flexItem variant="middle" className="divider" />
      <Grid container sx={niceContainer}>
        <Grid item xs={12}>
          <Typography sx={aspectText}>Requisitos</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography sx={textSubtitle}>PERFIL DEL SOLICITANTE:</Typography>
          <List>
            {bankDetail.requirements.clientProfile.map((profileItem, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <Circle sx={listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={profileItem}
                  primaryTypographyProps={listText}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={1} className="divider">
          <Divider orientation="vertical" variant="middle" flexItem />
        </Grid>
        <Grid item xs={12} md={5} sx={{ paddingX: 5 }}>
          <Typography sx={textSubtitle}>DOCUMENTACION</Typography>
          <List>
            {bankDetail.requirements.documents.map((documentItem, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <Circle sx={listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={documentItem}
                  primaryTypographyProps={listText}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};
