import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";

import SelectMultiple from "../../../components/UI/Select";
//import TextField from "../../../components/UI/TextField";
import { ButtonSecondary } from "../../../components/UI/Buttons";

import {
  setAmountToPayMonthly,
  setCapitalRecoveryFactor,
  setMonthlyFees,
  setMonthlyEffectiveRate,
  setTotalToPay,
  setMinPercentValidFromBalance,
} from "../../../helpers/calculations";

import { bankDataAutomotive } from '../../../constants/data';

export const PersonalSimulator = ({ resultScreen, setResultValues }) => {
  const [money, setMoney] = useState('usd');
  const [vehicleType, setVehicleType] = useState("Nuevo");
  //const [clientType, setClientType] = useState(["Persona"]);
  const [years, setYears] = useState(1);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [ammountRequired, setAmmountRequired] = useState(0);

  function cleanValues(value) {
    setTotal(value);
    if(value === '' || value < 1){
      setPercent(0)
      setAmmountRequired(0)
    } else {
      setAmmountRequired(value * (percent/100));
    }
  }
  function calculatePercent(value){
    setAmmountRequired(value)
    let result = 0
    result = ((value*100)/total)
    setPercent(result);
  }

  function calculateRequired(value){
    setPercent(value);
    let result = 0
    result = ((value*total)/100);
    setAmmountRequired(result)
  }

  function setTea(money, index){
    let tea;
    let tem;
    if(money === 'usd') {
      tea = bankDataAutomotive[index].effectiveAnnualTask[money]
      tem = setMonthlyEffectiveRate(
        bankDataAutomotive[index].effectiveAnnualTask[money]
      );
    }else if(money === 'peso uruguayo'){
      tea = bankDataAutomotive[index].effectiveAnnualTask.localMoneyOne
      tem = setMonthlyEffectiveRate(
        bankDataAutomotive[index].effectiveAnnualTask.localMoneyOne
      );
    }
    return {tea, tem};
  }
  function getNecessaryBalance (index) {
    let necessaryBalance = 0;
    if(money === 'usd'){
      necessaryBalance = bankDataAutomotive[index].maxPercentFromBalance[money]
    }else if(money === 'peso uruguayo'){
      necessaryBalance = bankDataAutomotive[index].maxPercentFromBalance.localMoney
    }
    return necessaryBalance;
  }
  function getFinancingAmmount(index, value) {
    let financingAmmount = 0;
    financingAmmount = (value * bankDataAutomotive[index].vehicleFinancing[vehicleType]) / 100;
    return financingAmmount;

  }
  const GetResults = (values) => {
    const results = [];
    for(let index in bankDataAutomotive){
      const { tea, tem } = setTea(money, index);
      const monthlyEffectiveRate = tem;
      const capitalRecoveryFactor = setCapitalRecoveryFactor({
        monthlyEffectiveRate,
        years,
      });
      const financingAmmount = getFinancingAmmount(index, total)
      const fees = setMonthlyFees({
        capitalRecoveryFactor,
        amountRequired: ammountRequired,
        vehicleType,
      });
      const amountToPay = setAmountToPayMonthly({ fees });
      const totalToPay = setTotalToPay({
        fees,
        amountToPay,
        years,
      });
      const minValidBalance = (setMinPercentValidFromBalance(fees, getNecessaryBalance(index))).toFixed(0);
      const maxValidBalance = (setMinPercentValidFromBalance(amountToPay, getNecessaryBalance(index))).toFixed(0);
      results.push({
        bank: bankDataAutomotive[index].name,
        image: bankDataAutomotive[index].image,
        capitalRecoveryFactor,
        ammountRequired,
        totalValue: total,
        years: years,
        annualEffectiveTax: tea,
        monthlyEffectiveRate,
        minFee: fees.toFixed(0),
        maxFee: amountToPay.toFixed(0),
        totalToPay,
        minValidBalance,
        maxValidBalance,
        moneyType: money,
        vehicleType: vehicleType,
        financing: bankDataAutomotive[index].vehicleFinancing[vehicleType],
        financingAmmount,
        aspects: bankDataAutomotive[index].aspects,
        conditions: bankDataAutomotive[index].conditions,
        requirements: bankDataAutomotive[index].requirements
      });
    }
    setResultValues(results);
    return;
  };
  const Text = styled.p({
    fontFamily: "Bebas Neue, cursive",
    margin: 0,
    fontSize: 20,
    color: "rgb(140,140,160)",
    textAlign: "left",
  });
  return (
    <Grid item xs={12} className="p-4 rounded-md hover:bg-gray-200">
      <Typography variant="h5" component="div">
        Prestamo personal (proximamente)
      </Typography>
      <br />
      <Formik
        initialValues={{
          moneyType: money,
          vehicleType: vehicleType,
          vehicleValue: total,
          amountRequired: ammountRequired,
          years: 0,
        }}
        onSubmit={(values) => {
          resultScreen(true);
          GetResults({
            ...values,
            vehicleValue: total,
            ammountRequired: ammountRequired,
          });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Text>Profesion</Text>
                <input
                  className="form-field"
                  name="profesion"
                  error={errors.moneyUsd}
                  placeholder="Ej: activo"
                  disabled
                  onChange={(event) => cleanValues(event.target.value)}
                />
              </Grid>
              {/*<Grid item xs={12}>
                <Text>Tipo de cliente</Text>
                <SelectMultiple
                  name="clientType"
                  personName={clientType}
                  setPersonName={setClientType}
                  data={["Persona", "Empresa"]}
                  isMultiple={false}
                />
              </Grid>*/}
              <Grid item xs={12}>
                <Text>Indique el tipo de moneda</Text>
                <SelectMultiple
                  name="moneyType"
                  personName={money}
                  setPersonName={setMoney}
                  data={["usd"]}
                  disabled={true}
                  isMultiple={false}
                />
              </Grid>
              {/*<Grid item xs={12} style={{marginLeft: '-0.4rem'}}>
                <Text>Valor del vehiculo</Text>
                <input
                  className="form-field"
                  name="vehicleValue"
                  error={errors.moneyUsd}
                  placeholder="Valor en USD"
                  onChange={(event) => cleanValues(event.target.value)}
                />
              </Grid>*/}
              <Grid item xs={12} style={{marginLeft: '-0.4rem'}}>
                <Text>Porcentaje a solicitar</Text>
                <input
                  className="form-field"
                  name='percent'
                  type='number'
                  placeholder="Porcentaje a adquirir"
                  value={percent}
                  disabled={total <= 0 ? true : false}
                  onChange={(event) => calculateRequired(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{marginLeft: '-0.4rem'}}>
                <Text>Cantidad de dinero requerida</Text>
                <input
                  className="form-field"
                  name="amountRequired"
                  error={errors.ammount}
                  placeholder="Cantidad"
                  value={ammountRequired}
                  disabled={total <= 0 ? true : false}
                  onChange={(event) => calculatePercent(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Text>En cuantos años desea pagar</Text>
                <SelectMultiple
                  name="years"
                  personName={years}
                  setPersonName={setYears}
                  disabled={true}
                  data={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]}
                  isMultiple={false}
                  onChange
                />
              </Grid>
              <br />
              <Grid container justifyContent={"center"}>
                <Grid item xs={6} style={{marginTop: '4%'}}>
                  <ButtonSecondary disabled={(total <= 0) || (ammountRequired <= 0) ? true : false} type="submit">Calcular</ButtonSecondary>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
