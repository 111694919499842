import React from "react";
import styled from "@emotion/styled";
import "./item.css";
const Item = styled.p({
  padding: ".5vw .8vw",
  margin: "0vw .5vw",
  width: "100%",
  display: "flex",
  color: "white",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter, sans-serif",
  letterSpacing: 1,
  borderBottom: "solid .1vw rgba(0,0,0,0)",
});
const ItemMenu = ({ children }) => {
  return (
    <>
      <Item className="item">{children}</Item>
    </>
  );
};
export default ItemMenu;
