import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const SelectMultiple = ({ data = [], personName, setPersonName, isMultiple=true, disabled=false }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if(isMultiple){
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }else{
      setPersonName(value)
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <FormControl sx={{ width: "98%" }}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple={isMultiple}
            value={personName}
            onChange={handleChange}
            disabled={disabled}
            input={<OutlinedInput label="" />}
            renderValue={(selected) => Array.isArray(selected) ? selected.join(", ") : selected}
            MenuProps={MenuProps}
          >
            {data.map((name) => (
              <MenuItem
                style={{
                  fontFamily: "Bebas Neue, cursive",
                }}
                key={name}
                value={name}
              >
                {personName && isMultiple && (
                  <Checkbox checked={personName.indexOf(name) > -1} />
                )}
                <ListItemText
                  style={{
                    fontFamily: "Bebas Neue, cursive",
                  }}
                  primary={name}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};
export default SelectMultiple;
