import React from "react";
import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { ButtonSecondary } from "../../UI/Buttons";
import TextField from "../../UI/TextField";
import Country from "../../country/Country";
import { UserContext } from "../../../context/userContext";

const CompanyUserValidator = Yup.object().shape({
  name: Yup.string("Nombre real del usuario")
    .max(50, "Debe tener maximo 50 caracteres")
    .required("Este campo es requerido"),
  lastName: Yup.string("Apellido del usuario")
    .max(50, "Debe tener maximo 50 caracteres")
    .required("Este campo es requerido"),
  email: Yup.string("Correo electronico del usuario")
    .min(8, "Debe tener minimo 8 caracteres")
    .max(50, "Debe tener maximo 50 caracteres")
    .required("Este campo debe ser requerido"),
  phone: Yup.string("debe haber texto")
    .min(4, "debes introducir un numero de telefono valido")
    .max(50, "debes introducir un numero de telefono valido")
    .required("Este campo es requerido"),
});
export const AddUserFromCompany = ({ canEdit, userData, submitUser }) => {
  const { bussinesUser } = React.useContext(UserContext);
  const [country, setCountry] = React.useState(canEdit ? userData?.country : "");
  const startValues = {
    name: canEdit ? userData?.name : '',
    lastName: canEdit ? userData?.lastName : '',
    birth: canEdit ? userData.birth || '' : '',
    email: canEdit ? userData?.email : '',
    phone: canEdit ? userData?.phone : '',
  }
  return (
    <>
      <Formik
        initialValues={startValues}
        validationSchema={CompanyUserValidator}
        onSubmit={(values) => {
          values.company = bussinesUser.id;
          values.country = country;
          if(!canEdit) {
            values.password = "123456"
          } else {
            delete values.password;
          }
          submitUser(values);
        }
        }
      >
        {({ errors, touched, handleChange }) => (
          <Form>
            <div className="grid grid-cols-1">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="name"
                    errors={errors.name}
                    touched={touched.name}
                    placeholder="Nombre"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="lastName"
                    errors={errors.lastName}
                    touched={touched.lastName}
                    placeholder="Apellido"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="email"
                    errors={errors.email}
                    touched={touched.email}
                    placeholder="Correo"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="phone"
                    errors={errors.phone}
                    touched={touched.phone}
                    placeholder="Telefono"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="text-sm">Fecha de nacimiento</label>
                  <input
                    type="date"
                    name="birth"
                    id="birth"
                    placeholder="Fecha de nacimiento"
                    className="border-2 border-field w-full p-3 rounded-lg"
                    onChange={handleChange}
                  />
                  <p>{errors.birth}</p>
                </Grid>
              </Grid>
            </div>
            <Grid container className="py-4" spacing={2}>
              <Grid item xs={12}>
                <Country
                  value={country}
                  setValue={setCountry}
                  register={true}
                  showOptionLabel={true}
                  showSelectedLabel={true}
                />
              </Grid>
            </Grid>
            <Grid container className="py-4" justifyContent={"center"}>
              <Grid item xs={12} md={4}>
                <ButtonSecondary type="submit">{canEdit ? 'Editar' : 'Agregar'}</ButtonSecondary>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
