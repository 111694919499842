import Swal from "sweetalert2";
import { ApiService } from "./Api.js";

import "./Controller.css";

export const GetAllUsers = (
  setUsers,
  setTotal = null,
  setPersons = null,
  setAdmins = null,
  setCompanies = null
) => {
  ApiService.getUsers()
    .then((res) => {
      setUsers(res.data.users);
      setPersons(res.data.persons);
      setAdmins(res.data.admins);
      setCompanies(res.data.companies);
      setTotal(res.data.total);
    })
    .catch((err) => {
      console.error(err);
    });
};
export const SignUserController = (User, setUser, setLoadding, setToken) => {
  ApiService.registrarUser(User)
    .then((res) => {
      const user = res.data;
      setLoadding(false);
      if (user.msg) {
        Swal.fire("Error", user.msg, "error");
      } else {
        setUser({
          avatar: res.data.usr.avatar,
          backgroundImage: res.data.usr.BGImage,
          name: res.data.usr.name,
          lastName: res.data.usr.lastName,
          phone: res.data.usr.phone,
          email: res.data.usr.email,
          id: res.data.usr._id,
          username: res.data.usr.user,
          typeUser: res.data.usr.type,
          country: res.data.usr.country,
          usersRefered: res.data.usr.usersRefered,
          referalCode: res.data.usr.referalCode,
          referedPoints: res.data.usr.referedPoints,
          role: res.data.usr.role,
        });
        localStorage.setItem("token", res.data.token);
        setToken(res.data.token);
      }
    })
    .catch(function (error) {
      console.error("Algo salio mal registrando usuario", error);
    });
};
export const AddUser = (User, setUsers) => {
  ApiService.registrarUser(User)
    .then((res) => {
      const user = res.data;
      if (user) {
        Swal.fire({
          title: "Registrado",
          text: "Su usuario ha sido registrado",
          icon: "success",
        });
        ApiService.getUsers().then((res) => {
          setUsers(res.data.users);
        });
      }
    })
    .catch((err) => {
      console.error(err);
      Swal.fire({
        title: "Error",
        text: err.message,
        icon: "error",
      });
    });
};
export const AddCompany = (Company, setUsers) => {
  ApiService.registrarUserBussines(Company)
    .then((res) => {
      const user = res.data;
      if (user) {
        Swal.fire({
          title: "Registrado",
          text: "Su empresa ha sido registrada",
          icon: "success",
        });
        ApiService.getUsers().then((res) => {
          setUsers(res.data.users);
        });
      }
    })
    .catch((err) => {
      console.error(err);
      Swal.fire({
        title: "Error",
        text: err.message,
        icon: "error",
      });
    });
};
export const UpdateUserController = (User, token, typeUser, role, setUsers) => {
  ApiService.updateUser(User, token, typeUser)
    .then((res) => {
      if (res.status === 200) {
        Swal.fire("Modificado", "Datos modificados", "success");
        if (role === "admin") {
          ApiService.getUsers().then((res) => {
            setUsers(res.data.users);
          });
        }
      } else {
        Swal.fire("Error", "No se pudo hacer los cambios", "error");
      }
    })
    .catch((err) => {
      Swal.fire("Error", err.message, "error");
    });
};
export const ChangePassword = (id, password) => {
  ApiService.changePassword(id, password)
    .then((res) => {
      Swal.fire({
        title: "Completado",
        text: "Su contrasena ha sido modificada",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          window.location.href = "/login";
        }
      });
    })
    .catch((err) => {
      console.error(err);
    });
};
export const ConsumePoints = (user, setUser) => {
  ApiService.consumePoints({ userId: user.id }).then((res) => {
    if (res.status === 200) {
      const newPoints = user.referedPoints - 1;
      setUser({
        ...user,
        referedPoints: newPoints,
      });
    }
  });
};
export const SignUpUserGoogleController = (User, setUser) => {
  ApiService.registrarUserGoogle(User)
    .then((res) => {
      setUser({
        avatar: res.data.avatar,
        name: res.data.name,
        lastName: res.data.lastName,
        email: res.data.email,
        id: res.data._id,
        username: res.data.user,
        typeUser: "",
        phone: "",
        backgroundImage: "",
      });
    })

    .catch(function (error) {
      alert("Algo salio mal registrando ususario", error.message);
    });
};
export const SignUpUserFacebookController = (User, setUser) => {
  ApiService.registrarUserFacebook(User)
    .then((res) => {
      setUser({
        avatar: res.data.avatar,
        name: res.data.name,
        lastName: res.data.lastName,
        email: res.data.email,
        id: res.data._id,
        username: res.data.user,
        typeUser: "",
        phone: "",
        backgroundImage: "",
      });
    })

    .catch(function (error) {
      alert("Algo salio mal registrando ususario", error.message);
    });
};
export const SignInUserController = (
  user,
  setUser,
  setToken,
  setLoadding,
  setError,
  setBountyData
) => {
  ApiService.login({ ...user, lastConnection: new Date() })
    .then((res) => {
      setLoadding(false);
      const data = res.data.user;
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("type-user", "user");
      setToken(res.data.token);
      if (data.msg) {
        setError(data.msg);
      }
      setUser({
        password: data.password,
        avatar: data.avatar,
        backgroundImage: data.BGImage,
        name: data.name,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        birth: data.birth,
        id: data._id,
        username: data.user,
        typeUser: data.type,
        country: data.country,
        lastConnection: data.lastConnection,
        company: data.company,
        referalCode: data.referalCode,
        role: data.role,
      });
      if (data.bounty_id) {
        const bountyData = {
          id_user: data.bounty_id.id_user,
          income: data.bounty_id.income,
          independent: data.bounty_id.independent,
          yearsOfWork: data.bounty_id.yearsOfWork,
          couple: data.bounty_id.couple,
          typeOfLoan: data.bounty_id.typeOfLoan,
        };
        setBountyData(bountyData);
      }
    })
    .catch(function (error) {
      setLoadding(false);
      console.error(error);
      setError(error.response.data.msg);
    });
};
export const GetUser = (token, setUser, setBountyData) => {
  ApiService.getUser(token)
    .then((res) => {
      const user = res.data;
      if (user) {
        setUser({
          avatar: user.avatar,
          backgroundImage: user.BGImage,
          name: user.name,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          birth: user.birth,
          id: user._id,
          username: user.user,
          typeUser: user.type,
          country: user.country,
          lastConnection: user.lastConnection,
          company: user.company,
          referalCode: user.referalCode,
          usersRefered: user.usersRefered,
          referedPoints: user.referedPoints,
          age: user.age,
          role: user.role,
        });
        if (user.bounty_id) {
          setBountyData({
            couple: user.bounty_id.couple,
            income: user.bounty_id.income,
            id_user: user.bounty_id.id_user,
            independent: user.bounty_id.independent,
            typeOfLoan: user.bounty_id.typeOfLoan,
            yearsOfWork: user.bounty_id.yearsOfWork,
          });
        }
        localStorage.setItem("type-user", "user");
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};
export const SearchUser = (data, setSearchedUser, setError) => {
  ApiService.searchUser(data)
    .then((res) => {
      data = res.data;
      setSearchedUser({
        avatar: data.avatar,
        backgroundImage: data.backgroundImage,
        name: data.name,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        id: data._id,
        username: data.username,
        typeUser: data.typeUser,
        country: data.country,
        lastConnection: data.lastConnection,
        referalCode: data.referalCode,
        referedPoints: data.referedPoints,
        role: data.role,
      });
      window.location.href = `/recover/${data._id}`;
    })
    .catch((err) => {
      Swal.fire({
        title: "Error",
        text: "Usuario no encontrado",
        icon: "error",
      }).then((res) => {
        if (res.isConfirmed) {
          setSearchedUser({
            avatar: "",
            backgroundImage: "",
            name: "",
            lastName: "",
            phone: "",
            email: "",
            id: null,
            username: "",
            password: "",
            typeUser: "",
            country: "",
            lastConnection: "",
            referalCode: "",
            referedPoints: 0,
          });
          console.error(err);
        }
      });
    });
};
export const GetBusinessUser = (token, setBussinesUser, setBountyData) => {
  ApiService.getBusinessUser(token)
    .then((res) => {
      const user = res.data;
      if (user) {
        setBussinesUser({
          id: user._id,
          user: user.user,
          email: user.email,
          name: user.name,
          password: user.password,
          lastName: user.lastName,
          phone: user.phone,
          typeEnterprise: user.typeEnterprise,
          position: user.position,
          rut: user.rut,
          nameEnterprise: user.nameEnterprise,
          avatar: user.avatar,
          country: user.country,
          availableQuotes: user.availableQuotes,
          lastConnection: user.lastConnection,
          users: user.users,
          role: user.role,
        });
        if (user.bounty_id) {
          setBountyData({
            couple: user.bounty_id.couple,
            income: user.bounty_id.income,
            id_user: user.bounty_id.id_user,
            independent: user.bounty_id.independent,
            typeOfLoan: user.bounty_id.typeOfLoan,
            yearsOfWork: user.bounty_id.yearsOfWork,
          });
        }
        localStorage.setItem("type-user", "business");
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};
export const GetCompanies = (setCompanies) => {
  ApiService.getCompanies()
    .then((res) => {
      setCompanies(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
};
export const SignInUserGoogleController = (
  user,
  setUser,
  setMessage,
  setLoadding,
  setError
) => {
  ApiService.loginGoogle(user)
    .then((res) => {
      const data = res.data;
      if (data === "user") {
        setError("No se ha encontrado un usuario con ese correo");
      } else if (data === "password") {
        setError("Su contraseña es incorrecta");
      } else {
        setUser({
          password: data.password,
          avatar: data.avatar,
          backgroundImage: data.BGImage,
          name: data.name,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          id: data._id,
          username: data.user,
          typeUser: data.type,
        });
      }
    })
    .catch(function (error) {
      alert("Algo salio mal registrando ususario", error.message);
    });
};
export const SignInUserFacebookController = (
  user,
  setUser,
  setMessage,
  setLoadding,
  setError
) => {
  ApiService.loginFacebook(user)
    .then((res) => {
      const data = res.data;
      if (data === "user") {
        setError("No se ha encontrado un usuario con ese correo");
      } else if (data === "password") {
        setError("Su contraseña es incorrecta");
      } else {
        setUser({
          password: data.password,
          avatar: data.avatar,
          backgroundImage: data.BGImage,
          name: data.name,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          id: data._id,
          username: data.user,
          typeUser: data.type,
          country: data.country,
        });
      }
    })
    .catch(function (error) {
      alert("Algo salio mal registrando ususario", error.message);
    });
};
export const SignInUserBussines = (user, setUser, setLoadding) => {
  ApiService.registrarUserBussines(user)
    .then((res) => {
      setLoadding(false);
      const data = res.data.usr;
      setUser({
        id: data._id,
        user: data.user,
        email: data.email,
        name: data.name,
        lastName: data.lastName,
        phone: data.phone,
        typeEnterprise: data.typeEnterprise,
        position: data.position,
        rut: data.rut,
        country: data.country,
        nameEnterprise: data.nameEnterprise,
        avatar: data.avatar,
        lastConnection: data.lastConnection,
        users: data.users,
        role: data.role,
      });
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("type-user", "business");
    })
    .catch(function (error) {
      console.error(error);
      // alert('Algo salio mal registrando ususario', error.message);
    });
};
export const LogInUserBusiness = (
  user,
  setBussinesUser,
  setToken,
  setBountyData,
  setLoadding,
  setError
) => {
  ApiService.loginBusiness({ ...user, lastConnection: new Date() })
    .then((res) => {
      setLoadding(false);
      const data = res.data.user;
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("type-user", "business");
      setToken(res.data.token);
      if (data.msg) {
        setError(data.msg);
      }
      setBussinesUser({
        id: data._id,
        user: data.user,
        email: data.email,
        name: data.name,
        password: data.password,
        lastName: data.lastName,
        phone: data.phone,
        typeEnterprise: data.typeEnterprise,
        position: data.position,
        rut: data.rut,
        nameEnterprise: data.nameEnterprise,
        avatar: data.avatar,
        country: data.country,
        availableQuotes: data.availableQuotes,
        referalCode: data.referalCode,
        lastConnection: data.lastConnection,
        users: data.users,
        role: data.role,
      });
      if (data.bounty_id) {
        setBountyData({
          couple: data.bounty_id.couple,
          income: data.bounty_id.income,
          id_user: data.bounty_id.id_user,
          independent: data.bounty_id.independent,
          typeOfLoan: data.bounty_id.typeOfLoan,
          yearsOfWork: data.bounty_id.yearsOfWork,
        });
      }
    })
    .catch((err) => {
      setError(err.response.data.msg);
    });
};
export const AddBountyData = (data, setBountyData, token) => {
  const user = data;
  ApiService.addBountyData(user, token)
    .then((res) => {
      setBountyData({
        couple: user.couple,
        income: user.income,
        id_user: user.id,
        independent: user.independent,
        typeOfLoan: user.typeOfLoan,
        yearsOfWork: user.yearsOfWork,
      });
      Swal.fire("Agregado", "Datos agregados con exito", "success");
    })
    .catch(function (error) {
      Swal.fire("Error", "No se agregaron los datos", "error");
      console.error(error);
    });
};
export const AddCompanyUser = (data, setBussinesUser) => {
  ApiService.addCompanyUser(data)
    .then((res) => {
      if (res.status === 200) {
        const user = res.data;
        Swal.fire({
          title: "Agregado",
          text: "Usuario agregado satisfactoriamente",
          icon: "success",
        }).then(() => {
          setBussinesUser({
            id: user._id,
            user: user.user,
            email: user.email,
            name: user.name,
            password: user.password,
            lastName: user.lastName,
            phone: user.phone,
            typeEnterprise: user.typeEnterprise,
            position: user.position,
            rut: user.rut,
            nameEnterprise: user.nameEnterprise,
            avatar: user.avatar,
            country: user.country,
            lastConnection: user.lastConnection,
            users: user.users,
          });
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
export const UpdateCompanyUser = (data, bussinessUser, setBussinesUser) => {
  ApiService.updateCompanyUser(data).then((res) => {
    let company = bussinessUser;
    company.users = [...bussinessUser.users];
    if (company.users.length > 0) {
      const isInCompany = company.users.findIndex(
        (user) => user._id === res.data._id
      );
      if (isInCompany !== -1) {
        company.users.splice(isInCompany, 1, res.data);
        Swal.fire({
          title: "Modificado",
          text: "Los datos del usuario se modificaron con exito",
          icon: "success",
        }).then((res) => {
          if (res.isConfirmed) {
            setBussinesUser({
              ...bussinessUser,
              users: company.users,
            });
          }
        });
      }
    }
  });
};
export const RemoveUserFromCompany = (data, setBussinesUser) => {
  ApiService.removeUserFromCompany(data)
    .then((res) => {
      const user = res.data;
      if (user._id) {
        setBussinesUser({
          id: user._id,
          user: user.user,
          email: user.email,
          name: user.name,
          password: user.password,
          lastName: user.lastName,
          phone: user.phone,
          typeEnterprise: user.typeEnterprise,
          position: user.position,
          rut: user.rut,
          nameEnterprise: user.nameEnterprise,
          avatar: user.avatar,
          country: user.country,
          lastConnection: user.lastConnection,
          users: user.users,
        });
        Swal.fire("Eliminado!", "El usuario a sido eliminado.", "success");
      } else {
        alert(user.msg);
        Swal.fire("Error!", user.msg, "error");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
export const RemoveUser = (id, setUsers) => {
  ApiService.removeUser(id)
    .then((res) => {
      ApiService.getUsers().then((res) => {
        setUsers(res.data.users);
      });
    })
    .catch((err) => {
      console.error(err);
    });
};
export const RemoveCompany = (
  id,
  setUsers,
  setTotal = null,
  setPersons = null,
  setAdmins = null,
  setCompanies = null
) => {
  ApiService.removeCompany(id)
    .then((res) => {
      GetAllUsers(setUsers, setTotal, setPersons, setAdmins, setCompanies);
    })
    .catch((err) => {
      console.error(err);
    });
};
export const UpdateCompany = (Company, token, typeUser, role, setUsers) => {
  ApiService.updateCompany(Company, token)
    .then((res) => {
      Swal.fire({
        title: "Modificado",
        text: "Sus datos han sido modificados",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          if (res && role === "admin") {
            ApiService.getUsers().then((res) => {
              setUsers(res.data.users);
            });
          }
        }
      });
    })
    .catch((res) => {
      console.error(res);
      Swal.fire({
        title: "Error",
        text: "Error al actualizar los datos",
        icon: "error",
      });
    });
};
export const BuyOffersQuotes = (
  payload,
  setProgress
) => {
  ApiService.buyOffersQuotes(payload)
    .then((result) => {
      if (result.data) {
        window.location.href = result.data.url;
        setProgress(false);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
export const GetOffers = (
  setAllOffers,
  setOffersSorted,
  setMortgageOffersList,
  setAutomotiveOffersList,
  setMortgageOffers,
  setAutoMotiveOffers
) => {
  ApiService.getAllOffers()
    .then((res) => {
      const { offers } = res.data;
     // console.log(offers);
      //TODO: ver si se puede reorganizar esta informacion en un solo estado
      setAllOffers(offers);
      setMortgageOffersList(offers.mortgage);
      setAutomotiveOffersList(offers.automotive);
      /*setMortgageOffers(offers.total.mortgage);
      setAutoMotiveOffers(offers.total.automotive);
      setOffersSorted(res.data.offersSorted);*/
    })
    .catch((err) => {
      console.error(err);
    });
};
export const GetOffersByCompany = (
  companyId,
  setAllOffers,
  setOffersSorted,
  setMortgageOffersList,
  setAutomotiveOffersList,
  setMortgageOffers,
  setAutoMotiveOffers
) => {
  ApiService.getOffersByCompany(companyId)
    .then((res) => {
      console.log(res.data);
      const offers = res.data.offers;
      const offersSorted = res.data.offersSorted;
      setAllOffers(offers.all);
      setMortgageOffersList(offers.mortgage);
      setAutomotiveOffersList(offers.automotive);
      setMortgageOffers(offers.total.mortgage);
      setAutoMotiveOffers(offers.total.automotive);
      setOffersSorted(offersSorted);
    })
    .catch((err) => {
      console.error(err);
    });
};
export const AddOffer = (
  offer,
  allOffers,
  setAllOffers,
  mortgageOffers,
  setMortgageOffers,
  automotiveOffers,
  setAutoMotiveOffers,
  setOpenModal,
  bussinesUser,
  setBussinesUser
) => {
  ApiService.addOffer(offer)
    .then((response) => {
      const company = { ...bussinesUser };
      setOpenModal(false);
      Swal.fire({
        title: "Agregada",
        text: "Su oferta ha sido agregada",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          setAllOffers([...allOffers, response.data]);
          if (response.data.typeOffer === "hipotecaria") {
            setMortgageOffers(mortgageOffers + 1);
          } else if (response.data.typeOffer === "automotor") {
            setAutoMotiveOffers(automotiveOffers + 1);
          }
          if(offer.duration === '7'){
            company.availableQuotes.sevenDays.quantity -= 1;
          }else if(offer.duration === '15'){
            company.availableQuotes.fifteenDays.quantity -= 1;
          }else if(offer.duration === '30'){
            company.availableQuotes.thirteenDays.quantity -= 1;
          }
          setBussinesUser({ ...company });
        }
      });
    })
    .catch((error) => console.error(error));
};
export const UpdateOffer = (
  id,
  offer,
  allOffers,
  setAllOffers,
  setMortgageOffers,
  setAutoMotiveOffers,
  setOpenModal
) => {
  ApiService.updateOffer(id, offer).then((result) => {
    const data = result.data;
    if (setOpenModal) {
      setOpenModal(false);
      Swal.fire({
        title: "Modificada",
        text: "Su oferta ha sido modificada",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          const offers = [...allOffers];
          const offerPosition = offers.findIndex((offer) => offer._id === id);
          offers.splice(offerPosition, 1, data);
          setAllOffers(offers);
        }
      });
    }
  });
};
export const UpdateOfferViews = (id, views) => {
  ApiService.updateOfferViews(id, views).then((res) => {
    //console.log(res);
  });
};
export const RemoveOffer = (id, allOffers, setAllOffers) => {
  ApiService.removeOffer(id.offerId)
    .then((response) => {
      Swal.fire({
        title: "Eliminada",
        text: "Oferta eliminada",
        icon: "success",
      })
        .then((res) => {
          if (res.isConfirmed) {
            const offers = [...allOffers];
            const offerPosition = offers.findIndex(
              (offer) => offer.code === response.data.code
            );
            offers.splice(offerPosition, 1);
            setAllOffers(offers);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    })
    .catch((error) => {
      console.error(error);
    });
};
export const uploadImage = async (img) => {
  const res = await ApiService.uploadImage(img);
  return res.data;
};

/*export const attachUserToCompany = (data, setBussinesUser) => {
  ApiService.attachUserToCompany(data)
  .then(res => {
    const user = res.data;
    setBussinesUser(
      {
        id: user._id,
        user: user.user,
        email: user.email,
        name: user.name,
        password: user.password,
        lastName: user.lastName,
        phone: user.phone,
        typeEnterprise: user.typeEnterprise,
        position: user.position,
        rut: user.rut,
        nameEnterprise: user.nameEnterprise,
        avatar: user.avatar,
        country: user.country,
        lastConnection: user.lastConnection,
        users: user.users
      }
    );
  })
  .catch(error => {
    console.log(error);
  })
}*/
