import React from "react";
import { Link } from "react-router-dom";
import { Grid, Card, CardContent, CardActions } from "@mui/material";
import { Formik, Form } from "formik";

import { SearchUser } from "../../../Api/Controller";
import { UserContext } from "../../../context/userContext";
import { ButtonSecondary } from "../../../components/UI/Buttons";

export const VerifyEmail = () => {
  const [email, setEmail] = React.useState("");
  const [invalid, setInvalid] = React.useState(false);
  const { setSearchedUser, setError } = React.useContext(UserContext);

  React.useEffect(() => {
    setSearchedUser(
      {
        avatar: "",
        backgroundImage: "",
        name: "",
        lastName: "",
        phone: "",
        email: "",
        id: null,
        username: "",
        password: "",
        typeUser: "",
        country: "",
        lastConnection: "",
        referalCode: "",
        referedPoints: 0,
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="h-screen w-full flex justify-center items-center">
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={(values) => {
            if (email) {
              SearchUser(email, setSearchedUser, setError);
            } else {
              setInvalid(true);
            }
          }}
        >
          <Form>
            <Card>
              <h3 className="p-4 font-semibold text-xl">Verificar correo</h3>
              <p className="p-4">
                Ingrese su correo para proceder a recuperar su contraseña
              </p>
              <CardContent>
                <Grid container>
                  <Grid item className="py-2" xs={12}>
                    <h5 className="text-left">Correo</h5>
                    <input
                      id="email"
                      name="email"
                      className="form-field"
                      type="email"
                      onClick={() => setInvalid(false)}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {invalid && (
                      <p className="text-danger text-left text-sm">Requerido</p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container>
                  <Grid item className="py-2" xs={12}>
                    <ButtonSecondary type="submit">Verificar</ButtonSecondary>
                  </Grid>
                  <Grid item className="py-2" xs={12}>
                    <Link to='/login'>Inicia sesion</Link>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Form>
        </Formik>
      </div>
    </>
  );
};
