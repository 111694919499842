import { useContext } from 'react';
import { Grid, Card, CardContent, CardActions } from '@mui/material';

import { UserContext } from '../../context/userContext';
import { ButtonSecondary } from '../../components/UI/Buttons';

import './MainOptions.css';

export const MainOptions = () => {
  const { user, bussinesUser, setScreen } = useContext(UserContext);
  return(
    <div className="h-screen w-full flex flex-col justify-center p-5">
      { (user && ((user.role === 'user' || user.role === 'admin'))) &&
      <>
        <Grid container justifyContent={'space-around'} alignItems={'center'} spacing={4} style={{marginBottom:'1%'}}>
          <Grid item xs={12} md={4} className='grid-item-responsive'>
            <Card className='grid-item-responsive' raised={true}>
              <CardContent>
                <h3 className="card-title">Prestamo Hipotecario</h3>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={12}>
                    <ButtonSecondary onClick={() => setScreen(1)}>Ir a simulador</ButtonSecondary>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className='grid-item-responsive'>
            <Card className='grid-item-responsive' raised={true}>
              <CardContent>
                <h3 className="card-title">Prestamo Personal</h3>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={12}>
                    <ButtonSecondary style={{width:'80%'}} onClick={() => setScreen(1)}>Ir a simulador</ButtonSecondary>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className='grid-item-responsive'>
            <Card className='grid-item-responsive' raised={true}>
              <CardContent>
                <h3 className="card-title">Prestamo Automotor</h3>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={12}>
                    <ButtonSecondary onClick={() => setScreen(1)}>Ir a simulador</ButtonSecondary>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className='grid-item-responsive'>
            <Card className='grid-item-responsive' raised={true}>
              <CardContent>
                <h3 className="card-title">Ofertas</h3>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={12}>
                    <ButtonSecondary onClick={() => setScreen(3)}>Ver ofertas</ButtonSecondary>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </>
      }
      { ((bussinesUser && bussinesUser.email) || (user && user.role === 'companyMember')) &&
      <>
        <Grid container justifyContent={'space-around'} alignItems={'center'} spacing={4} style={{marginBottom:'1%'}}>
          <Grid item xs={12} className='grid-item-responsive'>
            <Card className='card-responsive'>
              <CardContent>
                <h3 className="card-title">Cuenta</h3>
              </CardContent>
              <CardActions>
                <Grid container justifyContent='center'>
                  <Grid item xs={8}>
                    <ButtonSecondary onClick={() => setScreen(2)}>Ir al perfil</ButtonSecondary>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} className='grid-item-responsive'>
            <Card className='card-responsive'>
              <CardContent>
                <h3 className='card-title'>Configuracion</h3>
              </CardContent>
              <CardActions>
                <Grid container justifyContent='center'>
                  <Grid item xs={8}>
                    <ButtonSecondary onClick={() => setScreen(5)}>Ver Dashboard</ButtonSecondary>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </>
      }
    </div>
  );
}