import React from "react";
import styled from "@emotion/styled";
export const ButtonText = ({ children }) => {
  const Text = styled.p({
    margin: 0,
    fontFamily: "Bebas Neue, cursive",
    letterSpacing: 1.5,
    fontSize: 18,
  });
  return (
    <>
      <Text>{children}</Text>
    </>
  );
};

export const TittleText = ({ children, size, style }) => {
  const Text = styled.p({});
  return (
    <>
      <Text style={{ fontSize: size, color: '#00334E' }}>{children}</Text>
    </>
  );
};
