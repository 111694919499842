import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { UserContext } from "../../context/userContext";
import { CompanyContext } from "../../context/companyContext";

import Card from "../../components/Card";
import Warning from "../../components/Warning";

import { ButtonSecondary } from "../../components/UI/Buttons";

import "./Mortgage.css";

const Tittle = styled.p({
  fontFamily: "Bebas Neue, cursive",
  margin: 0,
  fontSize: 40,
  color: "rgb(140,140,160)",
  textAlign: "left",
});
const Mortgage = () => {
  const { bountyData, setScreen } = useContext(UserContext);
  const { mortgageOffersList, automotiveOffersList, getOffers } =
    useContext(CompanyContext);
  const [value, setValue] = useState("1");
  const handleEvent = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <br />
      <br />

      {!bountyData.id_user ? (
        <Grid
          container
          className="h-screen"
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid item xs={3}>
            <Warning setScreen={setScreen} />
          </Grid>
        </Grid>
      ) : (
        <>
          <TabContext value={value}>
            <Box>
              <TabList centered onChange={handleEvent} aria-label="credits">
                <Tab
                  label={
                    <span className="font-size-responsive">
                      Prestamos Hipotecarios
                    </span>
                  }
                  value="1"
                ></Tab>
                <Tab
                  label={
                    <span className="font-size-responsive">
                      Prestamos De Automoviles
                    </span>
                  }
                  value="2"
                ></Tab>
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={10}>
                    <Tittle>Todas las ofertas Hipotecarias</Tittle>
                  </Grid>
                  <Grid item md={11} lg={10} xs={12} sm={11}>
                    <Grid container justifyContent={"center"} spacing={2}>
                      {mortgageOffersList.map((i, index) => {
                        return (
                          <Grid item key={index} md={4} xs={8} sm={5} lg={3}>
                            <Card data={i} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="py-4 md:px-28">
                <h5 className="font-bold text-left">Nota:</h5>
                <p className="text-left">
                  Esta sección está destinada a empresas del sector financiero,
                  está pensada para poder ingresar información de interés para
                  los usuarios de crediap.com con información como futuros
                  productos y prestamos, mejora de las tasas y condiciones.
                  Contará con información importante de los créditos que iremos
                  actualizando ala brevedad.
                </p>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={10}>
                    <Tittle>Todas las ofertas de vehiculos</Tittle>
                  </Grid>
                  <Grid item md={11} lg={10} xs={12} sm={11}>
                    <Grid container justifyContent={"center"} spacing={2}>
                      {automotiveOffersList.map((i, index) => {
                        return (
                          <Grid item key={index} md={4} xs={8} sm={5} lg={3}>
                            <Card key={index} data={i} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="py-4 md:px-28">
                <h5 className="font-bold text-left">Nota:</h5>
                <p className="text-left">
                  Sección pensada para las instituciones financieras y empresas
                  dedicadas a la venta de vehículos en el mercado, enfocado en
                  la publicación de ofertas la publicación de nuevos productos
                  crediticios, sin que el cliente de Credicap.tenga que
                  compartir ningún dato con estas instituciones y empresas
                  teniendo el tiempo de analizar dichas ofertas desde la
                  comodidad de su hogar.
                </p>
              </Grid>
            </TabPanel>
          </TabContext>
          <Grid container justifyContent={"center"} sx={{ paddingY: "1rem" }}>
            <Grid item xs={4}>
              <ButtonSecondary onClick={() => setScreen(1)}>
                Simula tu prestamo
              </ButtonSecondary>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default Mortgage;
