import React from "react";
import { Grid, Box } from "@mui/material";

import uruguay from "../../../../assets/flags/Uruguay_flag.jpg";
import mexico from "../../../../assets/flags/Flag_of_Mexico.jpg";
import brazil from "../../../../assets/flags/Brazil-flag.jpg";
import peru from "../../../../assets/flags/Peru-flag.jpg";
import colombia from "../../../../assets/flags/Colombia-flag.jpg";
import chile from "../../../../assets/flags/Chile-flag.jpg";

const Flag = (image) => {
  return (
    <>
      <div className="flex justify-center">
        <Box component="img" alt="flag" src={image.image} className="rounded-md drop-shadow-xl" />
      </div>
    </>
  );
};

export const Available = () => {
  return (
    <section id="available" className="my-1">
      <Grid
        container
        justifyContent={"space-around"}
        alignContent={"center"}
        spacing={1}
      >
        <Grid item xs={12} md={4}>
          <div className="flex flex-col space-y-1">
            <h2 className="text-white">Disponible en:</h2>
            <Flag image={uruguay} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="flex flex-col space-y-1">
            <h2 className="text-white">Proximamente en:</h2>
            <div className="flex justify-center space-x-4">
              <Flag image={mexico} />
              <Flag image={brazil} />
              <Flag image={peru} />
              <Flag image={colombia} />
              <Flag image={chile} />
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};
