import React from "react";
import styled from "@emotion/styled";
import ItemMenu from "./ItemMenu";
import { Link } from "react-router-dom";
import { Grid, Menu, MenuItem } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../../assets/logo.png";
import "./TopMenu.css";

const items = [
  {
    name: "Nosotros",
    to: "/#about",
  },
  {
    name: "Contactanos",
    to: "/#contactUs",
  },
];
const Container = styled.div({
  position: "relative",
  zIndex: 99,
  width: "100%",
  top: 0,
  boxShadow: "0 0 5vw 0 rgba(20,20,20,.2)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
const ContainerItems = styled.div({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});
const ContainerRight = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});
const TopMenu = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  //Open anchor menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const list = (anchor) => (
    <Box
      style={{
        height: "100%",
      }}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      className="bg-primary"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {items.map((item, index) => (
          <div key={index}>
            <ListItem disablePadding>
              <Link to={item.to}>
                <ListItemButton>
                  <ListItemText
                    style={{ color: "white" }}
                    primary={item.name}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider />
          </div>
        ))}
        <div>
          <ListItem disablePadding>
            <Link to={"/login"}>
              <ListItemButton>
                <ListItemText style={{ color: "white" }} primary={"Personas"} />
              </ListItemButton>
            </Link>
          </ListItem>
          <Divider />
        </div>
        <div>
          <ListItem disablePadding>
            <Link to={"/business-login"}>
              <ListItemButton>
                <ListItemText style={{ color: "white" }} primary={"Empresas"} />
              </ListItemButton>
            </Link>
          </ListItem>
          <Divider />
        </div>
      </List>
    </Box>
  );
  return (
    <Grid container className={`fixed z-10 md:px-10 bg-primary`}>
      <Grid item md={12} sx={{ display: { md: "block", xs: "none" } }}>
        <Container>
          <Link to="/">
            <img className="header-logo cursor-pointer" src={logo} alt="logo" />
          </Link>
          <ContainerRight>
            <ContainerItems>
              {items.map((item, index) => (
                <div key={index}>
                  <Link to={item.to}>
                    <ItemMenu>{item.name}</ItemMenu>
                  </Link>
                </div>
              ))}
              <div>
                <button
                  onClick={handleOpen}
                  aria-controls={openMenu ? 'access-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                >
                  <ItemMenu>Acceder</ItemMenu>
                </button>
              </div>
            </ContainerItems>
            <Menu
              anchorEl={anchorEl}
              id="access-menu"
              open={openMenu}
              onClick={handleClose}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <Link to="/login">
                  Personas
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/business-login">
                  Empresas
                </Link>
              </MenuItem>
            </Menu>
          </ContainerRight>
        </Container>
      </Grid>
      <Grid item xs={12} sx={{ display: { md: "none", xs: "block" } }}>
        <Container>
          <Link to="/">
            <img className="header-logo" src={logo} alt="logo" />
          </Link>
          <ContainerRight className="pr-4">
            <IconButton
              onClick={toggleDrawer("left", true)}
              aria-label="delete"
              style={{ color: "white" }}
            >
              <MenuOutlinedIcon style={{ color: "white" }} />
            </IconButton>
            {["left"].map((anchor, index) => (
              <React.Fragment key={index}>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </ContainerRight>
        </Container>
      </Grid>
    </Grid>
  );
};
export default TopMenu;
