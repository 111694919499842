import React from "react";
import { Grid, Card, CardActions, CardContent } from "@mui/material";
import { Settings, Info } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/userContext";
import "./User.css";

export const UserCard = ({
  name,
  lastName,
  email,
  phone,
  title,
  info,
  lastConnect = null,
  usersAssociated = null,
}) => {
  const { setScreen } = React.useContext(UserContext);
  let lastDate = new Date(lastConnect);
  return (
    <>
      <Card>
        <CardContent>
          {info && (
            <p className="text-end" title={info}>
              <Info />
            </p>
          )}
          {title && (
            <p className="font-bold text-left">{`${title}${
              usersAssociated ? ": " + usersAssociated : ": " + 0
            }`}</p>
          )}
          {name && lastName && (
            <p className="font-semibold text-left">
              Nombre: {`${name} ${lastName}`}
            </p>
          )}

          {email && <p className="text-left">Correo: {email}</p>}
          {phone && <p className="text-left">Telefono: {phone}</p>}
        </CardContent>
        <CardActions>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <div className="align-right" style={{ bottom: 0 }}>
                <Link
                  to="#"
                  className="flex items-center space-x-1 text-white text-sm font-semibold bg-blue-700 px-2 py-1 rounded-xl"
                  onClick={() => setScreen(2)}
                >
                  <Settings className="text-white"></Settings>
                  Configuración
                </Link>
              </div>
            </Grid>
            {lastConnect && (
              <Grid item xs={12}>
                <div>
                  <p className="text-footer-in text-right">
                    Ultima conexion:{" "}
                    {`${lastDate.getDate()}/${
                      lastDate.getMonth() + 1
                    }/${lastDate.getFullYear()}`}
                  </p>
                </div>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </>
  );
};
