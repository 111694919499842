import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { Info } from '@mui/icons-material';

// - import { UserContext } from "../../../../context/userContext";
//import "./User.css";
import './Info.css';

export const InfoCard = ({children, title, items = [], link=false, info=null}) => {
  return (
    <>
      <Card style={{height:'100%'}}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {info && <p className="text-end" title={info}><Info/></p>}
              <p className="text-left font-semibold text-medium">{title}</p>
              {items.length > 0 && items.map((item, index) => {
                return(
                  <p key={index} className="text-title text-left text-nowrap">{item}</p>
                )
              })}
            </Grid>
            <Grid item xs={12}>
              {link && children}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};