import React from "react";
import { Box } from '@mui/material';
import Chart from 'react-apexcharts';

export const LineChart = ({labels, series, seriesTitle='series'}) => {
  const chartData = {
    series:[{
      name: seriesTitle,
      data: series
    }],
    options:{
      chart:{
        toolbar: false
      },
      xaxis:{
        categories: labels,
      },
      title: {
        name: 'Dias'
      }
    }
  }
  return(<>
    <Box className="bg-white">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
      />
    </Box>
  </>);
}