import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import { MortageSimulator } from "./Mortgage/index";
import { PersonalSimulator } from "./Personal/index";
import { AutomotiveSimulator } from "./Automotive/index";
import { Results } from "./results/results";
import { Details } from "./Details/index";
import { LoanDetail } from "./LoanDetails/index";

import { ButtonSecondary } from '../../components/UI/Buttons';
import { UserContext } from '../../context/userContext';

export const Simulator = () => {
  const { setScreen } = useContext(UserContext);
  const [result, setResult] = useState((localStorage.getItem('result') === 'true'));
  const [details, setDetails] = useState((localStorage.getItem('details') === 'true'));
  const [loanDetails, setLoanDetails] = useState((localStorage.getItem('loanDetails') === 'true'));
  const [bankDetail, setBankDetail] = useState(JSON.parse(localStorage.getItem('bankDetail')) || {});
  const [resultValues, setResultValues] = useState(JSON.parse(localStorage.getItem('results')) || []);

  React.useEffect(() => {
    localStorage.setItem('details', details.toString());
    localStorage.setItem('result', result.toString());
    localStorage.setItem('loanDetails', loanDetails.toString());
    localStorage.setItem('results', JSON.stringify(resultValues));
    localStorage.setItem('bankDetail', JSON.stringify(bankDetail));
  }, [result, details, loanDetails, resultValues, bankDetail]);

  return (
    <>
      <br />
      <br />
      <div style={{ paddingLeft: '2%', paddingRight: '2%', paddingTop: '2%', paddingBottom: '2%' }}>
        {(!result && !details && !loanDetails) && (
          <Grid container spacing={10} justifyContent={"space-between"}>
            <Grid item xs={12} sm={4}>
              <MortageSimulator
                resultScreen={setResult}
                setResultValues={setResultValues}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PersonalSimulator
                resultScreen={setResult}
                setResultValues={setResultValues}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AutomotiveSimulator
                resultScreen={setResult}
                setResultValues={setResultValues}
              />
            </Grid>
          </Grid>
        )}
        {(result && !details && !loanDetails) && (
          <Grid container justifyContent={"center"} alignContent={"center"} style={{height: "100%"}}>
            <Grid item xs={12}>
              <Results
                resultScreen={setResult}
                setDetails={setDetails}
                setBankDetail={setBankDetail}
                resultValues = {resultValues}
              />
            </Grid>
          </Grid>
        )}
        {(result && details && !loanDetails) && (
          <Grid container justifyContent={"center"} alignContent={"center"}>
            <Details
              bankDetail={bankDetail}
              setDetails={setDetails}
              setLoanDetails={setLoanDetails}
            />
          </Grid>
        )}
        {(result && !details && loanDetails) && (
          <Grid container justifyContent={"center"}>
            <LoanDetail
              bankDetail={bankDetail}
              setDetails={setDetails}
              setLoanDetails={setLoanDetails}
            />
          </Grid>
        )}
        <Grid container justifyContent={"center"} style={{marginTop: '3%'}}>
          <Grid item xs={6} sm={4} md={4}>
            <ButtonSecondary onClick={() => setScreen(3)}>Ver publicaciones</ButtonSecondary>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
