export function setAmortization (object) {
  const getFees = object.fees;
  const getCompensatoryInterest = object.compensatoryInterest;
  const result = getFees - getCompensatoryInterest;
  return result;
}
export function setAmountToPayMonthly (object) {
  const getFees = object.fees;
  const result = getFees + (4.75 / 100) * getFees;
  return result;
}
export function setCapitalRecoveryFactor (object) {
  const getTem = object.monthlyEffectiveRate / 100;
  const feeInMonths = object.years * 12;
  const result =
    (getTem * Math.pow(1 + getTem, feeInMonths)) /
    (Math.pow(1 + getTem, feeInMonths) - 1);
  return result.toFixed(4);
}
export function setCompensatoryInterest(object) {
  const { amountRequired } = object;
  const getTea = object.annualTax / 100;
  const result = amountRequired * (Math.pow(1 + getTea, 30 / 360) - 1);
  return result;
}
export function setMonthlyFees (object) {
  const getFrc = parseFloat(object.capitalRecoveryFactor);
  const result = object.amountRequired * getFrc;
  return parseInt(result);
}
export function setMonthlyEffectiveRate( annualEffectiverate ){
  const monthlyEffectiveRate = ((Math.pow(1 + annualEffectiverate / 100, 30 / 360) - 1) * 100);
  return monthlyEffectiveRate;
}
export function setTotalToPay(object) {
  const getFeesMin = object.fees;
  const getFeesMax = object.amountToPay;
  const feeInMonths = object.years * 12;
  const resultMin = (getFeesMin * feeInMonths).toFixed(0);
  const resultMax = (getFeesMax * feeInMonths).toFixed(0);
  return { min: resultMin, max: resultMax };
}
export function setMinPercentValidFromBalance(fee, percent){
  let minValidBalance = (fee*100) / (percent);
  return minValidBalance;
}
export function setValidBalance(object) {
  const pay = object.amountToPay;
  const percentBalance = object.percent * object.income;
  const isValid = percentBalance > pay;
  return isValid;
}