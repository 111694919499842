import { Grid, Typography } from '@mui/material';
import { PrivacyTerms } from '../../constants/data';

export const Privacy = () => {
  const styleContainer = {
    paddingX: '7%',
    paddingY: '1%',
    display: 'flex',
    flexDirection: 'column',
  };
  const aspectText = {
    color: "#145374",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "40px",
    paddingY: 5
  };
  const subtitle = {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16.18px',
    textAlign: 'left'
  }
  const paragraph = {
    fontFamily: 'Inter',
    fontSize: '16.18px',
    textAlign: 'left',
    paddingY: '0.5%',
    lineHeight: '160%',
    letterSpacing: '0.02em'
  }
  return(
    <>
      <styleContainer>
        <Grid container className='py-10'>
          <Grid item xs={12}>
            <Typography sx={aspectText}>Politica de privacidad</Typography>
          </Grid>
        </Grid>
        {PrivacyTerms.map(terms => {
          return(
            <Grid container sx={styleContainer}>
              <Grid item>
                <Typography sx={subtitle}>{terms.title}</Typography>
              </Grid>
              {terms.description.map(description => {
                return(
                  <Grid item>
                    <Typography sx={paragraph}>{description}</Typography>
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </styleContainer>
    </>
  )
}