import { Grid } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ButtonSecondary } from "../../../UI/Buttons";
import createAccount from "../../../../assets/joinus.jpg";

export const RegisterSection = ({ image }) => {
  return (
    <section id="register" className="py-24 px-4 bg-white">
      <Grid
        container
        justifyContent={"space-around"}
        alignContent={"center"}
        spacing={1}
      >
        <Grid item xs={12} md={8}>
          <h3 className="subtitle-1">Cree su cuenta gratis</h3>
          <p className="py-4 text-left">
            Registrate ahora en nuestro sistema es rápido y ya podrás hacer
            consultas ilimitadas de los diferentes créditos que el mercado
            ofrece, consultas hipotecarias en todos los bancos, consultas por
            autos en todos los bancos y consulta por créditos personales en las
            principales instituciones financieras.
          </p>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Link to={"/register"}>
                <ButtonSecondary>
                  Registrese ahora <ArrowForwardIos sx={{ fontSize: "1rem" }} />
                </ButtonSecondary>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <div className="flex justify-center">
            <img
              src={createAccount}
              alt="about"
              style={{ objectFit: "fill" }}
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};
