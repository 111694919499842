import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import { Link, Redirect, useParams } from "react-router-dom";
import moment from 'moment';

import TextField from "../../components/UI/TextField";
import { ButtonSecondary } from "../../components/UI/Buttons";
import { TittleText } from "../../components/UI/Texts";
import CustomCheckbox from "../../components/UI/CheckBox";
/*import SignInGoogle from "../../components/google/Signin";
import SignInFacebook from "../../components/facebook/Signin";*/
import { SignUserController } from "../../Api/Controller";
import Country from "../../components/country/Country";
import { UserContext } from "../../context/userContext";
import { useContext, useState } from "react";
const Ray = styled.div({
  width: "100%",
  height: 1,
  marginBottom: "2vw",
  backgroundColor: "rgb(220,220,220)",
});
const Wrapper = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 99,
});
const Tittle = styled(TittleText)({
  color: "red",
});
const Text = styled.p({
  fontFamily: "Roboto Condensed, sans-serif",
  color: " rgb(60,60,100)",
});
const Url = styled(Link)({
  textDecoration: "none",
  color: " rgb(60,60,100)",
  letterSpacing: 1,
  fontFamily: "Bebas Neue, cursive",
});
const Card = styled(Form)({
  marginTop: "6vw",
  backgroundColor: "rgb(252,252,252)",
  padding: "2vw 1vw 0 2vw",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 10,
  boxShadow: "0 1vw 4vw 0vw rgba(50,50,50,0.5)",
  marginBottom: "6vw",
});

const SignupSchema = Yup.object().shape({
  email: Yup.string("debe haber texto")
    .min(4, "El correo es muy corto")
    .max(50, "El correo es muy largo")
    .required("Este campo es requerido"),
  name: Yup.string("debe haber texto")
    .min(3, "El nombre  es muy corto")
    .max(50, "El nombre es largo")
    .required("Este campo es requerido"),
  birth: Yup.string().required("Ingrese fecha de nacimiento"),
  lastName: Yup.string("debe haber texto")
    .min(4, "El apellido es muy corto")
    .max(50, "El apellido es muy largo")
    .required("Este campo es requerido"),
  phone: Yup.string("debe haber texto")
    .min(4, "debes introducir un numero de telefono valido")
    .max(50, "debes introducir un numero de telefono valido")
    .required("Este campo es requerido"),
  password: Yup.string("debe haber texto")
    .min(5, "La contraseña es muy corta")
    .max(50, "La contraseña es muy larga")
    .required("Este campo es requerido"),
});
const Register = ({ setCreatedUser }) => {
  const { user, setUser, setLoadding, bussinesUser, setToken } =
    useContext(UserContext);
  const [country, setCountry] = useState("");
  const { referalId } = useParams();
  /*const responseFacebook = (res) => {
    console.log(res);
    SignUpUserFacebookController(res, setUser);
  };
  const responseGoogle = (res) => {
    console.log(res);
    SignUpUserGoogleController(res, setUser);
  };*/
  return (
    <>
      {(bussinesUser.id && <Redirect to="/home" />) ||
        (user.id && <Redirect to="/home" />)}
      {/*<div style={{ marginTop: "-10%", marginBottom: "-50%" }}>
        <img style={{ zIndex: 1, width: "100%" }} alt="" src={Background} />
      </div>*/}
      <Wrapper className="pt-20">
        <Grid container justifyContent="center">
          {/*<Grid item xs={12} style={{ display: 'flex', justifyContent: 'end'}}>{children}</Grid>*/}
          <Grid item xs={10} md={6}>
            <Formik
              initialValues={{
                email: "",
                name: "",
                birth: moment().format('L'),
                password: "",
                lastName: "",
                phone: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                // same shape as initial values
                setLoadding(true);
                SignUserController(
                  Object.assign(values, {
                    country: country,
                    referalId: referalId || null,
                  }),
                  setUser,
                  setLoadding,
                  setToken
                );
              }}
            >
              {({ errors, touched }) => (
                <Card>
                  <Tittle size={25}>Registrarme</Tittle>
                  <Ray></Ray>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={1} justifyContent={"center"}>
                      <Grid item xs={11} md={5}>
                        <TextField
                          name="name"
                          error={errors.name}
                          touched={touched.name}
                          placeholder="Nombre"
                        />
                      </Grid>
                      <Grid item xs={11} md={5}>
                        <TextField
                          name="lastName"
                          error={errors.lastName}
                          touched={touched.lastName}
                          placeholder="Apellido"
                        />
                      </Grid>
                      <Grid item xs={11} md={5}>
                        <TextField
                          name="email"
                          error={errors.email}
                          touched={touched.email}
                          placeholder="Correo"
                        />
                      </Grid>
                      <Grid item xs={11} md={5}>
                        <input
                          type="date"
                          name="birth"
                          id="birth"
                          className="border-2 border-field w-full h-full p-4 rounded-lg"
                        />
                        <p>{errors.birth}</p>
                      </Grid>
                      <Grid item xs={11} md={5}>
                        <TextField
                          name="phone"
                          error={errors.phone}
                          touched={touched.phone}
                          placeholder="Telefono"
                        />
                      </Grid>
                      <Grid item xs={11} md={5}>
                        <TextField
                          name="password"
                          error={errors.password}
                          touched={touched.password}
                          placeholder="Contraseña"
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={10} md={10}>
                        <Country
                          value={country}
                          setValue={setCountry}
                          register={true}
                          showOptionLabel={true}
                          showSelectedLabel={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={1}>
                        <CustomCheckbox />
                      </Grid>
                      <Grid item xs={9} sm={5}>
                        <Text>
                          Acepto los
                          <Url to="/terms"> terminos y condiciones</Url>
                        </Text>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={9}>
                        <Text>
                          Ver <Url to="/privacy">politica de privacidad</Url>
                        </Text>
                      </Grid>
                    </Grid>
                    <Grid container className="py-2" justifyContent={"center"}>
                      <Grid item xs={12} md={6}>
                        <ButtonSecondary type="submit">
                          Registrarme
                        </ButtonSecondary>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} md={6}>
                        <Text>
                          Ya tienes una cuenta?{" "}
                          <Url to="/login">Iniciar sesión</Url>
                        </Text>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};
export default Register;
