import { Grid } from "@mui/material";

export const SectionPage = ({
  id,
  display,
  title,
  content,
  image = "https://via.placeholder.com/300",
}) => {
  return (
    <section
      id={id}
      className={`py-24 px-4 ${display === "reverse" ? "bg-none" : "bg-white"}`}
    >
      <Grid
        container
        flexDirection={display === "reverse" ? "row-reverse" : "row"}
        justifyContent={"space-around"}
        alignContent={"center"}
        spacing={1}
      >
        <Grid item xs={12} md={8}>
          <h3 className="subtitle-1">{title}</h3>
          <p className="py-4 text-left">{content}</p>
        </Grid>
        <Grid item xs={12} md={2}>
          <div className="flex justify-center">
            <img src={image} alt="about" style={{ objectFit: "fill" }} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};
