import React from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

export const BasicTable = ({ children, headers }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="top five offers">
          <TableHead>
            <TableRow>
              {headers &&
                headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
