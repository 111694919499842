import React from "react";
import {
  Container,
  Grid,
  Modal,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Close,
  Group,
  DirectionsCar,
  House,
  AccessibilityNew,
  Sell,
  Groups,
  CalendarMonth,
  CalendarViewMonth,
  DateRange,
  Visibility,
  AttachMoney,
} from "@mui/icons-material";
import Swal from "sweetalert2";

import { UserContext } from "../../../context/userContext";
import { CompanyContext } from "../../../context/companyContext";
import { UserCard } from "../../../components/UI/cards/user/index";
import { ButtonPrimary } from "../../../components/UI/Buttons";
import { AddUserFromCompany } from "../../../components/forms/users/addFromCompany";
import { DataTable } from "./DataTable";
import { FormOffers } from "../../../components/forms/offers/index";
import { BarChart } from "../../../components/charts/bar";
import { PieChart } from "../../../components/charts/pie";
import { LineChart } from "../../../components/charts/line";
import { BasicTable } from "../../../components/tables/basic";
import { GetOfferQuotes } from "../../../components/forms/offers/buyQuotes";
import { NoData } from "../../../components/UI/cards/no-data/index";
import "./Companies.css";

export const DashboardCompanies = () => {
  const userColumns = [
    { field: "name", headerName: "Nombre", width: 200 },
    { field: "lastName", headerName: "Apellido", width: 200 },
    { field: "email", headerName: "Correo", width: 200 },
    { field: "phone", headerName: "Telefono", width: 200 },
    { field: "role", headerName: "Permisos", width: 200 },
  ];

  const offerColumns = [
    { field: "name", headerName: "Nombre", width: 200 },
    { field: "typeOffer", headerName: "Tipo de oferta", width: 200 },
    { field: "summary", headerName: "Descripcion", width: 200 },
    { field: "description", headerName: "Detalles", width: 200 },
  ];
  const {
    user,
    bussinesUser,
    addCompanyUser,
    buyOffersQuotes,
    updateCompanyUser,
    removeCompanyUser,
  } = React.useContext(UserContext);
  const [userSelected, setUserSelected] = React.useState(null);
  const [userList, setUserList] = React.useState(false);
  const [offerSelected, setOfferSelected] = React.useState(null);
  const [offerList, setOfferList] = React.useState(false);
  const [buyOfferQuota, setBuyOfferQuota] = React.useState(false);
  const {
    allOffers,
    offersSorted,
    mortgageOffers,
    automotiveOffers,
    removeOffer,
    getOffersByCompany,
    setProgress,
  } = React.useContext(CompanyContext);
  const [openModal, setOpenModal] = React.useState(false);
  const [formUser, setFormUser] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  React.useEffect(() => {
    if (bussinesUser.id) {
      getOffersByCompany(bussinesUser.id);
    } else if (user.id) {
      getOffersByCompany(user.company[0]._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bussinesUser.id, user.id]);
  return (
    <>
      <br />
      <br />
      <br />
      <Container>
        <Grid container justifyContent={"space-around"} spacing={2}>
          <Grid item xs={12}>
            <Box className="border-1 border-gray-300 bg-white px-4 py-2 drop-shadow-md">
              {(bussinesUser.name || user.id) && (
                <p className="text-left subtitle">
                  {bussinesUser.name || user.company[0].nameEnterprise} /
                  dashboard
                </p>
              )}
              {bussinesUser && bussinesUser.availableQuotes && (
                <section id="available-quotes">
                  <p className="text-left subtitle">Puede agregar:</p>
                  <ul className="text-left subtitle">
                    <li>
                      Ofertas que duran siete dias:{" "}
                      {bussinesUser.availableQuotes.sevenDays.quantity}
                    </li>
                    <li>
                      Ofertas que duran quince dias:{" "}
                      {bussinesUser.availableQuotes.fifteenDays.quantity}
                    </li>
                    <li>
                      Ofertas que duran treinta dias:{" "}
                      {bussinesUser.availableQuotes.thirteenDays.quantity}
                    </li>
                  </ul>
                </section>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="py-4">
            {(bussinesUser || user.id) && (
              <UserCard
                name={bussinesUser.nameEnterprise || user.name}
                lastName={bussinesUser.typeEnterprise || user.lastName}
                email={bussinesUser.email || user.email}
                phone={bussinesUser.phone || user.phone}
                lastConnect={bussinesUser.lastConnection || user.lastConnection}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Group className="text-primary" />
                    <Typography className="subtitle-4">
                      Usuarios Asociados
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className="font-inter text-2xl text-center py-1 md:text-5xl">
                    {bussinesUser.users.length}
                  </p>
                </Grid>
              </CardContent>
              <CardActions sx={{ paddingY: "1.1rem" }}>
                <div className="align-right">
                  <button
                    className="bg-blue-700 px-2 py-1 rounded-xl"
                    onClick={() => {
                      setUserList(!userList);
                    }}
                  >
                    <p className="text-white text-sm font-semibold">
                      Ver detalles
                    </p>
                  </button>
                </div>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 0,
                }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Sell className="text-primary" />
                    <Typography className="subtitle-4">
                      Ofertas publicadas
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <p className="text-left flex items-center space-x-1 pb-1">
                    <AccessibilityNew />
                    <span>Total: {allOffers.length}</span>
                  </p>
                  <p className="text-left flex items-center space-x-1 pb-1">
                    <House />
                    <span>Hipotecarias: {mortgageOffers}</span>
                  </p>
                  <p className="text-left flex items-center space-x-1 pb-1">
                    <DirectionsCar />
                    <span>Automotor: {automotiveOffers}</span>
                  </p>
                </Grid>
              </CardContent>
              <CardActions sx={{ paddingY: "0.75rem" }}>
                <div className="flex space-x-2 align-right">
                  <button
                    className="bg-blue-700 px-2 py-1 rounded-xl"
                    onClick={() => {
                      setOpenModal(!openModal);
                    }}
                  >
                    <p className="text-white text-sm font-semibold">
                      Agregar ofertas
                    </p>
                  </button>
                  <button
                    className="bg-blue-700 px-2 py-1 rounded-xl"
                    onClick={() => {
                      setOfferList(!offerList);
                    }}
                  >
                    <p className="text-white text-sm font-semibold">
                      Ver detalles
                    </p>
                  </button>
                  <button
                    className="bg-blue-700 px-2 py-1 rounded-xl"
                    onClick={() => {
                      setBuyOfferQuota(!buyOfferQuota);
                    }}
                  >
                    <p className="text-white text-sm font-semibold">
                      Comprar ofertas
                    </p>
                  </button>
                </div>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid container className="py-5" justifyContent={"center"} spacing={4}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <House className="text-primary" />
                    <Typography className="subtitle-4">
                      Ofertas hipotecarias mas consultadas
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} className="h-full">
                  {offersSorted.mortgage &&
                    offersSorted.mortgage.length > 0 && (
                      <BarChart
                        labels={offersSorted.mortgage
                          .slice(0, 5)
                          .map((item) => item._doc.name)}
                        series={offersSorted.mortgage
                          .slice(0, 5)
                          .map((item) => item.totalViews)}
                        seriesLabel="Vistas"
                      />
                    )}
                  {offersSorted.mortgage &&
                    offersSorted.mortgage.length < 1 && <NoData></NoData>}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <DirectionsCar className="text-primary" />
                    <Typography className="subtitle-4">
                      Ofertas automotoras mas consultadas
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {offersSorted.automotive &&
                    offersSorted.automotive.length > 0 && (
                      <BarChart
                        labels={offersSorted.automotive
                          .slice(0, 5)
                          .map((item) => item._doc.name)}
                        series={offersSorted.automotive
                          .slice(0, 5)
                          .map((item) => item.totalViews)}
                        seriesLabel="Vistas"
                      />
                    )}
                  {offersSorted.automotive &&
                    offersSorted.automotive.length < 1 && <NoData />}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Groups className="text-primary" />
                    <Typography className="subtitle-4">
                      Vistas segun edades
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {offersSorted.views &&
                    offersSorted.views.rate &&
                    offersSorted.views.rate.length > 0 && (
                      <div className="py-2">
                        <PieChart
                          labels={offersSorted.views.rate.map(
                            (item) => item._id.ageRank
                          )}
                          series={offersSorted.views.rate.map(
                            (item) => item.percent
                          )}
                        />
                      </div>
                    )}
                  {offersSorted.views &&
                    offersSorted.views.rate &&
                    offersSorted.views.rate.length < 1 && <NoData />}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <CalendarMonth className="text-primary" />
                    <Typography className="subtitle-4">
                      Vistas en el ultimo mes
                    </Typography>
                  </div>
                </Grid>
                {offersSorted.views &&
                  offersSorted.views.lastMonth.length > 0 && (
                    <Grid item xs={12}>
                      <BarChart
                        labels={offersSorted.views.lastMonth.map(
                          (item) => item.name
                        )}
                        series={offersSorted.views.lastMonth.map(
                          (item) => item.views
                        )}
                      />
                    </Grid>
                  )}
                {offersSorted.views &&
                  offersSorted.views.lastMonth.length < 1 && <NoData />}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <CalendarViewMonth className="text-primary" />
                    <Typography className="subtitle-4">
                      Vistas durante los ultimos cinco meses
                    </Typography>
                  </div>
                </Grid>
                {offersSorted.views &&
                  offersSorted.views.lastFiveMonths.length > 0 && (
                    <Grid item xs={12}>
                      <LineChart
                        labels={offersSorted.views.lastFiveMonths.map(
                          (item) => item.month
                        )}
                        series={offersSorted.views.lastFiveMonths.map(
                          (item) => item.views
                        )}
                        seriesTitle="Visitas"
                      />
                    </Grid>
                  )}
                {offersSorted.views &&
                  offersSorted.views.lastFiveMonths.length < 1 && <NoData />}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <DateRange className="text-primary" />
                    <Typography className="subtitle-4">
                      Vistas durante el año
                    </Typography>
                  </div>
                </Grid>
                {offersSorted.views &&
                  offersSorted.views.perMonths.length > 0 && (
                    <Grid item xs={12}>
                      <LineChart
                        labels={offersSorted.views.perMonths.map(
                          (item) => item.month
                        )}
                        series={offersSorted.views.perMonths.map(
                          (item) => item.views
                        )}
                        seriesTitle="Visitas"
                      />
                    </Grid>
                  )}
                {offersSorted.views &&
                  offersSorted.views.perMonths.length < 1 && <NoData />}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <Visibility className="text-primary" />
                    <Typography className="subtitle-4">
                      Ofertas mas consultadas
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {offersSorted.typeOffer &&
                    offersSorted.typeOffer.views &&
                    offersSorted.typeOffer.views.length > 0 && (
                      <BasicTable headers={["Tipo", "Consultas"]}>
                        {offersSorted.typeOffer.views.map((offer, index) => (
                          <TableRow key={index}>
                            <TableCell>{offer.name}</TableCell>
                            <TableCell>{offer.percent} %</TableCell>
                          </TableRow>
                        ))}
                      </BasicTable>
                    )}
                  {offersSorted.typeOffer &&
                    offersSorted.typeOffer.views &&
                    offersSorted.typeOffer.views.length < 1 && <NoData />}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent
                className="space-y-4"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <div className="flex justify-center space-x-2">
                    <AttachMoney className="text-primary" />
                    <Typography className="subtitle-4">
                      Ofertas con mayor precio
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {offersSorted.typeOffer &&
                    offersSorted.typeOffer.price &&
                    offersSorted.typeOffer.price.length > 0 && (
                      <BasicTable headers={["Tipo de oferta", "Precio"]}>
                        {offersSorted.typeOffer.price.map((offer, index) => (
                          <TableRow key={index}>
                            <TableCell>{offer.name}</TableCell>
                            <TableCell>{offer.total.$numberDecimal}</TableCell>
                          </TableRow>
                        ))}
                      </BasicTable>
                    )}
                  {offersSorted.typeOffer &&
                    offersSorted.typeOffer.price &&
                    offersSorted.typeOffer.price.length < 1 && <NoData />}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Modal className="modal" open={userList}>
          <Box className="modal-box">
            <Grid container className="py-4 justify-center items-center">
              <Grid item xs={11}>
                <h2 className="font-semibold text-xl">Usuarios asociados</h2>
              </Grid>
              <Grid item xs={1}>
                <Close
                  className="cursor-pointer text-danger"
                  onClick={() => setUserList(!userList)}
                ></Close>
              </Grid>
            </Grid>
            <Grid
              container
              className="margin-y"
              justifyContent={"space-around"}
            >
              <Grid item xs={12}>
                <DataTable
                  items={[bussinesUser, ...bussinesUser.users]}
                  columns={userColumns}
                  onUpdate={(userId) => {
                    const user = bussinesUser.users.find(
                      (user) => user._id === userId
                    );
                    if (user) {
                      setUserSelected(user);
                      setEdit(true);
                      setFormUser(!formUser);
                    } else {
                      console.log("No se encontro al usuario en la empresa");
                    }
                  }}
                  onDelete={(userId) => {
                    Swal.fire({
                      title: "Estas seguro?",
                      text: "No podras revertir esto!",
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Si, Eliminar",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        removeCompanyUser(userId);
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <ButtonPrimary onClick={() => setFormUser(!formUser)}>
                  Crear Usuario
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal className="modal" open={offerList} sx={{ zIndex: "1000" }}>
          <Box className="modal-box">
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              className="py-4"
            >
              <Grid item xs={11}>
                <h2 className="font-semibold text-xl">Ofertas publicadas</h2>
              </Grid>
              <Grid item xs={1}>
                <Close
                  className="cursor-pointer text-danger"
                  onClick={() => setOfferList(!offerList)}
                ></Close>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <DataTable
                  items={allOffers}
                  columns={offerColumns}
                  companyId={bussinesUser.id}
                  onUpdate={(offerId) => {
                    const offer = allOffers.find(
                      (offer) => offer._id === offerId
                    );
                    if (offer) {
                      setOfferSelected(offer);
                      setEdit(true);
                      setOpenModal(!openModal);
                    }
                  }}
                  onDelete={(offerId) => {
                    Swal.fire({
                      title: "Eliminar oferta",
                      text: "No podra revertir esta accion",
                      icon: "warning",
                      showConfirmButton: true,
                      showCancelButton: true,
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Si, Eliminar",
                    })
                      .then((res) => {
                        if (res.isConfirmed) {
                          removeOffer(offerId);
                        }
                      })
                      .catch((error) => console.error(error));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <ButtonPrimary
                  onClick={() => setOpenModal(!openModal)}
                  isDisabled={bussinesUser.availableQuotes < 1}
                >
                  {bussinesUser.availableQuotes > 1
                    ? `Agregar Ofertas [${bussinesUser.availableQuotes}]`
                    : "No puede agregar ofertas"}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal className="modal" open={buyOfferQuota}>
          <Box className="modal-box">
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              className="py-4"
            >
              <Grid item xs={11}>
                <h2 className="font-semibold text-base md:text-xl">
                  Comprar Ofertas
                </h2>
              </Grid>
              <Grid item xs={1}>
                <Close
                  className="cursor-pointer text-danger"
                  onClick={() => setBuyOfferQuota(!buyOfferQuota)}
                ></Close>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <GetOfferQuotes
                company={bussinesUser}
                onSubmit={(values) => {
                  setProgress(true);
                  buyOffersQuotes(
                    { id: bussinesUser.id, productData: values },
                    setBuyOfferQuota,
                    setProgress
                  );
                }}
              />
            </Grid>
          </Box>
        </Modal>
      </Container>
      <Modal className="modal" open={openModal}>
        <Box className="modal-box">
          <Grid container justifyContent={"center"} spacing={1}>
            <Grid item xs={12}>
              <h3 className="inter text-center">
                {edit ? "Editar oferta" : "Nueva oferta"}
              </h3>
            </Grid>
            <Grid item className="input" xs={12}>
              <FormOffers
                openModal={openModal}
                edit={edit}
                setOpenModal={setOpenModal}
                setEdit={setEdit}
                offerData={offerSelected}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal className="modal" open={formUser}>
        <Box className="modal-box">
          <Grid container className="py-4" justifyContent={"center"}>
            <Grid item xs={11}>
              <h4 className="font-semibold text-xl text-center">
                {edit ? "Editar Usuario" : "Agregar Usuario"}
              </h4>
            </Grid>
            <Grid item xs={1}>
              <Close
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setEdit(false);
                  setFormUser(!formUser);
                }}
              />
            </Grid>
          </Grid>
          <AddUserFromCompany
            submitUser={(values) => {
              if (edit) {
                values.id = userSelected._id;
                updateCompanyUser(values);
              } else {
                addCompanyUser(values);
              }
              setFormUser(false);
              setEdit(false);
            }}
            userData={userSelected}
            canEdit={edit}
          />
        </Box>
      </Modal>
    </>
  );
};
