import * as React from "react";
//import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
//import InputBase from "@mui/material/InputBase";
import logo from "../../assets/logo.png";
//import MenuItem from "@mui/material/MenuItem";
//import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
//import SearchIcon from "@mui/icons-material/Search";
/*import AccountCircle from "@mui/icons-material/AccountCircle";
import ProfileImg from "../icons/Profile";*/
import { UserContext } from '../../context/userContext';
//import MoreIcon from "@mui/icons-material/MoreVert";
import SideMenu from "./Menu";
import { useContext } from "react";
import Country from "../country/Country";
/*const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));*/

/*const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));*/

/*const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));*/
const NavBar = () => {
  const { user } = useContext(UserContext);
  //const { screen, setScreen } = useContext(UserContext);
  //const [anchorEl, setAnchorEl] = React.useState(null);
  //const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  //const isMenuOpen = Boolean(anchorEl);
  ///const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  /*const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };*/

  /*const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };*/

  /*const handleMenuClose = () => {
    if(screen !== 1){
      setScreen(1)
    }
    setAnchorEl(null);
    handleMobileMenuClose();
  };*/

  /*const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };*/
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  //const menuId = "primary-search-account-menu";
  /*const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );*/

  // eslint-disable-next-line no-lone-blocks
  {/*const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
        onClick={handleProfileMenuOpen}
      }
      <MenuItem>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {
            user.avatar ? :<ProfileImg /> : <AccountCircle />
          }
        </IconButton>
        {
          <p>Profile</p>
        }
      </MenuItem>
    </Menu>
  ); */}

  return (
    <Box
      style={{ position: "fixed", width: "100%", zIndex: 5, top: 0 }}
      sx={{ flexGrow: 1 }}
    >
      <SideMenu toggleDrawer={toggleDrawer} state={state} />
      <AppBar sx={{backgroundColor: '#00334E'}} position="static">
        <Toolbar className="flex justify-between">
          <img className="header-logo" src={logo} alt="logo" />

          {/*<Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>*/}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              onClick={toggleDrawer("left", true)}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {(user && user.email) && <Country showOptionLabel={false} showSelectedLabel={false} />}
            {/*<IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {user.avatar ? <ProfileImg /> : <AccountCircle />}
            </IconButton>*/}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              onClick={toggleDrawer("left", true)}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {/*<IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
          </IconButton>*/}
          </Box>
        </Toolbar>
      </AppBar>
      {/*renderMobileMenu*/}
      {/*{renderMenu}*/}
    </Box>
  );
};
export default NavBar;
