import React, { useContext } from "react";
import styled from "@emotion/styled";

import { UserContext } from "../../context/userContext";
import Profile from "../profile/Profile";
import Mortgage from "../mortgage/Mortgage";
import { MainOptions } from '../MainOptions/MainOptions';
import { Simulator } from "../Simulator";
import { DashboardPeople } from '../Dashboard/People/index';
import { DashboardCompanies } from '../Dashboard/Companies/index';
import { AdminPanel } from '../Dashboard/Admin/index';

const Container = styled.div({});

export const Home = () => {
  const { screen } = useContext(UserContext);
  React.useEffect(() => {
    localStorage.setItem('screen', screen)
  }, [screen]);
  return (
    <>
      <br />
      <br />
      <Container style={{height: "100%"}}>
        {screen === 0 && <MainOptions/>}
        {screen === 1 && <Simulator />}
        {screen === 2 && <Profile />}
        {screen === 3 && <Mortgage />}
        {screen === 4 && <DashboardPeople />}
        {screen === 5 && <DashboardCompanies />}
        {screen === 6 && <AdminPanel />}
      </Container>
    </>
  );
};
export default Home;
