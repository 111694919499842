import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { CardOfferBuy } from "../../UI/cards/buy-offers/index";
import { offersPackages } from "../../../constants/data";

export const GetOfferQuotes = ({ company, onSubmit }) => {
  return (
    <>
      <Grid container justifyContent={"center"} spacing={{ md: 1 }}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent={"space-around"}>
            <Grid item xs={12} md={6}>
              <label className="font-semibold list-text-resposive">
                Datos del beneficiario:
              </label>
              <ul>
                <li className="list-text-responsive">
                  Empresa: {company.nameEnterprise}
                </li>
                <li className="list-text-responsive">
                  Tipo de empresa: {company.typeEnterprise}
                </li>
                <li className="list-text-responsive">Email: {company.email}</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="font-semibold list-text-resposive">
                Ofertas disponibles:
              </label>
              <ul>
                <li className="list-text-responsive">
                  Siete dias de duracion:{" "}
                  {company.availableQuotes.sevenDays.quantity}
                </li>
                <li className="list-text-responsive">
                  Quince dias de duracion:{" "}
                  {company.availableQuotes.fifteenDays.quantity}
                </li>
                <li className="list-text-responsive">
                  Treinta dias de duracion:{" "}
                  {company.availableQuotes.thirteenDays.quantity}
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ paddingY: "1rem" }}
            justifyContent={"space-around"}
          >
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary id="package-one" expandIcon={<ExpandMore />}>
                  <h3 className="subtitle-4 p-1">Paquetes tipo A</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} spacing={1}>
                    {offersPackages.package_one.map((offer, index) => (
                      <Grid item key={index} xs={4} md={3.5}>
                        <CardOfferBuy
                          quantity={offer.quantity}
                          price={offer.price}
                          duration={offer.exprire_days}
                          onPurchase={(values) => {
                            onSubmit({
                              quantity: values.quantity,
                              duration: values.duration,
                              priceId: offer.price_id,
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary id="package-one" expandIcon={<ExpandMore />}>
                  <h3 className="subtitle-4 p-1">Paquetes tipo B</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} spacing={1}>
                    {offersPackages.package_two.map((offer, index) => (
                      <Grid item key={index} xs={4} md={3.5}>
                        <CardOfferBuy
                          quantity={offer.quantity}
                          price={offer.price}
                          duration={offer.exprire_days}
                          onPurchase={(values) => {
                            onSubmit({
                              quantity: values.quantity,
                              duration: values.duration,
                              priceId: offer.price_id,
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary id="package-one" expandIcon={<ExpandMore />}>
                  <h3 className="subtitle-4 p-1">Paquetes tipo C</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} spacing={1}>
                    {offersPackages.package_three.map((offer, index) => (
                      <Grid item key={index} xs={4} md={3.5}>
                        <CardOfferBuy
                          quantity={offer.quantity}
                          price={offer.price}
                          duration={offer.exprire_days}
                          onPurchase={(values) => {
                            console.log(values);
                            onSubmit({
                              quantity: values.quantity,
                              duration: values.duration,
                              priceId: offer.price_id,
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary id="package-one" expandIcon={<ExpandMore />}>
                  <h3 className="subtitle-4 p-1">Paquetes tipo D</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} spacing={1}>
                    {offersPackages.package_four.map((offer, index) => (
                      <Grid item key={index} xs={4} md={3.5}>
                        <CardOfferBuy
                          quantity={offer.quantity}
                          price={offer.price}
                          duration={offer.exprire_days}
                          onPurchase={(values) => {
                            console.log(values);
                            onSubmit({
                              quantity: values.quantity,
                              duration: values.duration,
                              priceId: offer.price_id,
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
