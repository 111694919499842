import React from "react";
import styled from "@emotion/styled";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
const Container = styled.div({
  backgroundColor: "rgba(250,200,2,.3)",
  border: "solid 1px rgba(255,220,2,.3)",
  height: "30vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 5,
});
const Warning = ({setScreen}) => {
  return (
    <>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <WarningIcon />
          </Grid>
          <Grid item xs={12}>
            <p>Completa tu perfil para que puedas ver las ofertas </p>
          </Grid>
          <Grid item xs={12}>
            <button className="text-info cursor-pointer underline underline-offset-1" onClick={() => setScreen(1)}>Ir al simulador</button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Warning;
