import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import TextField from "../../components/UI/TextField";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { ButtonSecondary } from "../../components/UI/Buttons";
import { Grid } from "@mui/material";
import { AddBountyData, UpdateUserController } from "../../Api/Controller";
import { UserContext } from "../../context/userContext";
import { useContext } from "react";
import RadioButton from "../../components/UI/RadioButton";
import SelectMultiple from "../../components/UI/Select";
import { Loans } from "../../constants/data";
import Country from "../../components/country/Country";
const Ray = styled.div({
  width: "100%",
  height: 1,
  marginBottom: "2vw",
  backgroundColor: "rgb(220,220,220)",
});
const Wrapper = styled.div({
  width: "100%",
  backgroundColor: "rgb(252,252,252)",
  padding: "2vw 1vw 0 2vw",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 10,
  boxShadow: "0 1vw 4vw 0vw rgba(150,150,150,0.5)",
  marginBottom: "6vw",
});
const Tittle = styled.p({
  fontFamily: "Bebas Neue, cursive",
  margin: 0,
  fontSize: 30,
  color: "rgb(140,140,160)",
  textAlign: "left",
});

const Card = styled(Form)({});

const SignupSchema = Yup.object().shape({
  email: Yup.string("debe haber texto")
    .min(4, "El correo es muy corto")
    .max(50, "El correo es muy largo")
    .required("Este campo es requerido"),
  name: Yup.string("debe haber texto")
    .min(3, "El nombre  es muy corto")
    .max(50, "El nombre es largo")
    .required("Este campo es requerido"),
  birth: Yup.string().required("Ingrese su fecha de nacimiento"),
  lastName: Yup.string("debe haber texto")
    .min(4, "El apellido es muy corto")
    .max(50, "El apellido es muy largo")
    .required("Este campo es requerido"),
  phone: Yup.string("debe haber texto")
    .min(4, "debes introducir un numero de telefono valido")
    .max(50, "debes introducir un numero de telefono valido")
    .required("Este campo es requerido"),
});
const BountySchema = Yup.object().shape({
  income: Yup.string("debe haber texto")
    .min(3, "Debe proporcionar un numero mas grande")
    .max(50, "La cifre es demasiado grande")
    .required("Este campo es requerido"),
  yearsOfWork: Yup.string("debe haber texto")
    .min(2, "La respuesta  es muy corta")
    .max(50, "La respuesta es larga")
    .required("Este campo es requerido"),
});
const Avatar = styled.img({
  width: "60%",
  borderRadius: "100%",
});
const Text = styled.p({
  fontFamily: "Bebas Neue, cursive",
  margin: 0,
  fontSize: 20,
  color: "rgb(140,140,160)",
  textAlign: "left",
});
const Profile = () => {
  const { user, bussinesUser, bountyData, setBountyData, token } = useContext(UserContext);
  const [couple, setCouple] = React.useState("no");
  const [typeOfLoan, setTypeOfLoan] = React.useState(bountyData.typeOfLoan || []);
  const [Contribute, setContribute] = React.useState("no");
  const [independent, setIndependent] = React.useState("no");
  const userType = localStorage.getItem('type-user')
  const handleChangeCouple = (event) => {
    setCouple(event.target.value);
  };
  const handleChangeContribute = (event) => {
    setContribute(event.target.value);
  };
  const handleChangeIndependent = (event) => {
    setIndependent(event.target.value);
  };
  return (
    <>
      <br />

      <Grid container justifyContent={"center"}>
        <Grid item xs={11}>
          <Wrapper>
            <Grid container justifyContent={"center"}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={2}>
                  <Avatar src={user.avatar} />
                </Grid>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Formik
                  initialValues={{
                    email: userType === 'user' ? user.email : bussinesUser.email,
                    name: userType === 'user' ? user.name : bussinesUser.name,
                    birth: (userType === 'user' && user.birth) ? moment(user.birth).format('L') : moment().format('L'),
                    lastName: userType === 'user' ? user.lastName : bussinesUser.typeEnterprise,
                    phone: userType === 'user' ? user.phone : bussinesUser.phone,
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    // same shape as initial values
                    values.id = user.id || bussinesUser.id;
                    UpdateUserController(values, token || localStorage.getItem('token'), userType)
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Card>
                      <Tittle>Datos del perfil</Tittle>
                      <Ray></Ray>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={12}>
                            <Text>Nombre</Text>
                            <TextField
                              name="name"
                              error={errors.name}
                              touched={touched.name}
                              placeholder="nombre"
                            />
                          </Grid>
                          {user.id && <Grid item md={6} xs={12}>
                            <Text>Apellido</Text>
                            <TextField
                              name="lastName"
                              error={errors.lastName}
                              touched={touched.lastName}
                              placeholder="Apellido"
                            />
                          </Grid>}
                          <Grid item md={6} xs={12}>
                            <Text>Correo</Text>
                            <TextField
                              name="email"
                              error={errors.email}
                              touched={touched.email}
                              placeholder="Correo"
                            />
                          </Grid>
                          {(userType === 'user') && <Grid item xs={12} md={6}>
                            <Text>Fecha de nacimiento</Text>
                            <input
                              type="date"
                              name="birth"
                              id="birth"
                              value={values.birth}
                              className="border-2 border-field w-full px-4 py-4 rounded-lg"
                              onChange={(ev) => values.birth = moment(ev.target.value).format('L')}
                            />
                            {errors.birth && <p>Ingrese su fecha de nacimiento</p>}
                          </Grid>}
                          <Grid item md={6} xs={12}>
                            <Text>Telefono</Text>
                            <TextField
                              name="phone"
                              error={errors.phone}
                              touched={touched.phone}
                              placeholder="Telefono"
                            />
                          </Grid>
                        </Grid>

                        <br />
                        <Grid container justifyContent={"center"}>
                          <Grid item md={5} lg={3} xs={12}>
                            <ButtonSecondary type="submit">
                              Guardar datos del perfil
                            </ButtonSecondary>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  )}
                </Formik>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} style={{paddingTop: '2%', paddingBottom: '2%'}} spacing={4}>
              <Grid item lg={6} xs={12}>
                <Formik
                  initialValues={{
                    income: bountyData.income,
                    yearsOfWork: bountyData.yearsOfWork,
                  }}
                  validationSchema={BountySchema}
                  onSubmit={(values) => {
                    // same shape as initial values
                    const data = Object.assign(values, {
                      couple: couple,
                      id: (user.id || bussinesUser.id),
                      Contribute: Contribute,
                      independent: independent,
                      country: user.country,
                      typeOfLoan: typeOfLoan,
                    });
                    AddBountyData(data, setBountyData, token || localStorage.getItem('token'));
                  }}
                >
                  {({ errors, touched }) => (
                    <Card>
                      <Tittle>Datos complementarios</Tittle>
                      <Ray></Ray>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item md={8} xs={12}>
                            <Text>¿Cuanto ingreso liquido posees?</Text>
                            <TextField
                              name="income"
                              error={errors.income}
                              touched={touched.income}
                              placeholder="¿Cuanto ingreso liquido posees?"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Text>¿Eres Independiente?</Text>
                            <Grid container>
                              <Grid item xs={4}>
                                <RadioButton
                                  Checked={independent === "no"}
                                  onChange={handleChangeIndependent}
                                  Text={"No"}
                                  value={"no"}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <RadioButton
                                  Checked={independent === "si"}
                                  onChange={handleChangeIndependent}
                                  Text={"Si"}
                                  value={"si"}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={8} xs={12}>
                            <Text>Antigüedad laboral.</Text>
                            <TextField
                              name="yearsOfWork"
                              error={errors.yearsOfWork}
                              touched={touched.yearsOfWork}
                              placeholder="¿Cuantos Años de trabajo tienes?"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Text>¿Sumarias a otra persona para el préstamo?</Text>
                            <Grid container>
                              <Grid item xs={4}>
                                <RadioButton
                                  Checked={couple === "no"}
                                  onChange={handleChangeCouple}
                                  Text={"No"}
                                  value={"no"}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <RadioButton
                                  Checked={couple === "si"}
                                  onChange={handleChangeCouple}
                                  Text={"Si"}
                                  value={"si"}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item md={8} xs={12}>
                            <Text>Tipo de prestamo</Text>
                            <SelectMultiple
                              personName={typeOfLoan}
                              setPersonName={setTypeOfLoan}
                              data={Loans}
                              placeholder="Tipo de prestamo"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            {couple === "si" && (
                              <>
                                <Text>¿Contribuira con el prestamo?</Text>
                                <Grid container>
                                  <Grid item xs={2}>
                                    <RadioButton
                                      Checked={Contribute === "no"}
                                      onChange={handleChangeContribute}
                                      Text={"No"}
                                      value={"no"}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <RadioButton
                                      Checked={Contribute === "si"}
                                      onChange={handleChangeContribute}
                                      Text={"Si"}
                                      value={"si"}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Grid item md={8} xs={12}>
                            <Text>Pais donde se ubica</Text>
                            <Country />
                          </Grid>
                        </Grid>
                        <Grid container justifyContent={"center"} style={{margin: '2%'}}>
                          <Grid item md={5} lg={5} xs={12}>
                            <ButtonSecondary type="submit">
                              Guardar datos
                            </ButtonSecondary>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={12} md={6}>
                <Tittle>Info</Tittle>
                <Ray></Ray>
                <Grid container spacing={1} style={{paddingBottom: '2%'}}>
                  <Grid item xs={12}>
                    <b>Seguridad</b>
                  </Grid>
                  <Grid item textAlign={'left'} xs={12}>Esta información no es obligatoria y es únicamente para uso interno del sistema, no se compartirá con agentes externos </Grid>
                </Grid>
                <Grid container spacing={1} style={{paddingTop: '2%'}}>
                  <Grid item xs={12}>
                    <b>Informacion de interes</b>
                  </Grid>
                  <Grid item textAlign={'left'} xs={12}><b>¡Importante!:</b> Los cálculos y tasas pueden ser diferentes según las instituciones financieras, nuestro sistema proporciona un estudio preliminar de cada consulta realizada por el usuario siendo esta independiente a las consultas de cada institución financiera.</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Wrapper>
        </Grid>
      </Grid>
    </>
  );
};
export default Profile;
