import React from "react";
import { Box } from "@mui/material";
import Chart from "react-apexcharts";

export const BarChart = ({labels, series, seriesLabel = 'series'}) => {
  const chartData = {
      options:{
        chart:{
          toolbar: {
            show: false
          }
        },
        plotOptions:{
          bar:{
            horizontal: true,
            dataLabels:{
              position: 'top'
            }
          }
        },
        dataLabels:{
          style: {
            colors: ['#333']
          },
          offsetX: 35
        },
        xaxis: {
          categories: labels || []
        }
      },
      series: [{
        name: seriesLabel,
        data: series || []
      }]
    }
  return (
    <Box className="bg-white">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
      />
    </Box>
  );
};
