import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";

import { UserContext } from "../../../../context/userContext";
import { ButtonSecondary } from "../../../../components/UI/Buttons";
import TextField from "../../../../components/UI/TextField";
import { AddCompany, UpdateCompany } from '../../../../Api/Controller';

export const AdminAddCompany = ({
  companySelected = "",
  setAddCompany,
  setEditUser,
  setUsers
}) => {
  const { token, user } = React.useContext(UserContext);
  const typeUser = localStorage.getItem("type-user");

  function closeModal() {
    setAddCompany(false);
    setEditUser(false);
  }
  const [edit, setEdit] = React.useState(false);

  const submitCompany = (companySubmitted) => {
    if (companySelected._id) {
      UpdateCompany(companySubmitted, token, typeUser, user.role, setUsers);
    } else {
      AddCompany(companySubmitted, setUsers);
    }
    setAddCompany(false);
    setEditUser(false);
  };

  const initial = {
    user: "",
    email: "",
    name: "",
    password: "123456",
    phone: "",
    typeEnterprise: "",
    position: "",
    rut: "",
    role: "company",
    availableQuotes: 0
  };

  const loadValues = {
    id: companySelected._id,
    user: companySelected.user,
    email: companySelected.email,
    name: companySelected.name,
    phone: companySelected.phone,
    typeEnterprise: companySelected.typeEnterprise,
    position: companySelected.position,
    rut: companySelected.rut,
    role: companySelected.role,
    availableQuotes: companySelected.availableQuotes
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string("debe haber texto")
      .min(4, "El correo es muy corto")
      .max(50, "El correo es muy largo")
      .required("Este campo es requerido"),
    user: Yup.string("debe haber texto")
      .min(4, "El nombre de usuario es muy corto")
      .max(50, "El nombre de usuario es muy largo")
      .required("Este campo es requerido"),
    name: Yup.string("debe haber texto")
      .min(3, "El nombre  es muy corto")
      .max(50, "El nombre es largo")
      .required("Este campo es requerido"),
    typeEnterprise: Yup.string("Requerido")
      .min(3, "Debe haber 3 caracteres minimo")
      .max(25, "Debe tener maximo 25 caracteres")
      .required("Este campo es requerido"),
    position: Yup.string("Requerido")
      .min(3, "Debe haber 3 caracteres minimo")
      .max(25, "Debe tener maximo 25 caracteres")
      .required("Este campo es requerido"),
    rut: Yup.string("Requerido")
      .min(3, "Debe haber 3 caracteres minimo")
      .max(25, "Debe tener maximo 25 caracteres")
      .required("Este campo es requerido"),
    phone: Yup.string("debe haber texto")
      .min(4, "debes introducir un numero de telefono valido")
      .max(50, "debes introducir un numero de telefono valido")
      .required("Este campo es requerido"),
  });

  React.useEffect(() => {
    if (companySelected) {
      setEdit(true);
    }
  }, [companySelected]);

  return (
    <>
      <Grid
        container
        className="flex flex-col w-full bg-white opacity-100 rounded-2xl z-auto pt-64 lg:pt-0"
        spacing={1}
      >
        <Grid item className="flex justify-end items-center p-4" xs={12}>
          <Close onClick={closeModal} className="text-danger cursor-pointer" />
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={edit ? loadValues : initial}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => {
              submitCompany(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={12} md={5}>
                    <h2 className="subtitle-2">Agregar empresa</h2>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="py-4"
                  justifyContent={"center"}
                  spacing={2}
                >
                  <Grid item xs={12} md={5}>
                    <label>Nombre de usuario</label>
                    <TextField
                      name="user"
                      error={errors.user}
                      touched={touched.user}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Nombre de la empresa</label>
                    <TextField
                      name="name"
                      error={errors.name}
                      touched={touched.name}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Tipo de empresa</label>
                    <TextField
                      name="typeEnterprise"
                      error={errors.typeEnterprise}
                      touched={touched.typeEnterprise}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Email</label>
                    <TextField
                      name="email"
                      error={errors.email}
                      touched={touched.email}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Cargo</label>
                    <TextField
                      name="position"
                      error={errors.position}
                      touched={touched.position}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>RUT</label>
                    <TextField
                      name="rut"
                      error={errors.rut}
                      touched={touched.rut}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Phone</label>
                    <TextField
                      name="phone"
                      error={errors.phone}
                      touched={touched.phone}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label>Cantidad de ofertas</label>
                    <TextField
                      name="availableQuotes"
                      error={errors.availableQuotes}
                      touched={touched.availableQuotes}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container className="p-4" justifyContent={"center"}>
                  <Grid item xs={12} md={3}>
                    <ButtonSecondary type="submit">
                      {companySelected._id ? "Editar" : "Agregar"}
                    </ButtonSecondary>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
