/* eslint-disable eqeqeq */
import React from "react";
import { useParams, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import { CompanyContext } from '../../context/companyContext';

import { ButtonSecondary } from '../../components/UI/Buttons';

const Price = styled.p({
  letterSpacing: 1,
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  fontFamily: "Bebas Neue, cursive",
  wordWrap: "break-word",
  fontSize: 30,
  color: "rgb(140,140,155)",
  margin: 0,
});
const Tittle = styled.p({
  fontFamily: "Bebas Neue, cursive",
  margin: 0,
  fontSize: 40,
  color: "rgb(140,140,160)",
  textAlign: "left",
});
const Text = styled.p({
  color: "rgb(140,140,160)",
  fontFamily: "Roboto Condensed, sans-serif",
  textAlign: "justify",
});
export const BankDetail = (props) => {
  const { id } = useParams();
  const { mortgageOffersList, automotiveOffersList, getOffers } = React.useContext(CompanyContext);

  React.useEffect(() => {
    getOffers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <br />
      <br />
      <br />
      {mortgageOffersList.filter((e) => id == e.code).map((i, index) => {
        const expireDate = new Date(i.expiresAt);
        return (
          <div key={index}>
            <Grid container justifyContent={'center'} className="py-2">
              <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent:"center"}}>
                <img className="rounded-xl" src={i.imageDetail} alt="mortgage" style={{objectFit: 'contain'}} />
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"}>
              <Grid item xs={11}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12}>
                    <Text variant="body2" color="text.secondary">
                      Valida hasta: {expireDate.toLocaleDateString('es-MX')}
                    </Text>
                  </Grid>
                  <Grid item xs={4} md={2} lg={2}>
                    <Price>Empresa: {i.company.nameEnterprise}</Price>
                  </Grid>
                  <Grid item  xs={12} lg={4}>
                    <Grid
                      container
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Grid item xs={10}>
                        <Price>Tipo de oferta: {i.typeOffer}</Price>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Tittle>{i.name}</Tittle>
                </Grid>
                <Grid item xs={12}>
                  <Text variant="body2" color="text.secondary">
                    {i.description}
                  </Text>
                </Grid>
                {/*<Grid item xs={12}>
                  <Tittle>Opciones Relacionadas</Tittle>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"} spacing={4}>
                    {HipotecasPrueba.map((i, index) => {
                      return (
                        <Grid key={index} item md={4} xs={8} sm={5} lg={3}>
                          <Card key={index} data={i} />
                        </Grid>
                      );
                    })}
                  </Grid>
                  </Grid>*/}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {automotiveOffersList.filter((e) => id == e.code).map((i, index) => {
        const expireDate = new Date(i.expiresAt);
        return (
          <div key={index}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={6}>
                <img className="rounded-xl" src={i.imageDetail} alt="automotive" />
              </Grid>
            </Grid>
            <br />
            <Grid container justifyContent={"center"}>
              <Grid item xs={11}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12}>
                    <Text variant="body2" color="text.secondary">
                      Valida hasta: {expireDate.toLocaleDateString('es-MX')}
                    </Text>
                  </Grid>
                  <Grid item xs={4} md={6}>
                    <Price>Empresa: {i.company.nameEnterprise}</Price>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Grid
                      container
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12}>
                        <Price>Tipo de credito: {i.typeOffer}</Price>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Tittle>{i.name}</Tittle>
                </Grid>
                <Grid item xs={12}>
                  <Text variant="body2" color="text.secondary">
                    {i.description}
                  </Text>
                </Grid>
                {/*<Grid item xs={12}>
                  <Tittle>Opciones Relacionadas</Tittle>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"} spacing={4}>
                    {AutosPrueba.map((i, index) => {
                      return (
                        <Grid key={index} item md={4} xs={8} sm={5} lg={3}>
                          <Card key={index} data={i} />
                        </Grid>
                      );
                    })}
                  </Grid>
                  </Grid>*/}
              </Grid>
            </Grid>
          </div>
        );
      })}
      <Grid container justifyContent={'center'} spacing={1} sx={{marginY: '1%'}}>
        <Grid item xs={11} md={3}>
          <Link to="/home">
            <ButtonSecondary>Volver</ButtonSecondary>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
