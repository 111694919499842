import React from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { ButtonText } from "./Texts";

import './UI.css';

export const ButtonPrimary = ({ children, type, onClick, width="100%", topMargin=30, isDisabled = false }) => {
  const Button1 = styled(Button)({
    /*background:
      "linear-gradient(120deg, rgb(50,185,255) 30%, rgba(50,100,200) 100%)",*/

    width: width,
    marginTop: topMargin,
    marginBottom: 5,
    borderRadius: 6,
  });
  const Text = styled(ButtonText)({
    color: "blue",
  });
  return (
    <>
      <Button1 type={type} onClick={onClick} className="bg-gradient-to-r from-cyan-500 to-blue-500" variant="contained" disabled={isDisabled}>
        <Text>{children}</Text>
      </Button1>
    </>
  );
};
export const ButtonSecondary = ({ children, type, onClick, disabled=false}) => {
  const Text = styled(ButtonText)({
    color: "#FFFFFF",
  });
  return (
    <>
      <Button className={disabled ? 'button-disabled' : 'secondary-button'} onClick={onClick} type={type} disabled={disabled} variant="contained">
        <Text>{children}</Text>
      </Button>
    </>
  );
};
export const RoundedButton = ({ children, type, onClick }) => {
  const Button1 = styled(Button)({
    background:
      "linear-gradient(120deg, rgb(0,144,255) 30%, rgba(18,79,158) 100%)",
    width: "100%",
    borderRadius: 30,
  });
  const Text = styled(ButtonText)({
    color: "blue",
  });
  return (
    <>
      <Button1 onClick={onClick} type={type} variant="contained">
        <Text>{children}</Text>
      </Button1>
    </>
  );
};
export const RoundedButton2 = ({ children, type, onClick }) => {
  const Button1 = styled(Button)({
    borderColor: "white",
    width: "100%",
    marginTop: 5,
    borderRadius: 30,
    color: "white",
  });
  const Text = styled(ButtonText)({
    color: "blue",
  });
  return (
    <>
      <Button1 onClick={onClick} type={type} variant="outlined">
        <Text>{children}</Text>
      </Button1>
    </>
  );
};
