import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "@emotion/styled";
const Icon = styled(ClearIcon)({
  background:
    "linear-gradient(120deg, rgb(250,25,25) 30%, rgba(120,15,15) 100%)",
  color: "white",
  padding: 2,
  borderRadius: 20,
  fontSize: 12,
});
const ErrorIcon = () => {
  return (
    <>
      <Icon />
    </>
  );
};
export default ErrorIcon;
