import { Grid } from '@mui/material';
//import { Facebook, Twitter, Instagram, Phone, Telegram } from '@mui/icons-material';

import { Available } from '../../../components/UI/MainPage/Available/index';

const date = new Date();

export const Footer = () => {
  return(
    <>
      <div className="bg-primary text-white p-1">
        <Grid container className="w-full flex justify-around p-2">
          {/*<Grid item xs={12} md={2} className="our-terms">
            <h4 className='text-center font-semibold text-lg pb-1 md:text-left'>Aspectos legales</h4>
            <ul className="text-left">
              <li>
                <a href="/privacy">Politica de privacidad</a>
              </li>
              <li>
                <a href="/terms">Terminos y condiciones</a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={2} className="social-media">
            <h4 className='text-center font-semibold text-lg pb-1 md:text-left'>Nuestras redes</h4>
            <ul className="text-left">
              <li>
                <p><Facebook/> CrediAp</p>
              </li>
              <li>
                <p><Twitter/> @CrediAp</p>
              </li>
              <li>
                <p><Instagram/> @CrediAp</p>
              </li>
            </ul>
          </Grid>*/}
          <Grid item xs={12} className="contact">
            <Available/>
          </Grid>
          <Grid item xs={12}>
            <p>Contactos: info@crediap.com  Acceso a <a href="/terms" className="cursor-pointer">TYC</a> y <a href="/privacy" className="cursor-pointer">politica de privacidad</a></p>
          </Grid>
        </Grid>
        <hr className="m-1" />
        <div className='w-full flex justify-center'>
          <p><b>CrediAp</b> {date.getFullYear()} © Todos los derechos reservados. </p>
        </div>
      </div>
    </>
  );
}