// - Revisar porque no se puede editar los paises
import React, { useContext } from "react";
import "./country.css";
import ReactFlagsSelect from "react-flags-select";
import { UserContext } from "../../context/userContext";
const Country = ({
  showOptionLabel,
  showSelectedLabel,
  register,
  value,
  setValue,
}) => {
  const { user, bussinesUser, setUser } = useContext(UserContext);
  return (
    <>
      <ReactFlagsSelect
        className="select"
        countries={["UY", "BR", "CO", "PE", "CL", "VE"]}
        showSelectedLabel={showSelectedLabel}
        showOptionLabel={showOptionLabel}
        selected={register ? value : (user.country || bussinesUser.country)}
        selectButtonClassName="menuflagsbutton"
        onSelect={(code) => {
            register ? setValue(code) : setUser({ ...user, country: code })
          }
        }
      />
    </>
  );
};
export default Country;
