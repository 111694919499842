import React from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Edit, PersonRemove } from "@mui/icons-material";

export const DataTable = ({ items, columns, onUpdate, onDelete }) => {
  React.useMemo(() => {
    const exists = columns.findIndex((column) => column.field === "actions");
    if (exists === -1) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Opciones",
        width: 200,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              style={{ color: "blue" }}
              icon={<Edit title="Editar" />}
              onClick={() => {
                onUpdate(params.row._id);
              }}
              label="Edit user"
            />,
            <GridActionsCellItem
              style={{ color: "red" }}
              icon={<PersonRemove title="Eliminar" />}
              onClick={() =>
                onDelete({
                  offerId: params.row._id,
                  offerImg: params.row.imageSummary,
                })
              }
              label="Remove user"
            />,
          ];
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={items}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.email || row.code}
          autoHeight={true}
          autoPageSize={true}
        />
      </div>
    </div>
  );
};
