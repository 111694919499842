import { createContext, useState, useEffect } from "react";

import {
  GetUser,
  GetBusinessUser,
  AddCompanyUser,
  UpdateCompany,
  BuyOffersQuotes,
  RemoveUserFromCompany,
  UpdateCompanyUser,
} from "./../Api/Controller";

export const UserContext = createContext();
//ingreso liquido, dependiente, independiente, años de trabajo, tiene pareja (si/no) va a aporta?, tipo de prestamo
// States
export default function UserProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [bountyData, setBountyData] = useState({});
  const [user, setUser] = useState({
    avatar: "",
    backgroundImage: "",
    name: "",
    lastName: "",
    phone: "",
    email: "",
    birth: "",
    id: null,
    username: "",
    password: "",
    typeUser: "",
    country: "",
    referalCode: "",
    lastConnection: "",
    usersRefered: [],
    referedPoints: 0,
    company: [],
    role: "",
  });
  const [searchedUser, setSearchedUser] = useState({
    avatar: "",
    backgroundImage: "",
    name: "",
    lastName: "",
    phone: "",
    email: "",
    id: null,
    username: "",
    password: "",
    typeUser: "",
    country: "",
    lastConnection: "",
  });
  const [bussinesUser, setBussinesUser] = useState({
    id: null,
    user: "",
    email: "",
    name: "",
    password: "",
    lastName: "",
    phone: "",
    typeEnterprise: "",
    position: "",
    rut: "",
    nameEnterprise: "",
    avatar: "",
    country: "",
    lastConnection: "",
    users: [],
    role: "",
  });

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [islogged, setIslogged] = useState(false);
  const [loadding, setLoadding] = useState(false);
  const [screen, setScreen] = useState(
    parseInt(localStorage.getItem("screen")) || 0
  );
  const [nameLotery, setNameLotery] = useState("");
  const [time, setTime] = useState([]);
  //Functions
  function addCompanyUser(payload) {
    AddCompanyUser(payload, setBussinesUser);
  }
  function updateCompany(payload) {
    UpdateCompany(payload, token);
  }
  function updateCompanyUser(payload) {
    UpdateCompanyUser(payload, bussinesUser, setBussinesUser);
  }
  function buyOffersQuotes(payload, setBuyOfferQuota, setProgress) {
    console.log(payload);
    BuyOffersQuotes(payload, setBussinesUser, setBuyOfferQuota, setProgress);
  }
  function removeCompanyUser(id) {
    RemoveUserFromCompany(
      { userId: id, companyId: bussinesUser.id },
      setBussinesUser
    );
  }
  //Hooks
  useEffect(() => {
    const localUser = localStorage.getItem("type-user");
    if (localUser === "user") {
      GetUser(token, setUser, setBountyData);
    } else if (localUser === "business") {
      GetBusinessUser(token, setBussinesUser, setBountyData);
    }
  }, [token]);
  return (
    <UserContext.Provider
      value={{
        user,
        error,
        bountyData,
        message,
        islogged,
        screen,
        nameLotery,
        time,
        loadding,
        bussinesUser,
        token,
        searchedUser,
        setUser,
        setError,
        setBountyData,
        setMessage,
        setIslogged,
        setScreen,
        setNameLotery,
        setTime,
        setLoadding,
        setBussinesUser,
        setToken,
        setSearchedUser,
        addCompanyUser,
        updateCompany,
        updateCompanyUser,
        buyOffersQuotes,
        removeCompanyUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
