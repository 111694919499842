import React from "react";
import {
  AddOffer,
  UpdateOffer,
  GetOffers,
  GetOffersByCompany,
  UpdateOfferViews,
  RemoveOffer,
} from "../Api/Controller";
import { UserContext } from './userContext';
export const CompanyContext = React.createContext();

export const CompanyProvider = ({ children }) => {
  //Imports
  const { bussinesUser, setBussinesUser } = React.useContext(UserContext);
  //States
  const [offer, setOffer] = React.useState("hipotecaria");
  const [allOffers, setAllOffers] = React.useState([]);
  const [offersSorted, setOffersSorted] = React.useState({});
  const [mortgageOffersList, setMortgageOffersList] = React.useState([]);
  const [automotiveOffersList, setAutomotiveOffersList] = React.useState([]);
  const [progress, setProgress] = React.useState(false);
  const [mortgageOffers, setMortgageOffers] = React.useState(0);
  const [automotiveOffers, setAutoMotiveOffers] = React.useState(0);
  //Functions
  const getOffers = () => {
    GetOffers(setAllOffers, setOffersSorted, setMortgageOffersList, setAutomotiveOffersList, setMortgageOffers, setAutoMotiveOffers);
  };

  const getOffersByCompany = (companyId) => {
    GetOffersByCompany(companyId, setAllOffers, setOffersSorted, setMortgageOffersList, setAutomotiveOffersList, setMortgageOffers, setAutoMotiveOffers);
  }

  const addOffer = (payload, setOpenModal) => {
    AddOffer(payload, allOffers, setAllOffers, mortgageOffers, setMortgageOffers, automotiveOffers, setAutoMotiveOffers, setOpenModal, bussinesUser, setBussinesUser);
  };

  const updateOffer = (id, payload, setOpenModal = null) => {
    UpdateOffer(id, payload, allOffers, setAllOffers, setMortgageOffers, setAutoMotiveOffers, setOpenModal);
  };

  const updateOfferViews = (id, payload) => {
    UpdateOfferViews(id, payload);
  }

  const removeOffer = (id) => {
    RemoveOffer(id, allOffers, setAllOffers);
  };

  const company = {
    offer,
    allOffers,
    offersSorted,
    mortgageOffers,
    automotiveOffers,
    mortgageOffersList,
    automotiveOffersList,
    progress,
    setOffer,
    setAllOffers,
    setOffersSorted,
    setMortgageOffers,
    setAutoMotiveOffers,
    setAutomotiveOffersList,
    setMortgageOffersList,
    getOffers,
    getOffersByCompany,
    addOffer,
    updateOffer,
    updateOfferViews,
    removeOffer,
    setProgress
  };
  return (
    <CompanyContext.Provider value={company}>
      {children}
    </CompanyContext.Provider>
  );
};
