import React from "react";
import { Grid } from "@mui/material";
import Hero from "../../components/UI/MainPage/Hero/index";
import { SectionPage } from "../../components/UI/MainPage/SectionsTextImg/index";
import { OurWork } from "../../components/UI/MainPage/OurWork/index";
import { Contact } from "../../components/UI/MainPage/ContactUs";
import { RegisterSection } from "../../components/UI/MainPage/Register/index";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import BG from "../../assets/bg.jpg";
import logo from "../../assets/aboutus.jpg";
import vision from "../../assets/vision.jpg";
import whyUs from "../../assets/whyus.jpg";
import { UserContext } from "../../context/userContext";
import "./FrontPage.css";

const heroData = {
  title: "Credi Ap",
  description:
    "Consulte los mejores créditos hipotecarios, automotrices y personales que el mercado ofrece de forma rápida, sencilla y sin costos.",
  image: `${BG}`,
  imageText: "main image description",
  linkText: "Continue reading…",
};

const FrontPage = () => {
  const { user, bussinesUser } = useContext(UserContext);
  return (
    <>
      {(bussinesUser.id && <Redirect to="/home" />) ||
        (user.id && <Redirect to="/home" />)}
      <Grid container>
        <Grid item xs={12}>
          <Hero data={heroData} />
        </Grid>
        <Grid item xs={12}>
          <SectionPage
            title="Nuestro Sistema"
            id="about"
            content="CrediAp está diseñado para analizar las diferentes propuestas crediticias del mercado de forma rápida y  segura, sin compartir su información con ninguna institución financiera. 
Nuestro sistema permite analizar las diferentes propuestas del mercado, tasas de interés anuales, años y cantidad de cuotas, permitiendo a nuestros usuarios tener una herramienta capaz de visualizar todas las propuestas del mercado en un mismo lugar y en solo tres pasos. 
CrediAp compara en base a los datos proporcionados por el usuario las diferentes propuestas en un mismo lugar."
            image={logo}
          />
        </Grid>
        <Grid item xs={12}>
          <OurWork />
        </Grid>
        <Grid item xs={12}>
          <SectionPage
            title="Nuestra vision"
            id="howWorks"
            content="Nos enfocamos en  consultas y análisis financiero on-line, en la búsqueda de aportar valor a la hora de evaluar todo préstamo que están analizando solicitar. 
Nosotros no somos una entidad financiera y nuestro sistema no aporta información relacionada con el historial crediticio.
Nosotros  analizamos posibilidades de crédito, basados en cálculos estimados de tasas de interés y otros datos relevantes a la hora de solicitar cualquier tipo de préstamo. 
Lo que nos convierte en una herramienta de consulta altamente eficiente y desde la comodidad de su hogar."
            image={vision}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionPage
            title="¿Por qué elegirnos?"
            id="why-us"
            display="reverse"
            content=" Somos una plataforma de análisis y consulta de diferentes tipos de créditos.
Nuestro sistema no sugiere, no patrocina y no modifica los datos de las diferentes instituciones financieras del mercado.
Nuestra plataforma de uso gratuito le permite tener todas las ofertas y propuestas del mercado en un mismo lugar, brindando una herramienta para tomar decisiones de manera más eficiente, sin compartir su información personal con las entidades financieras.
"
          image={whyUs}
          />
        </Grid>
        <Grid item xs={12}>
          <RegisterSection />
        </Grid>
        <Grid item xs={12}>
          <Contact />
        </Grid>
      </Grid>
    </>
  );
};
export default FrontPage;
