import React from "react";
import { Grid, Card, CardHeader, CardContent, Box } from "@mui/material";
import { House, Person, DirectionsCar } from '@mui/icons-material';

const works = [
  {
    title: "Credito hipotecario",
    description:
      "CrediAp cuenta con un cotizador específico para créditos hipotecarios, analizando cantidad de años, cuotas, tasas e interés acumulados. Permitiendo a los usuarios tener una idea más precisa de los costos y cuotas. Nuestro sistema no proporciona  estimado de cuotas, tasas anuales e  intereses acumulados  a lo largo del préstamo.",
    icon: <House style={{fontSize: '3rem'}}/>
  },
  {
    title: "Credito Personal",
    description:
      "Cotizador de préstamos personales, específicamente diseñado para aportar a nuestros usuarios más detalles sobre las propuestas que ofrece el mercado. Nuestro sistema le permite analizar por cuenta propia cuáles son las mejores opciones que el mercado ofrece siendo una herramienta útil y desde la comodidad de su casa.",
    icon: <Person style={{fontSize: '3rem'}}/>
   },
  {
    title: "Credito Automotor",
    description:
      "Cotizador de préstamos automotores, diseñado para proporcionar la información de los créditos que ofrecen las entidades financieras con este servicio a disposición, proporcionando detalle de posibles cuotas e intereses. Diseñado para hacer más sencillo analizar las propuestas que el mercado ofrece y de forma totalmente gratuita.",
    icon: <DirectionsCar style={{fontSize: '3rem'}}/>
   },
];

export const OurWork = () => {
  return (
    <section id='OurWork' className="py-24 px-4">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <h3 className="subtitle-2">Nuestro trabajo</h3>
        </Grid>
        {works.map((work, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card>
              <div className="py-3">
                {work.icon}
              </div>
              <CardHeader
                title={work.title}
                titleTypographyProps={{align: 'center'}}
              ></CardHeader>
              <CardContent>
                <Box>
                  <p>{work.description}</p>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
