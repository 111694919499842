import React from "react";
import { Grid, Box } from "@mui/material";
import { Style } from "@mui/icons-material";
import Swal from "sweetalert2";

import { CompanyContext } from "../../../../context/companyContext";

import "./BuyOffers.css";

export const CardOfferBuy = ({ quantity, price, duration, onPurchase }) => {
  const { progress } = React.useContext(CompanyContext);
  return (
    <>
      <Box className="rounded-md bg-gray-100 drop-shadow-lg">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <div className="text-center">
              <Style className="text-primary"></Style>
            </div>
          </Grid>
          <Grid item xs={12}>
            <button
              disabled={progress}
              className="cursor-pointer disabled:cursor-progress w-full h-32 md:h-auto"
              onClick={() => {
                Swal.fire({
                  title: "Comprar Ofertas",
                  text: `Esta seguro de comprar este paquete de ${quantity} ofertas?`,
                  icon: "info",
                  showConfirmButton: true,
                  showCancelButton: true,
                }).then((res) => {
                  if (res.isConfirmed) {
                    onPurchase({ quantity, duration });
                  }
                });
              }}
            >
              <div className="grid grid-cols-1 gap-1 py-3">
                <p className=" text-xs md:text-sm text-center font-semibold">
                  {quantity} publicaciones
                </p>
                <p className="text-xs md:text-sm">
                  Tiempo de duracion {duration} dias
                </p>
                <p className="text-xs text-input font-bold text-info">
                  $USD {price}
                </p>
              </div>
            </button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
