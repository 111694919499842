import React from "react";
import { Grid } from "@mui/material";
import { UserContext } from "../../../context/userContext";
import { UserCard } from "../../../components/UI/cards/user/index";
import { InfoCard } from "../../../components/UI/cards/info";
import { ButtonSecondary } from "../../../components/UI/Buttons";
import "./People.css";

export const DashboardPeople = () => {
  const { user, setScreen } = React.useContext(UserContext);
  return (
    <>
      <br />
      <br />
      <br />
      <div style={{padding:'1rem'}}>
        <Grid container justifyContent={"space-around"} spacing={1}>
          <Grid item xs={12} md={3}>
            <InfoCard
              title="Ofertas y promociones"
              link={true}
              info='Ver las ofertas disponibles tanto hipotecarias como automotoras'
            >
              <ButtonSecondary onClick={() => setScreen(3)}>Ver ofertas</ButtonSecondary>
            </InfoCard>
          </Grid>
          {user.company && user.company.length > 0 && (
            <Grid item xs={12} md={3} className="grid-height">
              <UserCard
                title={`Empresa: ${user.company[0].name}`}
                email={user.company[0].email}
                phone={user.company[0].phone}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <InfoCard
              title={`Referidos: ${user.usersRefered.length}`}
              items={[
                `Puntos: ${user.referedPoints}`,
                `Url de referido: ${window.location.origin}/register/${user.referalCode}`
              ]}
              info='Los puntos de referido le permiten acceder al detalle de los creditos solicitados, ademas por cada 3 usuarios que se registren con su enlace obtendra un punto'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InfoCard
              title="Creditos simulados"
              items={[
                'Hipotecario: 2',
                'Automotor: 4',
                'Personal: 3'
              ]}
              info="Muestra la cantidad de creditos de acuerdo a su interes"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <UserCard
              name={user.name}
              lastName={user.lastName}
              email={user.email}
              phone={user.phone}
              lastConnect={user.lastConnection}
              info={'Datos de usuario'}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container justifyContent={"space-around"} spacing={2}>
          <Grid item xs={12}>
            <p className="subtitle-3">Creditos hipotecarios</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="Bancos mas consultados"
              items={[
                'BBVA: 1',
                'Santander: 1'
              ]}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="Bancos mejor calificados"
              items={[
                'BBVA: 3',
                'Santander: 1',
                'Scotia bank: 2'
              ]}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="TEA por banco"
              items={[
                'BBVA: 4.75%',
                'Santander: 4%',
                'Itau: 4.75%',
                'HSBC: 5.25%',
                'Scotia bank: 4.5%',
                'BHU Uruguay: 3.75%'
              ]}
            ></InfoCard>
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-around"} spacing={2}>
          <Grid item xs={12}>
            <p className="subtitle-3">Creditos automotores</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="Bancos mas consultados"
              items={[
                'BBVA: 2',
                'Santander: 2'
              ]}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="Bancos mejor calificados"
              items={[
                'BBVA: 5',
                'Santander: 3',
                'Scotia bank: 1'
              ]}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="TEA por banco"
              items={[
                'BBVA: 4.75%',
                'Santander: 6.75%',
                'Itau: 7%',
                'Scotia bank: 6%',
              ]}
            ></InfoCard>
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-around"} spacing={2}>
          <Grid item xs={12}>
            <p className="subtitle-3">Creditos personales</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="Bancos mas consultados"
              items={[
                'BBVA: 2',
                'Santander: 1'
              ]}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="Bancos mejor calificados"
              items={[
                'BBVA: 2',
                'Santander: 3',
                'Scotia bank: 2'
              ]}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoCard
              title="TEA por banco"
              items={[
                'BBVA: 4.75%',
                'Santander: 6.75%',
                'Itau: 7%',
                'Scotia bank: 6%',
              ]}
            ></InfoCard>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
