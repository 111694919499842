import React from "react";
import { Grid } from "@mui/material";
import { Business, Phone, Email } from "@mui/icons-material";
export const Contact = () => {
  return (
    <section id="contact" className="py-24 px-4">
      <Grid
        container
        flexDirection={{ xs: "column", md: "row-reverse" }}
        justifyContent={"center"}
        alignContent={"center"}
        spacing={4}
      >
        <Grid item xs={12}>
          <h3 className="subtitle-2">Contactenos</h3>
        </Grid>
        <Grid item xs={12} md={3}>
          <ul className="h-full flex flex-col justify-center space-y-3 md:items-start">
            <li>
              <p>
                <Business /> 6201, Av Italia, Parque tecnologico LATU
              </p>
            </li>
            <li>
              <p>
                <Phone /> +598 880810
              </p>
            </li>
            <li>
              <p>
                <Email /> info@crediap.com
              </p>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="flex justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.0881590808203!2d-56.07903548513092!3d-34.879132480389046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80fcfb86d9ed%3A0x8fed80dbcd8c6f53!2sLATU%20-%20Laboratorio%20Tecnol%C3%B3gico%20del%20Uruguay!5e0!3m2!1ses-419!2sve!4v1678110683541!5m2!1ses-419!2sve"
              title="location"
              width="300"
              height="300"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};
