import React from "react";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import styled from "@emotion/styled";

const Icon = styled(CheckTwoToneIcon)({
  background:
    "linear-gradient(120deg, rgb(50,240,5) 30%, rgba(37,100,10) 100%)",
  color: "white",
  padding: 2,
  borderRadius: 20,
  fontSize: 12,
});
const CheckIcon = () => {
  return (
    <>
      <Icon />
    </>
  );
};
export default CheckIcon;
