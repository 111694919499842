import React from "react";
import Radio from "@mui/material/Radio";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
const Tittle = styled.p({
  fontFamily: "Bebas Neue, cursive",
  margin: 0,
  fontSize: 18,
  color: "rgb(140,140,180)",
});
const RadioButton = ({ value, Text, Checked, onChange }) => {
  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item xs={1}>
          <Tittle>{Text}</Tittle>
        </Grid>
        <Grid item xs={1}>
          <Radio
            onChange={onChange}
            checked={Checked}
            sx={{
              color: "rgb(120,120,180)",
              "&.Mui-checked": {
                color: "rgb(10,200,250)",
              },
            }}
            value={value}
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default RadioButton;
