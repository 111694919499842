import React from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import CardCustom from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

import { CompanyContext } from "../context/companyContext";
import { UserContext } from "../context/userContext";

const Container = styled.div({
  backgroundColor: "rgb(255,255,255)",
  width: "100%",
  boxShadow: "0 0.1vw .2vw 0 rgb(200,200,200)",
  borderRadius: ".4vw",
  height: "100%",
});
const Text = styled.p({
  color: "rgb(140,140,160)",
  fontFamily: "Roboto Condensed, sans-serif",
  textAlign: "justify",
});
const TextLogo = styled.p({
  fontFamily: "Inter",
  color: "#145374",
  fontSize: ".8rem",
  fontWeight: "600",
});
const Tittle = styled.p({
  fontFamily: "Bebas Neue, cursive",
  margin: 0,
  fontSize: 20,
  color: "rgb(140,140,160)",
  textAlign: "left",
});

const Price = styled.p({
  letterSpacing: 1,
  textAlign: "left",
  fontFamily: "Bebas Neue, cursive",
  wordWrap: "break-word",
  fontSize: 18,
  color: "rgb(140,140,155)",
  margin: 0,
});
const Card = ({ data }) => {
  const { updateOfferViews } = React.useContext(CompanyContext);
  const {
    user: { age },
  } = React.useContext(UserContext);
  const navigate = useHistory();
  const expireDate = new Date(data.expiresAt);
  const goToDetails = () => {
    const newView = {
      view: 1,
      userAge: age,
    };
    updateOfferViews(data._id, newView);
    navigate.push(`/bankDetail/${data.code}`);
  };
  return (
    <>
      <Container onClick={goToDetails}>
        <CardCustom sx={{ maxWidth: 400, height: '100%' }}>
          <CardActionArea>
            <CardMedia
              component="img"
              style={{ height: "10rem", objectFit: "contain" }}
              image={data.imageDetail}
              alt=""
            />
            <CardContent>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item lg={4} md={5} sm={6} xs={6}>
                  <TextLogo>{data.company.nameEnterprise}</TextLogo>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Price>{data.name}</Price>
                </Grid>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Tittle>Precio: USD {data.price}</Tittle>
              </Grid>
              <Grid item xs={12}>
                <Text variant="body2" color="text.secondary">
                  {data.summary}
                </Text>
              </Grid>
              <Grid item xs={12}>
                <Text variant="body2" color="text.secondary">
                  Valida hasta: {expireDate.toLocaleDateString('es-MX')}
                </Text>
              </Grid>
            </CardContent>
          </CardActionArea>
        </CardCustom>
      </Container>
    </>
  );
};
export default Card;
