import { Card, Grid, Typography, CardMedia, Box, Divider } from "@mui/material";
import { ButtonSecondary } from "../../../components/UI/Buttons";

import "./results.css";

export const Results = ({
  resultScreen,
  setDetails,
  setBankDetail,
  resultValues,
}) => {
  const backToSimulator = () => {
    localStorage.setItem("results", '[]');
    localStorage.setItem("bankDetail", '{}');
    resultScreen(false);
  };
  const GoToDetails = (result) => {
    setBankDetail(result);
    setDetails(true);
    return;
  };
  const title = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "20px",
    textAlign: "center",
    color: "#145374",
  };
  const boxStyleTitle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 60,
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
  };
  const subtitle = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "11px",
    color: "#145374",
    textAlign: "left",
  };
  const infotext = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "1.5rem",
    textAlign: "left",
  };
  const mainCard = {
    border: "1px solid #D9D9D9",
    display: "flex",
    padding: "4px",
    borderRadius: "4px",
  };
  return (
    <>
      <Grid container justifyContent={"right"} className="py-2">
        <Grid item>
          <ButtonSecondary onClick={backToSimulator}>
            Volver a Simulador
          </ButtonSecondary>
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-around"} spacing={1}>
        {resultValues.map((result, index) => (
          <Grid container key={index} item xs={12} lg={6}>
            <Card sx={mainCard} className="hover:bg-gray-200">
              <Grid container justifyContent={"space-between"} spacing={1}>
                <Grid item xs={12} sm={4} lg={7}>
                  <CardMedia
                    component="img"
                    className="image-card cursor-pointer"
                    image={result.image}
                    onClick={() => GoToDetails(result)}
                    alt="Credits"
                  />
                </Grid>
                <Grid item xs={12} sm={8} lg={5}>
                  <Box sx={boxStyleTitle}>
                    <Typography sx={title}>{result.bank}</Typography>
                  </Box>
                  <Box className="box-style-info">
                    <Typography sx={subtitle}>Cuota mensual:</Typography>
                    {result.moneyType === "usd" && (
                      <>
                        <Typography sx={infotext}>
                          USD: Entre USD { new Intl.NumberFormat('es-MX').format(result.minFee)} y USD { new Intl.NumberFormat('es-MX').format(result.maxFee)}
                        </Typography>
                        <Typography sx={infotext}>
                          Pesos: Entre $U { new Intl.NumberFormat('es-MX').format(((result.minFee * 40).toFixed(0)))} y{" "}
                          $U { new Intl.NumberFormat('es-MX').format((result.maxFee * 40).toFixed(0))}
                        </Typography>
                      </>
                    )}
                    <Typography sx={infotext}>
                      TEA: <b>{result.annualEffectiveTax} %</b>
                    </Typography>
                    <Divider />
                    <Typography sx={subtitle}>Importe a pagar</Typography>
                    {result.moneyType === "usd" && (
                      <>
                        <Typography sx={infotext}>
                          USD: Entre USD {new Intl.NumberFormat('es-MX').format(result.totalToPay.min)} y{" "}
                          USD {new Intl.NumberFormat('es-MX').format(result.totalToPay.max)}
                        </Typography>
                        <Typography sx={infotext}>
                          Pesos: Entre $U {new Intl.NumberFormat('es-MX').format((result.totalToPay.min * 40).toFixed(0))}{" "}
                          y $U {new Intl.NumberFormat('es-MX').format((result.totalToPay.max * 40).toFixed(0))}
                        </Typography>
                      </>
                    )}
                    <Divider />
                    <Typography sx={subtitle}>
                      Salario minimo requerido
                    </Typography>
                    {result.moneyType === "usd" && (
                      <>
                        <Typography sx={infotext}>
                          USD: Entre USD {new Intl.NumberFormat('es-MX').format(result.minValidBalance)} y{" "}
                          USD {new Intl.NumberFormat('es-MX').format(result.maxValidBalance)}
                        </Typography>
                        <Typography sx={infotext}>
                          Pesos: Entre{" "}
                          $U {new Intl.NumberFormat('es-MX').format((result.minValidBalance * 40).toFixed(0))} y{" "}
                          $U {new Intl.NumberFormat('es-MX').format((result.maxValidBalance * 40).toFixed(0))}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
