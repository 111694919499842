import React from "react";
//import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  //CardActions,
  Typography,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

//import { setMonthlyFees, setAmountToPayMonthly } from '../../../helpers/calculations';
//import { ButtonSecondary } from "../../../components/UI/Buttons";
//import Select from "../../../components/UI/Select";
import { TableDetail } from "./table";

import "./LoanDetails.css";

export const LoanDetail = ({ bankDetail, setDetails, setLoanDetails }) => {
  const resultCols = [
    { field: "year", headerName: "Año", width: 100 },
    {
      field: "ammountRequired",
      headerName: "Saldo inicial + intereses",
      width: 200,
    },
    { field: "totalPayment", headerName: "Total pagado", width: 200 },
    { field: "finalBalance", headerName: "Saldo final", width: 200 },
    { field: "anualInterests", headerName: "Interes anual", width: 200 },
  ];
  const [resultRows, setResultRows] = React.useState([]);
  const [fees, setFees] = React.useState(0);
  React.useEffect(() => {
    let results = [];
    let initBalance =
      (parseInt(bankDetail.totalToPay.max) +
        parseInt(bankDetail.totalToPay.min)) /
      2;
    let minFee = parseInt(bankDetail.minFee);
    let maxFee = parseInt(bankDetail.maxFee);
    let finalMinBalance = 0;
    let finalMaxBalance = 0;
    let finalBalance = 0;
    let anualInterests = 0;
    let feesAmmount = 0;
    for (let item = 0; item < bankDetail.years; item++) {
      for (let months = 0; months < 12; months++) {
        finalMinBalance += minFee;
        finalMaxBalance += maxFee;
        feesAmmount += 1;
      }
      finalBalance = (
        initBalance -
        (finalMinBalance + finalMaxBalance) / 2
      ).toFixed(0);
      anualInterests = (
        (((finalMinBalance + finalMaxBalance) / 2) *
          bankDetail.annualEffectiveTax) /
        100
      ).toFixed(0);
      let data = {
        year: item + 1,
        ammountRequired: `USD ${new Intl.NumberFormat('es-MX').format(initBalance)}`,
        totalPayment: `USD ${new Intl.NumberFormat('es-MX').format(finalMinBalance.toFixed(
          0
        ))} - USD ${new Intl.NumberFormat('es-MX').format(finalMaxBalance.toFixed(0))}`,
        finalBalance: `USD ${finalBalance < 51 ? 0 : new Intl.NumberFormat('es-MX').format(finalBalance)}`,
        anualInterests: `USD ${new Intl.NumberFormat('es-MX').format(anualInterests)}`,
      };
      results.push(data);
      initBalance = (
        initBalance -
        (finalMinBalance + finalMaxBalance) / 2
      ).toFixed(0);
      finalMinBalance = 0;
      finalMaxBalance = 0;
    }
    setFees(feesAmmount);
    setResultRows(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bankDetail.ammountRequired,
    bankDetail.annualEffectiveTax,
    bankDetail.minFee,
    bankDetail.totalValue,
  ]);

  const backToDetails = () => {
    setDetails(true);
    setLoanDetails(false);
  };
  return (
    <>
      <Grid container className="py-4">
        <Grid item xs={12}>
          <h1 className="subtitle-3">{`Detalles del credito ${bankDetail.bank}`}</h1>
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-around"} className="pt-5">
        <Grid item xs={12} md={8}>
          <TableDetail columns={resultCols} rows={resultRows} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container className="space-y-5">
            <Grid item xs={12}>
              <Card className="card-centered">
                <CardContent className="card-centered">
                  <Typography variant="p" className="text-left">
                    ¡Importante!: Los cálculos se basan en información que los
                    usuarios proporcionan en el sistema www.crediap.com nuestro
                    sistema calcula basado en información de las diferentes
                    instituciones financieras, sin estas participar de cada
                    cálculo, nuestros cálculos son únicamente informativos y
                    pueden tener diferencias con las diferentes instituciones
                    financieras.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*<Grid xs={12}>
              <Card className="height-full card-centered">
                <CardContent className="card-centered">
                  <Typography variant="p" className="text-left">
                    Dolar: 39.119$U
                  </Typography>
                  <Typography variant="p" className="text-left">
                    UI: 5.5862$U
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"flex-start"}
        spacing={4}
        className="pl-8"
      >
        {bankDetail.loanType === "mortgage" && (
          <Grid item xs={12} md={7}>
            <Accordion sx={{ marginY: "3%" }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Detalle de Gastos Compraventa e Hipoteca
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align={"center"}
                          colSpan={3}
                          className="bg-field"
                        >
                          <span className="font-bold">Detalles de gastos</span>
                        </TableCell>
                        <TableCell align={"center"} className="bg-field">
                          <span className="font-bold">Monto</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bankDetail.additionalCosts.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align={"center"} colSpan={3}>
                            {row.details}
                          </TableCell>
                          <TableCell align={"center"}>{row.ammount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align={"center"}
                          className="bg-field"
                          colSpan={3}
                        >
                          <span className="font-bold">Gastos previos</span>
                        </TableCell>
                        <TableCell align={"center"} className="bg-field">
                          <span className="font-bold">Monto</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bankDetail.previousCost.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align={"center"} colSpan={3}>
                            {row.details}
                          </TableCell>
                          <TableCell align={"center"}>{row.ammount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {bankDetail.loanType === "automotive" && (
          <Grid item xs={12} md={7}>
            <Accordion sx={{ marginY: "3%" }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Detalle de Gastos Compraventa vehicular</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead className="bg-field">
                        <TableRow>
                          <TableCell align={"center"} colSpan={4}>
                            <span className="font-bold">
                              Detalle de Gastos Compraventa vehicular
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell align={"center"}>
                            <span className="font-bold">GASTOS PREVIOS</span>
                          </TableCell>
                          <TableCell align={"center"}>
                            <span>Honorarios escribano/a</span>
                          </TableCell>
                          <TableCell align={"center"}>
                            <span>Montepios</span>
                          </TableCell>
                          <TableCell align={"center"}>
                            <span>Otros</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {
                        <TableBody>
                          {bankDetail.additionalCosts.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align={"center"}>
                                {row.details}
                              </TableCell>
                              <TableCell align={"center"}>
                                {row.notaryFees}
                              </TableCell>
                              <TableCell align={"center"}>
                                {row.montepios}
                              </TableCell>
                              <TableCell align={"center"}>
                                {row.other}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Grid container sx={{ marginY: "6%" }} className="pl-8 md:pl-0">
            <Grid item xs={12} className="my-2">
              <Card className="height-full card-centered">
                <CardContent className="card-centered">
                  <Typography
                    variant="p"
                    className="text-left"
                  >{`Valor de la propiedad: USD ${new Intl.NumberFormat('es-MX').format(bankDetail.totalValue)}`}</Typography>
                  <Typography
                    variant="p"
                    className="text-left"
                  >{`TEA: ${new Intl.NumberFormat('es-MX').format(bankDetail.annualEffectiveTax)} %`}</Typography>
                  <Typography
                    variant="p"
                    className="text-left"
                  >{`Cuota mensual: USD ${new Intl.NumberFormat('es-MX').format(parseInt(
                    bankDetail.minFee
                  ))} - USD ${new Intl.NumberFormat('es-MX').format(parseInt(bankDetail.maxFee))}`}</Typography>
                  <Typography
                    variant="p"
                    className="text-left"
                  >{`Cantidad de cuotas: ${fees}`}</Typography>
                </CardContent>
              </Card>
            </Grid>
            {/*<Grid item xs={12} className="py-2">
              <Card className="card-centered">
                <CardContent>
                  <Typography variant="h5">
                    Califica el servicio (Proximamente)
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid
                    container
                    flexDirection={"column"}
                    alignContent={"center"}
                    spacing={1}
                  >
                    <Grid item xs={12} md={6}>
                      <Select
                        data={[1, 2, 3, 4, 5]}
                        isMultiple={false}
                        disabled={true}
                      ></Select>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonSecondary>Enviar</ButtonSecondary>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>*/}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="py-4">
        <Grid item xs={12} md={2}>
          <button
            onClick={backToDetails}
            className="text-primary font-semibold"
          >
            &lt;&lt; Volver atras
          </button>
        </Grid>
      </Grid>
    </>
  );
};
