import React from "react";
import { DataGrid } from '@mui/x-data-grid';

export const TableDetail = ({columns, rows}) => {
  return(
    <DataGrid
      rows={ rows }
      columns={ columns }
      pageSize={5}
      getRowId={(row) => row.ammountRequired}
      autoHeight={true}
      autoPageSize={true}
      disableColumnMenu
      disableRowSelectionOnClick={true}
      disableExtendRowFullWidth
    ></DataGrid>
  );
}